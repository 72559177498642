import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage";
import Layout from "../layout/Index";
import Matches from "../pages/component/Matches";
import Scorecards from "../pages/component/Scorecards";
// import CricketScore from "../pages/component/CricketScore";
import Football from "../pages/component/Football";
import Rugbyscorecard from "../pages/component/Rugbyscorecard";
import Soccerinfo from "../pages/component/Soccerinfo";
import Rugbyinfo from "../pages/component/Rugbyinfo";
import Basketballinfo from "../pages/component/Basketballinfo";
import { TeamView } from "../pages/component/Teams/Teamview";
import { TeamLeague } from "../pages/component/Teams/TeamLegue";
import { VersionControlView } from "../pages/component/VersionControl/VesionControlView"
import { VersionControlChangeEndpoint } from "../pages/component/VersionControl/VersionControlChangeEndpoint";
// import Clock from "../pages/component/Clock";
import Newboard from "../pages/component/Newboard";
import NoSideBar from "../layout/Index-nosidebar";
import Rugbybigcard from "../pages/component/Rugbybigcard";
import Soccerbigcard from "../pages/component/Soccerbigcard";
import Matchlist from "../pages/component/Matchlist";
import BasketBallView from "../pages/component/BasketBallView";
import { MatchScore } from "../pages/component/Teams/MatchScore";
import { Shimmer } from "../pages/component/Teams/Shimmer";
import Errorpage from "../pages/component/Error Page/Errorpage";
import { LandingPage } from "../pages/LandingPage";
import { VersionControlDashboard } from "../pages/component/VersionControl/VersionControlDashboard";
import { AddSport } from "../pages/Homepage/AddSport";
import {Test} from "../pages/component/Teams/Test";
import { TestTeam } from "../pages/component/SportTeam/TestTeam";
import { ShowTeamPerSport } from "../pages/component/SportTeam/ShowTeamPerSport";
import GenericForm from "../components/genericComponents/GenericForm";

const Pages = () => {
  return (
    <Routes>
      {/* <Route path="/games" element={
        <LandingPage />}></Route> */}
      <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
        <Route index element={<Homepage />}></Route>
        <Route path="versioncontrol/games/:gameid/addnewgame" element={<VersionControlChangeEndpoint />}></Route>
        <Route path="teams" element={<TestTeam />}></Route>
        <Route path="showsportteam/:sportId/:sportName" element={<ShowTeamPerSport />}></Route>
        <Route path="matches" element={<Matches />}></Route>
        <Route path="scoreboards" element={<Scorecards />}></Route>
        <Route path="addTeam" element={<AddSport />}></Route>
        {/* <Route path="landingpage" element={<LandingPage />}></Route> */}
        <Route path="test" element={<Test />}></Route>
        {/* <Route path="testteam" element={<TestTeam />}></Route> */}
        <Route path="form/:action/:id" element = {<GenericForm/>}></Route>

        <Route path="teamview" element={<TeamView />} />
        <Route path="/:teamId/teamlegue" element={<TeamLeague />}></Route>
        {/* <Route path="cricketscore" element={<CricketScore />}></Route> */}

        <Route path="versioncontrol" element={<VersionControlView />} />
        <Route path="try" element={<Shimmer />} />

        <Route path="teamview/TeamLeague" element={<TeamLeague />}></Route>
        <Route path="/TeamLeague/matchScore" element={<MatchScore />}></Route>
        <Route path="dummy" element={<Errorpage />}></Route>
        <Route path="soccercard" element={<Football />}></Route>
        <Route path="rugbyscorecard" element={<Rugbyscorecard />}></Route>
        <Route path="soccer" element={<Soccerinfo />}></Route>
        <Route path="rugby" element={<Rugbyinfo />}></Route>
        <Route path="versioncontroldashboard" element={<VersionControlDashboard />}></Route>

      </Route>


      <Route path="/" element={<NoSideBar />}>
        <Route path="basketballscoreboard/:boston-vs-phonix" element={<Newboard />}></Route>
        <Route path="landingpage" element={<LandingPage />}></Route>

        <Route path="basketball" element={<Basketballinfo />}></Route>

        <Route path="rubgyscoreboard/:rebels-vs-elites" element={<Rugbybigcard />}></Route>
        {/* <Route path="soccerscoreboard/:team" element={<Soccerbigcard />}></Route> */}
        <Route path="soccerscoreboard/:bar-vs-rm" element={<Soccerbigcard />}></Route>
        <Route path="matches-schedule" element={<Matchlist />}></Route>
        <Route path="basketballscore" element={<BasketBallView />}></Route>

      </Route>
    </Routes>
  );
};

export default Pages;
