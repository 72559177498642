import React, { useEffect, useState } from 'react'
import { Display } from "react-7-segment-display";
import rugbyimg1 from "../../images/game/rugbylogo1.png"
import rugbyimg2 from "../../images/game/rubgylogo2.png"


export default function Rugbybigcard() {


    const initialTotalSeconds = 40 * 60; // 15 minutes in seconds
    const [totalSeconds, setTotalSeconds] = useState(initialTotalSeconds);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (totalSeconds > 0) {
                setTotalSeconds(prevSeconds => prevSeconds - 1);
            } else {
                clearInterval(intervalId);
                // Timer completed, you can trigger a callback or action here
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [totalSeconds]);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Format minutes and seconds to display with leading zeros if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return (
        <>
            {/* <div className="container-fluid text-white py-2 bg-black">
                <div className="row justify-content-center bg-black py-3">
                    <div className='col-3 col-md-4  justify-content-center d-flex flex-column align-center'>
                        <img src={rugbyimg1} alt="rugby" height={"110px"} width={"100px"} className='p-1' backgroundColor="#000000" />
                        <h5 className="fs-1">Rebels</h5>
                    </div>

                    <div className="col-12 col-md-4 order-0text-center justify-content-center d-flex flex-column align-center">

                        <div className='d-flex border-red justify-content-center p-2'>
                            <div className='d-flex justify-content-center'>
                                <Display value={formattedMinutes} count={2} height={60} backgroundColor='black' className="" color='red'/>
                            </div>

                            <div className='d-flex flex-column justify-content-center '>
                                <div className='d-block px-2'>
                                    <div className="blinking-dot my-1"></div>
                                </div>
                                <div className='d-block px-2'>
                                    <div className="blinking-dot my-1"></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Display value={formattedSeconds} count={2} height={60} backgroundColor='black' className="" color='red' />
                            </div>
                        </div>

                    </div>


            
                    <div className='col-4 justify-content-center d-flex flex-column align-center'>
                        <img src={rugbyimg2} alt="rugby " height={"100px"} width={"100px"} />
                        <h5 className="fs-1">Elites</h5>
                    </div>
                    <div className='row justify-content-center pt-3 align-center'>

                        <div className='col-4 p-2'>
                            <Display value="9" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>

                        <div className='col-4 p-3 text-center'>
                            <h5 className="fs-1">TRIES</h5>

                        </div>
                        <div className='col-4 p-2'>
                            <Display value="4" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>
                    </div>
                    <div className='row justify-content-center align-center'>

                        <div className='col-4 p-2'>
                            <Display value="1" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>

                        <div className='col-4 p-3 text-center'>
                            <h5 className="fs-1">PENALTIES</h5>

                        </div>
                        <div className='col-4 p-2'>
                            <Display value="5" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>
                    </div>

                    <div className='row justify-content-center align-center'>

                        <div className='col-4 p-2'>
                            <Display value="14" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>

                        <div className='col-4 p-3 text-center'>
                            <h5 className="fs-1">FIELD GOALS</h5>

                        </div>
                        <div className='col-4 p-2'>
                            <Display value="6" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>
                    </div>

                    <div className='row justify-content-center align-center'>

                        <div className='col-4 p-2'>
                            <Display value="4" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>

                        <div className='col-4 p-2 text-center'>
                            <h5 className="fs-1">CONVERSATIONS</h5>

                        </div>
                        <div className='col-4 p-2'>
                            <Display value="8" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>
                    </div>
                    <div className='row justify-content-center pt-2 align-center'>

                        <div className='col-4 p-2'>
                            <Display value="28" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>

                        <div className='col-4 p-3 text-center'>
                            <h5 className="fs-1">TOTAL</h5>

                        </div>
                        <div className='col-4 p-2'>
                            <Display value="23" count={2} height={50} backgroundColor='black' className="" color='#23e123' />

                        </div>
                    </div>


                </div>


            </div> */}









            <div className="container-fluid text-white py-1 bg-black height-100">
                <div className="row justify-content-center bg-black py-2">

                    <div className="col-12 col-md-4 order-0 text-center justify-content-center d-flex flex-column align-center">

                        <div className='d-flex border-red justify-content-center p-1'>
                            <div className='d-flex justify-content-center'>
                                <Display value={formattedMinutes} count={2} height={40} backgroundColor='black' className="" color='red' />
                            </div>

                            <div className='d-flex flex-column justify-content-center '>
                                <div className='d-block '>
                                    
                                    <div className="blinking-dot my-1"></div>
                                </div>
                                <div className='d-block '>
                                    <div className="blinking-dot my-1"></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Display value={formattedSeconds} count={2} height={40} backgroundColor='black' className="" color='red' />
                            </div>
                        </div>

                    </div>
                        <div className='d-flex justify-content-between'>
                    <div className='col-4 col-md-3  d-flex flex-column align-center '>
                        <div className='text-center'>

                        <img src={rugbyimg1} alt="rugby" height={"110px"} width={"100px"} className=' img-fluid' backgroundColor="#000000" />
                        <h5 className="fs-1">Rebels</h5>
                        </div>
                    </div>



                    <div className='col-4 col-md-3  d-flex flex-column align-center align-md-center'>
                        <div className='text-center'>

                        <img src={rugbyimg2} alt="rugby " height={"100px"} width={"100px"} />
                        <h5 className="fs-1">Elites</h5>
                        </div>
                    </div>
                        </div>


                    <div className='row justify-content-center pt-2 align-center'>

                        <div className='col-3 '>
                            <Display value="9" count={2} height={40} backgroundColor='' className="" color='#0db30d' />

                        </div>

                        <div className='col-6 text-center'>
                            <h3 className="">TRIES</h3>

                        </div>
                        <div className='col-3 '>
                            <Display value="4" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>
                    </div>
                    <div className='row justify-content-center align-center pt-1'>

                        <div className='col-3'>
                            <Display value="1" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>

                        <div className='col-6  text-center'>
                            <h3 className="">PENALTIES</h3>

                        </div>
                        <div className='col-3 '>
                            <Display value="5" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>
                    </div>

                    <div className='row justify-content-center align-center pt-1'>

                        <div className='col-3 '>
                            <Display value="14" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>

                        <div className='col-6  text-center'>
                            <h3 className="">FIELD GOALS</h3>

                        </div>
                        <div className='col-3 '>
                            <Display value="6" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>
                    </div>

                    <div className='row justify-content-center align-center pt-1' >

                        <div className='col-3 '>
                            <Display value="4" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>

                        <div className='col-6 text-center'>
                            <h3 className="">CONVERSATIONS</h3>

                        </div>
                        <div className='col-3 '>
                            <Display value="8" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>  
                    </div>
                    <div className='row justify-content-center pt-1 align-center pt-1'>

                        <div className='col-3 '>
                            <Display value="28" count={2} height={40} backgroundColor='black' className="" color='#0db30d' />

                        </div>

                        <div className='col-6 text-center'>
                            <h3 className=" ">TOTAL</h3>

                        </div>
                        <div className='col-3 d-md-none'>
                            <Display value="23" count={2} height={40} backgroundColor='black' className=" fs-1" color='#0db30d ' />

                        </div>
                        <div className='col-3 d-none d-md-block'>
                            <Display value="23" count={2} height={40} backgroundColor='black' className=" fs-1" color='#0db30d ' />

                        </div>
                    </div>


                </div>


            </div>

        </>
    )
}
