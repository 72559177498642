export const GenericFooterPagination = ({ data, currentPage, setCurrentPage, PreviousIcon, NextIcons }) => {
    const getNextPage = (data) => {
        if (data[currentPage + 1]) {
            setCurrentPage((prev) => prev + 1)
        }
    }
    const getPrevPage = (data) => {
        if (data[currentPage - 1]) {
            setCurrentPage((prev) => prev - 1)
        }
    }
    return (
        <nav aria-label="Page navigation">
            <ul class="pagination d-flex ">
                <div>
                    <li class="page-item pointer" onClick={() => getPrevPage(data)}>
                        <div class="page-link" aria-label="Previous">
                            <PreviousIcon />
                            <span class="sr-only fw-bold">Previous</span>
                        </div>
                    </li>
                </div>{
                    
                }
                <div>
                    <li class="page-item pointer" onClick={() => getNextPage(data)}>
                        <div class="page-link" aria-label="Next">
                            <span class="sr-only fw-bold">Next</span>
                            <NextIcons />
                        </div>
                    </li>
                </div>
            </ul>
        </nav>
    )
}