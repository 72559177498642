import React from "react";
import { ShimmerButton, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
export const Shimmer = ({ type }) => {
    return (
        <>
            <ShimmerTitle line={1} gap={10} variant="primary" />
            <div className="row">

                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />

                </div>
                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />
                </div>
            </div>
            <div className="row">

                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />

                </div>
                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />
                </div>
            </div>
            <div className="row">

                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />

                </div>
                <div className="px-4 col-md-6">
                    <ShimmerThumbnail height={50} rounded />
                </div>
            </div>
        </>
    )
}