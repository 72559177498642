import { useReducer, useRef, useState } from "react";
import AddProperty from "../../../components/genericComponents/Addproperty";
import { SchemaTypes, VersionControlGroup, filePropertylist, versioncontrolpost } from "../../../helper/extraproperties";
import { VERSIONCONTROL } from "../../../Endpoints/endpoints";
import axios from "axios";
import { getCookie } from "../../../utils/Utils";
import ShowProgress from "../../../components/genericComponents/GenericProgressBar";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "react-query";
import { Button } from "reactstrap";

const ADD_TO_CURRENT = "push"
const UPDATE_PROP_VALUES = "update"
const REMOVE_ARRAY_PROPS_TYPE = "pop";
const ADDTOTABLE = "AddToTable"
const ADD_TO_CURRENT_DATA = "add_to_current"
export const INITIAL = "initial"
export const InitState = { total: 0, increasing: 0, loading: false, success: false, error: "", percentage: 0, estimatedTime: 0 }

const init = {
    current: [{}],
    saveData: []
}

const reducer = (state, action) => {
    var currentState = {}

    if (state.current && state.current.length === 1) {
        currentState.current = [...state.current]
    }
    if (state.saveData && state.saveData.length > 0) {
        currentState.saveData = [...state.saveData]
    }

    switch (action.type) {
        case INITIAL:
            currentState.saveData = []
            currentState.current = [{}]
            break
        case ADD_TO_CURRENT:
            currentState.current = [{}]
            break;
        case ADD_TO_CURRENT_DATA:
            if (action.payload.ContentType) {
                currentState.current[0][action.payload.name] = action.payload.value
            } else {
                currentState.current[0][action.payload.name] = action.payload.value;
            }
            break;
        case ADDTOTABLE:
            if (action.payload.index) {
                currentState.saveData[action.payload.index] = {
                    value: action.payload.value,
                    ContentType: action.payload.ContentType,
                    fileName: action.payload.fileName
                };
            } else {
                if (!currentState.saveData) {
                    currentState.saveData = []
                }
                currentState.saveData.push(action.payload.value)
            }
            currentState.current = []
            break;
        case UPDATE_PROP_VALUES:
            currentState.current = [
                { ...currentState.saveData[action.payload.index] }
            ]
            break
        case REMOVE_ARRAY_PROPS_TYPE:
            let indexToRemove = action.payload.index
            currentState.saveData.splice(indexToRemove, 1);
            break;
        default:
    }
    return currentState;
};

export const VersionControlChangeEndpoint = () => {
    const { gameid } = useParams()
    const [state, dispatch] = useReducer(reducer, init);
    const [status, setStatus] = useState(InitState)
    const [error, setError] = useState(null);
    const formRef = useRef()
    const queryClient = useQueryClient()
    const navigate = useNavigate()


    const notify = async (e) => {
        await queryFunction(e)
    }

    const queryFunction = async () => {
        if (!error) {
            const totalFileSize = state.current.reduce((acc, curr) => acc + curr.file.size, 0);
            let totalUploadedBytes = 0;
            let startTime = 0

            setStatus((prevState) => ({ ...prevState, total: totalFileSize, loading: true }));
            try {
                return await Promise.all(state.current.map(async (element) => {
                    element.contentType = SchemaTypes.file;
                    let formData = new FormData();
                    formData.append('description', element.description);
                    formData.append('title', element.title);
                    formData.append('version', element.version + "," + element.file.size);
                    formData.append('file', element.file);
                    formData.append('group_key', gameid)
                    await axios.post(process.env.REACT_APP_GAME_BASE_URL + VERSIONCONTROL + "/" + versioncontrolpost, formData, {
                        headers: {
                            "authToken": getCookie("authToken")
                        },
                        onUploadProgress: (progressEvent) => {
                            const uploadedBytes = progressEvent.loaded;
                            totalUploadedBytes += uploadedBytes;
                            if (!startTime) {
                                startTime = Date.now();
                            }
                            const elapsedTime = Date.now() - startTime;
                            const percentage = Math.round(Math.round(progressEvent.loaded * 100) / progressEvent.total)
                            let estimatedUploadTimeInSeconds = Math.round((progressEvent.total - uploadedBytes) / (uploadedBytes / elapsedTime) / 1000);
                            setStatus((prevState) => ({ ...prevState, increasing: totalUploadedBytes, percentage: percentage, estimatedTime: estimatedUploadTimeInSeconds }));
                        },
                    });
                })).then(() => {
                    navigate("/versioncontroldashboard")
                    setStatus((prevState) => ({ ...prevState, success: true, loading: false }))
                    queryClient.invalidateQueries({
                        predicate: (query) =>
                            query.queryKey.includes("GETALLLIST")
                    })
                    dispatch({ INITIAL })
                })
            } catch (error) {
                await Promise.reject("Try Again")
                dispatch({ INITIAL })
                setStatus((prevState) => ({ ...prevState, error: status.error += status.error }))
            }
        }
        dispatch({ INITIAL })
    };




    const handleDelete = (index) => {
        if (index >= 0) {
            dispatch({ type: REMOVE_ARRAY_PROPS_TYPE, payload: { index: index } });
        }
    }

    const GetCurrentValue = (data) => {
        return state.current[0][data.item.name]
    }

    const Onchange = (e, data) => {
        if (data.item.type === SchemaTypes.file) {
            dispatch({ type: ADD_TO_CURRENT_DATA, payload: { fileName: e.name, name: data.item.name, value: e.value, ContentType: e.type, size: e.size } })
        } else {
            dispatch({ type: ADD_TO_CURRENT_DATA, payload: { value: e.value, name: e.name } })
        }
    }

    const submitToTable = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            if (state.current[0].file) {
                setError(null)
                notify()
            } else {
                setError("Something Went Wrong")
            }
        } else {
            form.classList.add("was-validated");
        }
        dispatch({ type: ADD_TO_CURRENT })
    }

    var singleProps = [];
    filePropertylist.forEach((data) => {
        singleProps.push(data);
    })


    if (status.loading || status.success) {
        return (<ShowProgress dispatch={dispatch} setStatus={setStatus} percentage={status.percentage} Total={status.total} error={status.error} done={status.success} success={status.increasing} estimatedUploadTimeInSeconds={status.estimatedTime} successMessage={"Files is Successfully Addded"} />)
    }

    return (
        <div className="m-2 text-center rounded">
            <div
                className=" container-fluid  p-2 row row-cols-1 g-2 justify-content-center"
            >
                <div className="col-12 col-sm-12">
                    <div className="hstack col-12">
                        <div>
                            <h4>Add Version Info</h4>
                            <p className="text-center ">{VersionControlGroup[gameid]}</p>
                        </div>
                        <div className="ms-auto">
                            <Button color="primary" onClick={() => navigate("/versioncontroldashboard")}>Back To List</Button>
                        </div>
                    </div>
                    <div className={"vstack mt-2 p-2 " + (state?.current?.length === 1 ? "card border border-1" : "")}>
                        <div className="pt-2">
                            {
                                state?.current?.map((element, index) => {
                                    return (
                                        <form
                                            key={index}
                                            onSubmit={submitToTable}
                                            className=" needs-validation container-fluid  rounded"
                                            ref={formRef}
                                            noValidate
                                        >
                                            {singleProps.map((data, index) => {
                                                return (

                                                    <div className="vstack mt-2" key={index}>
                                                        <AddProperty
                                                            deleteField={(element) => {
                                                                handleDelete(element, data);
                                                            }}
                                                            setError={setError}
                                                            error={error}
                                                            key={index}
                                                            data={data}
                                                            currentValue={GetCurrentValue(data)}
                                                            onChange={(e) => {
                                                                Onchange(e, data)
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            <Button type="submit" color="primary" className="ms-auto mt-2 float-start align-self-end ">Save</Button>
                                        </form>
                                    )
                                })}
                        </div>
                    </div>
                    <hr className="d-sm-none d-block bg-blue" />
                </div>
            </div>
        </div>
    );
}