import React, { useEffect, useState } from "react";
import { Display } from "react-7-segment-display";
import Scorecards from "./Scorecards";

const Football = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  return (
    <>
      
      <section className="m-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-7 shadow p-3 px-5 pb-5 bg-black rounded-2 position-relative scoreboard">
              <div className="row justify-content-between">
                <p className="text-center fw-bold fs-1 mb-3 text-bg-dark custom-letter-spacing bg-gradient rounded-3">
                  LA LIGA FOOTBALL LEAGUE
                </p>
                <div className="col-4 text-center">
                  <div className="text-center">
                    <img src="fcb.png" alt="" />
                    <h6 className="fs-18px p-2 rounded-pill bg-black text-azure-dim">BARCELONA</h6>
                  </div>
                </div>
                <div className="col-4 my-auto">
                  <div className="clock-effect z-2 position-relative">
                    <div className="d-flex align-items-center">
                      <Display
                        value={hours.toString().padStart(2, "0")}
                        count={2}
                        height={40}
                        backgroundColor="black"
                        color="#0db30d"
                        className="mx-auto"
                      />
                      <div className="clock">
                        <span className="timer">:</span>
                      </div>
                      <Display
                        value={minutes.toString().padStart(2, "0")}
                        count={2}
                        height={40}
                        backgroundColor="black"
                        color="#0db30d"
                        className="mx-auto"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4 text-center">
                  <div className="text-center">
                    <img src="rm.png" alt="" />
                    <h6 className="fs-18px p-2 rounded-pill bg-black text-azure-dim">REAL MADRID</h6>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-6 col-lg-4 text-center">
                  <Display value="03" count={2} height={80} backgroundColor="" className="mx-auto" />
                  <ul className="mt-3">
                    <li className="fs-12px text-white">Leo Messi (14', 43')</li>
                    <li className="fs-12px text-white">Neymar Jr. (35')</li>
                  </ul>
                </div>
                <div className="col-6 col-lg-4 text-center">
                  <Display value="02" count={2} height={80} backgroundColor="" />
                  <ul className="mt-3">
                    <li className="fs-12px text-white">Benzema (12')</li>
                    <li className="fs-12px text-white">Kross (40')</li>
                  </ul>
                </div>
              </div>
              <h3 className="my-3 text-center text-white text-space">
                1<sup>st</sup> HALF
              </h3>
              <div className="d-flex"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Football;
