import React, { useState } from "react";
import { SchemaTypes } from "../../helper/extraproperties";
import ImageValidater from "./ImageValidater";
import { blobToBase64 } from "../../helper/helper";

export function AddProperty(props) {
  const [selectedFile, setSelectedFile] = useState(undefined)
  var controlProps = {
    required: props.data.item.required,
    placeholder: "Enter "+props.data.item.displayName,
    onChange: async (e) => {
      if (props.data.item.type === SchemaTypes.file && props?.data?.item?.specialFileInput) {
        if (props.data.item.Types.includes(e.target.files[0].type)) {
          props.onChange({
            name: props.data.item.name,
            value: e.target.files[0],
            fileName: e.target.files[0].name,
            ContentType: e.target.files[0].type,
            size: e.target.files[0].size
          });
          props.setError(null)
        } else {
          props.setError("Please Select Appropriate File")
        }
      } else if (props.data.item.type === SchemaTypes.Image) {
        props.onChange({
          name: props.data.item.name,
          value: e.target.files[0],
          fileName: e.target.files[0].name,
          ContentType: e.target.files[0].type,
          size: e.target.files[0].size
        });
      }
      else {
        props.onChange({ name: props.data.item.name, value: e.target.value });
      }
    },
  };
  switch (props.data.item.type) {
    case SchemaTypes.String:
      controlProps.type = SchemaTypes.String;
      break;
    case SchemaTypes.file:
      controlProps.type = SchemaTypes.file;
      break;
    case SchemaTypes.Image:
      controlProps.type = SchemaTypes.file
      break;
    default:
      break;
  }

  if (props.currentValue) {
    controlProps.defaultValue = props.currentValue;
  } else {
    controlProps.defaultValue = "";
  }

  if (props.disabled) {
    controlProps.disabled = props.disabled;
  }

  if (props.data.item.maxLength) {
    controlProps.maxLength = props.data.item.maxLength;
  }

  if (props.data.item.min) {
    controlProps.min = props.data.item.min
  }

  if (props.data.item.accept) {
    controlProps.accept = props.data.item.accept
  }

  if (props.data.item.type === SchemaTypes.Image) {
    return (
      <>
        <ImageValidater
          controlProps={controlProps}
          filetype={SchemaTypes.file}
          RestrictedSize={"2"}
          setSelectedFile={setSelectedFile}
          props={props}
          selectedFile={selectedFile}
        />
      </>
    )
  }

  if (props.data.item.type === SchemaTypes.file) {
    return (
      <div>
        <label for={"validationCustom01" + props.data.item.name} class="form-label d-flex justify-content-start">{props.data.item.displayName}</label>
        <div class="input-group">
          <input
          style={{backgroundPosition:'right',backgroundImage: 'none'}}
            type={props.data.item.type}
            class="form-control "
            id={"validationCustom01" + props.data.item.name}
            onChange={controlProps.onChange}
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            // {...controlProps}
            required={true}
          />
          <label class="input-group-text" htmlFor={props.data.item.name} >Upload</label>
          <div className="invalid-feedback text-start">
         {props.data.item.displayName.charAt(0).toUpperCase() + props.data.item.displayName.slice(1)} is required
        </div>
        </div>
        {
          props.error &&
          <div>
            {props.error}
          </div>}
      </div>
    )
  }

  
  return (
    <div className="">
      <label for="validationCustom01" class="form-label d-flex justify-content-start pb-0">{props.data.item.displayName}</label>
      <div className="form-group">
      <input
        value={props.currentValue}
        type={props.data.item.type}
        className="form-control" style={{backgroundPosition:'right',backgroundImage:'none'}}
        id={"floatingInput" + props.data.item.name}
        pattern={controlProps.pattern}
        {...controlProps}
        maxLength={props.data.item.maxLength}
        onChange={controlProps.onChange}
      />
      <div className="invalid-feedback text-start" >
      {props.data.item.displayName.charAt(0).toUpperCase() + props.data.item.displayName.slice(1)} is required
      </div> 
      </div>
     
    </div>

  );
}

export default AddProperty;