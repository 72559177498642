import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import { Icon, Button } from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useState } from "react";

import logo from "../../assets/logo.png";
import logoDark from "../../assets/logo-dark.png";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import PackageModal from "./PackageModal";

const Header = ({ fixed, className }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { skin } = useTheme();

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div className="nk-header-app-name pointer" onClick={() => navigate("/")}>
            <div className="nk-header-app-info">
              <p className="fs-2">
                <span className="text-primary">Games</span>
              </p>
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="notification-dropdown me-n1">
                <Notification />
              </li>
              <li className="list-apps-dropdown d-lg-none">
                <AppDropdown />
              </li>
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PackageModal toggleIsOpenModal={toggleIsOpen} isOpen={isOpen} />
    </div>
  );
};
export default Header;
