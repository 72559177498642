import "../../css/LandingPage/landingPage.css"
export const GenericInfoCard = ({ icons, title, data }) => {
    return (
        <div className="col-md-6 my-sm-2 m-0  ">
            <div className="card  bg-transparent p-1 my-1">
                <div className="row g-0">
                    <div className="col-md-3 my-auto ">
                        <div className="git-background mx-auto">
                            {icons}
                        </div>
                    </div>
                    <div className="col-md-9 ">
                        <div className="card-body text-center text-sm-start">
                            <h5 className=" m-0 text-black ">{title}</h5>
                            <p className="card-text fw-normal" style={{ lineHeight: 'normal' }}>{data}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}