import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"

const   TeamLegueShimmerCard = () => {
    return (
        <div className={"container-fluid d-none d-sm-block my-2"}>
            <div className="row">
                <div className="col-4 ">
                    <ShimmerThumbnail height={50} rounded />
                

                </div>
                <div className="col-4 ">
                    <ShimmerThumbnail height={50} rounded />
                

                </div> <div className="col-4 ">
                    <ShimmerThumbnail height={50} rounded />
                

                </div>
                <div className={"col-8 fw-normal pointer "} style={{ fontSize: '13px' }} >
              
                </div>
            </div>
        </div>
    )
}

export const TeamLegueShimmer = () => {
    return (
        <Card className="card-bordered  border-0 ">
            <CardBody className="card-inner">
                <CardTitle className="fs-5  fw-bolder"><ShimmerText line={1} gap={10} /></CardTitle>
                <CardText>
                    <div className="overflow-y-scroll overflow-x-visible" >
                      <TeamLegueShimmerCard />
             
                    </div>
                </CardText>
            </CardBody>
        </Card>
    )
}