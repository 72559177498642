import React from 'react'
import Player1 from "../../../images/game/Playerimage1.jpg"

export default function Playerdetails() {
    return (
        <>
            <div className=''>
                <div class="card">
                    <img className='img-fluid' src={Player1} alt="Card image cap" width="100%"/>
                        <div class="card-body d-flex justify-content-around  ">
                            <div className=''>

                            <h5 className='fs-6 py-1'>Player Name</h5>
                            <h5 className='fs-6 py-1'>Position</h5>
                            <h5 className='fs-6 py-1'>Build</h5>
                            <h5 className='fs-6 py-1'>Home State</h5>
                            </div>

                            <div>
                            <h5 className='fs-6 py-1' >Kane Patrik </h5>
                            <h5 className='fs-6 py-1' >Forward</h5>
                            <h5 className='fs-6 py-1' >6"2'</h5>
                            <h5 className='fs-6 py-1' >Temple</h5>
                            </div>
                        
                        </div>
                </div>
            </div>
        </>
    )
}
