import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects"

export const VersionControlShimmer = () => {
    return (
        <>
            <ShimmerText line={1} gap={0} className="w-100 px-4 pt-5"/>

            <div className="px-4 d-flex justify-content-between">
            <ShimmerThumbnail height={30} width={50} rounded className="w-100" />
                <ShimmerThumbnail height={10} rounded className="w-75" />
           

            </div>
          <div className="d-flex">
          <ShimmerText line={1} gap={10} className="w-25 ps-4  pt-2" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          </div>
          <div className="d-flex">
          <ShimmerText line={1} gap={10} className="w-25 ps-4 pt-2 " />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          </div>
          <div className="d-flex">
          <ShimmerText line={1} gap={10} className="w-25 ps-4 pt-2 " />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          </div>
          <div className="d-flex">
          <ShimmerText line={1} gap={10} className="w-25 ps-4 pt-2 " />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2 d-none d-md-block" />
          <ShimmerText line={1} gap={0} className="w-25 ps-2 pt-2  d-none d-md-block" />
          </div>



        </>

    )
}