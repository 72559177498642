import React from 'react'
import Scorecards from './Scorecards'
import { Display } from "react-7-segment-display";
// import { useState, useEffect } from 'react';

export default function Rugbyscorecard() {
    // const Display = ({ value, count, height, backgroundColor, color }) => {
    //     const [timerValue, setTimerValue] = useState(value); // State to hold the timer value

    //     // Function to update timer value
    //     useEffect(() => {
    //         const timerInterval = setInterval(() => {
    //           setTimerValue(prevValue => {
    //             if (prevValue > 0) {
    //               return prevValue - 1;
    //             } else {
    //               clearInterval(timerInterval); // Stop the timer when timerValue reaches 0
    //               return 0;
    //             }
    //           });
    //         }, 1000);
        
    //         // Clean up the interval on component unmount
    //         return () => clearInterval(timerInterval);
    //       }, []);
        return (
            <>


                <div className='container w-70 py-2 bg-black'>
                    <div className='text-center text-white'>
                        <h3>
                            {/* RUGBY SCORECARD */}
                        </h3>
                    </div>
                    <div className="container   text-white  bg-black">
                        <div className="row justify-content-center bg-black py-2">
                            <div className='col-md-3  justify-content-center d-flex flex-column align-end'>
                                <img src="rugby2.jpg" alt="rugby " height={"110px"} width={"100px"} className='p-1' />
                                <h5 className="fs-1">Rebels</h5>
                            </div>

                            <div className="col-md-5  text-center justify-content-center d-flex flex-column align-center">

                                <div className=' d-flex border-red justify-content-center p-2 '>
                                    <div className='d-flex justify-content-center'>
                                        <Display value="10" count={2} height={50} backgroundColor='black' className="" color='red' />
                                    </div>

                                    <div className='d-flex flex-column justify-content-center '>
                                        <div className='d-block px-2'>
                                            {/* <RxDotFilled /> */}
                                            <div className="blinking-dot my-1"></div>
                                        </div>
                                        <div className='d-block px-2'>
                                            <div className="blinking-dot my-1"></div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Display value="20" count={2} height={50} backgroundColor='black' className="" color='red' />
                                    </div>
                                </div>

                            </div>



                            <div className='col-md-3 justify-content-start d-flex flex-column '>
                                <img src="" alt="rugby " height={"100px"} width={"100px"} />
                                <h5 className="fs-1">Elites</h5>
                            </div>
                            <div className='row justify-content-center'>

                                <div className='col-md-3 p-2'>
                                    <Display value="9" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>

                                <div className='col-md-4 p-3 text-center'>
                                    <h5 className="fs-1">TRIES</h5>

                                </div>
                                <div className='col-md-3 p-2'>
                                    <Display value="4" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>
                            </div>
                            <div className='row justify-content-center'>

                                <div className='col-md-3 p-2'>
                                    <Display value="1" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>

                                <div className='col-md-4 p-3 text-center'>
                                    <h5 className="fs-1">PENALTIES</h5>

                                </div>
                                <div className='col-md-3 p-2'>
                                    <Display value="5" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>
                            </div>

                            <div className='row justify-content-center'>

                                <div className='col-md-3 p-2'>
                                    <Display value="14" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>

                                <div className='col-md-4 p-3 text-center'>
                                    <h5 className="fs-1">FIELD GOALS</h5>

                                </div>
                                <div className='col-md-3 p-2'>
                                    <Display value="6" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>
                            </div>

                            <div className='row justify-content-center'>

                                <div className='col-md-3 p-2'>
                                    <Display value="4" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>

                                <div className='col-md-4 p-2 text-center'>
                                    <h5 className="fs-1">CONVERSATIONS</h5>

                                </div>
                                <div className='col-md-3 p-2'>
                                    <Display value="8" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>
                            </div>
                            <div className='row justify-content-center pt-2'>

                                <div className='col-md-3 p-2'>
                                    <Display value="28" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>

                                <div className='col-md-4 p-3 text-center'>
                                    <h5 className="fs-1">TOTAL</h5>

                                </div>
                                <div className='col-md-3 p-2'>
                                    <Display value="23" count={2} height={40} backgroundColor='black' className="" color='green' />

                                </div>
                            </div>


                        </div>
                      
                  
                    </div>
                </div>
            </>
        )
    }
