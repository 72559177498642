export const GenericTeamWrapper = ({ children }) => {
    return (
        <div className="container-fluid p-0">
            <div className="row justify-content-center m-0">
                {
                    children
                }
            </div>
        </div>
    )
}
