import { Badge } from "reactstrap"
import { GAMES } from "../../../helper/extraproperties"
import { useQuery } from "react-query";
import { ShimmerTeam } from "./ShimmerTeam";
import Error404Classic from "../../error/404-classic";
import { AxiosHelper } from "../../../helper/helper";
import { GETTEAMPERSPORT } from "../../../Endpoints/endpoints";
import { Icon } from "../../../components/Component";
import { useNavigate } from "react-router";

export const ShowSportCard = ({ item }) => {
    const navigate = useNavigate()
    const sportId = item.id;

    const queryKey = [sportId, GAMES]


    const SportTeamqueryfunction = async () => {
        let data = await AxiosHelper(GETTEAMPERSPORT, {
            sport_id: sportId
        })

        if (data.data.errors) {
            return []
        }
        if (data.data.data.length === 1) {
            return data.data.data
        }
        if (Object.keys(data).length === 0 || !data || data.data.data.length === 0) {
            return []
        }
        if (data.data.data[0] && data.data.data[1]) {
            return [data.data.data[0], data.data.data[1]];
        }
        return []
    }

    const { data, isLoading, error } = useQuery({
        queryFn: SportTeamqueryfunction,
        queryKey: queryKey
    })

    if (isLoading) {
        return <ShimmerTeam />;
    }

    if (error) {
        return <Error404Classic />;
    }
    if (data.length === 0) {
        return (
            <div className='col-md-6 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 bg-danger text-white '>
                    <div className='fw-bolder fs-4  '>{item.sport_name}</div>
                    <div className="pointer fs-6 fw-bold " onClick={() => navigate(`/showsportteam/${sportId}/${item.sport_name}`, { state: { item: item } })}>See All<Icon name="chevron-right" /></div>
                </div>
                <div className='d-flex flex-column text-center align-center'>
                    <div className="fw-bold fs-5 my-5">
                        <Badge className="badge-sm" pill color="primary">No Data</Badge>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <div className='col-md-6 border-1 border p-1 '>
            <div className='d-flex flex-row justify-content-between p-2 bg-danger text-white '>
                <div className='fw-bolder fs-4 '>{item.sport_name}</div>
                <div className="pointer fs-6 fw-bold " onClick={() => navigate(`/showsportteam/${sportId}/${item.sport_name}`, { state: { item: item } })}>See All<Icon name="chevron-right" /></div>
            </div>
            <div className='d-flex flex-column'>
                <ul class="list-group ">
                    {
                        data?.map((element, index) => {
                            return (<><li class="list-group-item hover-div">
                                <div className='d-flex justify-content-start '>
                                    <div className='d-flex flex-row g-2 pointer '>
                                        <div className=''>
                                            <img className="" height={50} width={50} style={{ minWidth: '50px', borderRadius: '50%' }} src={element?.team_logo} alt="" />
                                        </div>
                                        <div className='align-center fw-bold fs-6'>{element.team_name}</div>
                                    </div>
                                </div>
                            </li>
                            </>)
                        })
                    }
                </ul>
            </div>
        </div>
    )
}