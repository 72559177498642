import { useNavigate } from "react-router";
import { cutWord } from "../../../helper/helper";

export const VersionControlCard = ({ item }) => {
    const { title, describe, sourceImg } = item;
    const navigate = useNavigate()

    const NavigateTo = () => {
        navigate(`games/${item.sourceId}/addnewgame`)
    }

    return (
        <>
            <div className="mb-1 col-sm-6 rounded text-center d-flex justify-content-center pointer" >
                <div className="img-wrapper pt-1 " onClick={NavigateTo} >
                    <img src={sourceImg} alt="" className="rounded-2" style={{ objectFit: "fill", width: "500px", height: "260px" }} />
                    <div>
                    </div>
                    <div className="content fade vstack">
                        <h5 className="card-title text-white text-center ">{title}</h5>
                        <p className="card-text dynamicSizetableCard  text-white" >
                            {cutWord(80, describe)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}