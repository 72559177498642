import React, { useState } from 'react';
import footballimage1 from "../../images/game/Football/football-header.jpg"
import footballimage2 from "../../images/game/Football/history-football.jpg"
import fotballimage3 from "../../images/game/Football/fotball3.png"
import fotballimage4 from "../../images/game/Football/football4.jpg"
import fotballimage5 from "../../images/game/Football/Soccer_pitch_dimensions.png"
import fotballimage6 from "../../images/game/Football/fotball-players11.jpg"
import fotballimage7 from "../../images/game/Football/refree-image.png"
import cricket from "../../images/game/cricket.jpg"



export default function Soccerinfo() {
    const opts = {
        height: '360',
        width: '640',
        playerVars: {
            //   https://developers.google.com/youtube/player_parameters
            autoplay: 0, // Autoplay the video
        },
    };
    const yturl = "https://youtu.be/ouOY2vZaFtg?si=ZmU0dT7MzYJoH6xm";
    const [activeTab, setActiveTab] = useState('home-tab-pane'); // Default active tab

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <>

            <div>
                {/* Tab buttons */}
                <ul className="nav nav-tabs px-3" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'home-tab-pane' ? 'active' : ''}`}
                            onClick={() => handleTabClick('home-tab-pane')}
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected={activeTab === 'home-tab-pane'}
                        >
                            What is Soccer
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'profile-tab-pane' ? 'active' : ''}`}
                            onClick={() => handleTabClick('profile-tab-pane')}
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected={activeTab === 'profile-tab-pane'}
                        >
                            How to play
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'contact-tab-pane' ? 'active' : ''}`}
                            onClick={() => handleTabClick('contact-tab-pane')}
                            role="tab"
                            aria-controls="contact-tab-pane"
                            aria-selected={activeTab === 'contact-tab-pane'}
                        >
                            Rules
                        </button>
                    </li>
                </ul>

                {/* Tab content */}
                <div className="tab-content " id="myTabContent">
                    <div
                        className={`tab-pane fade ${activeTab === 'home-tab-pane' ? 'show active' : ''}`}
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab">




                        <div className='container '>
                            <div className='row '>
                                <div className='col-md-7'>
                                    <img src={footballimage1} alt="header img" />
                                </div>
                                <div className='col-md-5 '>
                                    <p>
                                        Football, also known as soccer in some countries, is a team sport played between two
                                        teams of eleven players each. It is the world's most popular sport, with millions of
                                        players and fans around the globe.
                                    </p>
                                    <p>
                                        The objective of football is to score goals by getting the ball into the opposing team's
                                        goal. Players use their feet to kick the ball and are not allowed to use their hands
                                        (except for the goalkeeper within the penalty area).
                                    </p>
                                    <p>
                                        Football matches are played on a rectangular field called a pitch, with goals at each
                                        end. The game is regulated by a set of rules established by FIFA (Fédération
                                        Internationale de Football Association), the governing body of world football.
                                    </p>

                                </div>
                                <h3 className='py-4 fw-normal'> History of Soccer
                                </h3>
                                <div className='col-md-8 '>
                                    <p>
                                        Modern football originated in Britain in the 19th century. Since before medieval times, “folk football” games had been played in towns and villages according to local customs and with a minimum of rules. Industrialization and urbanization, which reduced the amount of leisure time and space available to the working class, combined with a history of legal prohibitions against particularly violent and destructive forms of folk football to undermine the game status from the early 19th century onward. However, football was taken up as a winter game between residence houses at public (independent) schools such as Winchester College, Charterhouse, and Eton College. Each school had its own rules; some allowed limited handling of the ball and others did not. The variance in rules made it difficult for public schoolboys entering university to continue playing except with former schoolmates. As early as 1843 an attempt to standardize and codify the rules of play was made at the University of Cambridge, whose students joined most public schools in 1848 in adopting these “Cambridge rules,” which were further spread by Cambridge graduates who formed football clubs. In 1863 a series of meetings involving clubs from metropolitan London and surrounding counties produced the printed rules of football, which prohibited the carrying of the ball. Thus, the “handling” game of rugby remained outside the newly formed Football Association (FA). Indeed, by 1870 all handling of the ball except by the goalkeeper was prohibited by the FA.
                                    </p>
                                    <p>
                                        The new rules were not universally accepted in Britain, however; many clubs retained their own rules, especially in and around Sheffield. Although this northern English city was the home of the first provincial club to join the FA, in 1867 it also gave birth to the Sheffield Football Association, the forerunner of later county associations. Sheffield and London clubs played two matches against each other in 1866, and a year later a match pitting a club from Middlesex against one from Kent and Surrey was played under the revised rules. In 1871 15 FA clubs accepted an invitation to enter a cup competition and to contribute to the purchase of a trophy. By 1877 the associations of Great Britain had agreed upon a uniform code, 43 clubs were in competition, and the London clubs’ initial dominance had diminished.
                                    </p>

                                </div>
                                <div className='col-md-4'>
                                    <div className="card">
                                        <img src={footballimage2} alt="history of football" className="card-image rounded" />
                                        <div className="card-content py-2 ">
                                            <h5 className="card-title p-1">History of Soccer over years </h5>
                                            <p className="card-description p-1">This captivating image encapsulates the rich tapestry of soccer's storied past, showcasing pivotal moments that shaped the sport into the global phenomenon it is today. In the foreground, ancient depictions of ball games from diverse civilizations serve as a testament to soccer's ancient origins, illustrating humanity's enduring fascination with competitive play.</p>
                                        </div>
                                    </div>
                                </div>
                                <h3 className='py-4 fw-normal'> Key Milestones and Cultural Impact </h3>
                                <div className='d-flex '>

                                    <div className=' col-md-3 col-6 py-2 '>
                                        <img src={fotballimage3} alt="key milestones" className=' px-2 ' />
                                    </div>
                                    <div className='col-md-3 col-6  py-2 '>

                                        <img src={fotballimage4} alt="key milestones" className=' px-2 h-md-50' />
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <p className='fw-bolder fs-5'>
                                        <li>

                                            Key Milestones
                                        </li>
                                    </p>
                                    <p>
                                        <strong>1. Ancient Origins:</strong> Ancient ball games like "Cuju" in China (3rd century BCE)
                                        laid the groundwork for modern football.
                                    </p>
                                    <p>
                                        <strong>2. Medieval Football:</strong> Variations of football were played in medieval Europe,
                                        characterized by mob-style play between villages.
                                    </p>
                                    <p>
                                        <strong>3. Formation of Football Associations (1863):</strong> The founding of the Football
                                        Association (FA) in England established standardized rules for the game.
                                    </p>
                                    <p>
                                        <strong>4. Codification of Rules:</strong> The FA's rules led to the codification of football
                                        regulations, including the prohibition of handling the ball (except by goalkeepers) and the
                                        introduction of the offside rule.
                                    </p>
                                    <p>
                                        <strong>5. Early International Matches (1872):</strong> The first official international
                                        football match took place between England and Scotland, paving the way for international
                                        competitions.
                                    </p>
                                    <p>
                                        <strong>6. Formation of FIFA (1904):</strong> The Fédération Internationale de Football
                                        Association (FIFA) was founded, becoming the global governing body for football.
                                    </p>
                                    <p>
                                        <strong>7. Olympic Football (1900):</strong> Football debuted at the Olympic Games in Paris
                                        and became a permanent fixture in subsequent editions.
                                    </p>
                                    <p>
                                        <strong>8. First FIFA World Cup (1930):</strong> The inaugural FIFA World Cup was held in
                                        Uruguay, marking a milestone in international football.
                                    </p>
                                    <p>
                                        <strong>9. Post-War Growth and Expansion:</strong> Football experienced rapid growth and
                                        popularity post-World War II, with the rise of legendary players like Pelé and Diego Maradona.
                                    </p>
                                    <p>
                                        <strong>10. Modern Era and Globalization:</strong> Football became a global phenomenon with
                                        widespread broadcasting and commercialization, featuring prestigious leagues and superclubs.
                                    </p>
                                </div>
                                <div className="col-md-12 py-4">
                                    <p className='fw-bolder fs-5'>
                                        <li>
                                            Cultural Imapct
                                        </li>
                                    </p>
                                    <p>

                                        <strong>Unity and Identity: </strong>
                                        Football serves as a unifying force, bringing people together irrespective of nationality, ethnicity, or background. In many regions, supporting a football team becomes synonymous with local identity and pride, fostering a sense of belonging among fans.
                                    </p>
                                    <p>

                                        <strong>Passion and Expression: </strong>
                                        The passion ignited by football is palpable, fueling emotions and expressions that resonate beyond the pitch. Fans demonstrate unwavering loyalty, displaying team colors, chanting anthems, and celebrating victories with fervor, creating shared experiences that transcend language barriers.
                                    </p>
                                    <p>

                                        <strong>Community and Social Cohesion: </strong>
                                        Football cultivates social cohesion, forging connections within communities and fostering relationships across generations. Matches serve as communal gatherings, where diverse individuals unite in their shared love for the game, strengthening social bonds.
                                    </p>
                                    <p>

                                        <strong>Cultural Exchange and Diversity: </strong>
                                        International competitions like the FIFA World Cup facilitate cultural exchange, showcasing diverse traditions, music, and cuisine from participating nations. Football acts as a platform for cross-cultural dialogue, promoting understanding and appreciation of global diversity.
                                    </p>
                                    <p>

                                        <strong>Role Models and Inspiration: </strong>
                                        Iconic players and teams inspire admiration and aspiration, becoming cultural symbols of perseverance, skill, and sportsmanship. Football stars transcend sport, influencing fashion, music, and popular culture, serving as role models for aspiring athletes and youth.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === 'profile-tab-pane' ? 'show active' : ''}`}
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                    >
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 text-center p-5 bg-for-football text-white' >
                                    <h4 className='p-3'>
                                        Learn How To Play
                                    </h4>
                                </div>
                                <div className='col-md-6 py-4'>
                                    <p>
                                        Is it easy or difficult to learn how to play soccer? On one side the beauty of the game is that it is simple by nature so it can be played by all ages and abilities. The game however becomes more complex as the level increases just like any sport. This article aims to address some of the most common questions asked by those who want to learn how to play football by looking primarily at the technical side of the game which is the foundation upon which everything else should be built. There are some starting examples at the end to get you going using our unique soccer philosophy.

                                    </p>
                                </div>
                                <div className='col-md-6 text-center p-2 d-flex justify-content-center '>
                                    <iframe width="" height="" src="https://www.youtube.com/embed/TdHXmyGtMro?si=YiZWk7r2hm5OtsmW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className='w-80 h-100 m-1' allowFullScreen></iframe>
                                </div>
                                <div className='col-md-12'>
                                    <p>
                                        <b>
                                            Soccer technique covers a variety of areas which should all be practised when learning how to play football. It is all about building a relationship with the ball being equally comfortable playing soccer on both feet.
                                        </b>
                                    </p>




                                </div>
                                <div className='col-md-6 py-3 text-center '>
                                    <p className='text-center fs-5 fw-bold'>
                                        Ball Juggling
                                    </p>
                                    <p>You won’t use this in a match situation but this is all about building that relationship with the ball and getting comfortable controlling it.</p>
                                    <div className=''>
                                        <iframe width="" height="" src="https://www.youtube.com/embed/ouOY2vZaFtg?si=TQcQd-mY_NUsZeon" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className='w-md-50 ' allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className='col-md-6 py-3 text-center'>
                                    <p className='text-center fs-5 fw-bold'>
                                        Ball Mastery
                                    </p>
                                    <p>Running with the ball using both feet and 2 surfaces. Get those feet moving with ball in close control building a rhythm and tempo.</p>
                                    <iframe width="" height="" src="https://www.youtube.com/embed/pUVR5kHpMDU?si=wvREg7egk_Of9EDB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" className='' allowfullscreen></iframe>
                                </div>
                                <div className='col-md-6 py-3 text-center'>
                                    <p className='text-center fs-5 fw-bold'>
                                        Essential Footwork
                                    </p>
                                    <p> Learn to move the ball side to side incorporating turns and skills working off both feet. Football is not just played forward and back so learning how to move in different directions is very important.</p>

                                    <iframe width="" height="" src="https://www.youtube.com/embed/Xtev9CoEGQk?si=q1G0fr1NPycPo8JY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className='col-md-6 py-3 text-center'>
                                    <p className='text-center fs-5 fw-bold text-center'>
                                        Explosive Combinations
                                    </p>
                                    <p>A skill combination using multiple techniques with a final shot at the end. This progression moves in various directions using multiple surfaces and turning with explosive and dynamic movements.</p>
                                    <iframe width="" height="" src="https://www.youtube.com/embed/VNtVWaotw1o?si=KS-CaoEZifubOJpc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>

                                <div className="col-md-12">
                                    <p>

                                        Above are just some small examples of learning how to play soccer. Once you feel comfortable with the skills and techniques, start trying them out in an opposed environment and matches. Often it will be second nature as the muscle memory will kick in allowing you to concentrate more on building up the pictures and decision making. Don’t be afraid to make mistakes as that is when you will learn most and make sure you are having fun along the way.

                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === 'contact-tab-pane' ? 'show active' : ''}`}
                        id="contact-tab-pane"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                    >
                        <div className='container'>
                            <div className='row '>
                                <div className='col-md-12'>
                                    <h5>
                                        Rules of Game
                                    </h5>
                                    <p>
                                        The rules of football regarding equipment, field of play, conduct of participants, and settling of results are built around 17 laws. The International Football Association Board (IFAB), consisting of delegates from FIFA and the four football associations from the United Kingdom, is empowered to amend the laws. Modifications to the laws regarding the size of the field, ball, and goal, as well as the duration of the game, the use of return substitutes, and the use of temporary dismissals may be made for youth, senior, and disabled players and in grassroots or amateur football.
                                    </p>

                                </div>
                                <div className='col-md-12 py-3'>
                                    <p>
                                        <strong> Law 1: The Field of Play- </strong>

                                        This law determines the size and markings of the football field, called a football pitch, or a soccer field. The pitch is composed of natural or artificial grass, apart from recreational matches that sometimes take place in dirt ground.

                                        The pitch is divided into two equal halves by a line that runs parallel to the goal line and marks the playing surface. This line serves as the centre, and a circle of 9.15 meters is drawn around it.

                                        The touchlines must be 90-120 meters long and the same in length. The goal lines must be 45-90 meters wide and similar in width.
                                    </p>
                                    <p>
                                        Law 2: The Ball

                                        This law specifies the shape, size, and material composition of the ball. The law states that the standard diameter of a size 5 ball must be around 22 cm and a circumference of 68–70 cm. The soccer ball must weight between 410-450 g.
                                    </p>
                                    <p>
                                        Law 3: The Players

                                        There should be 11 eleven players in each team. Out of 11, one must be the goalie. A team must have at least seven players in order to be considered complete. The players should hit the ball with either their legs or torso. No player is permitted to use their hands or arms to touch the ball, except the goalkeepers.
                                    </p>
                                    <p>
                                        Law 4: The Players' Equipment

                                        Every player must wear a shirt, shorts, socks, shoes, and proper shin protection according to the rules. They may also choose to wear headgear, though it is not mandatory.

                                        Players are not allowed to use or wear any equipment that might be harmful to them or others, like rings. The goalie must be dressed differently than the other players and match officials.
                                    </p> <p>
                                        Law 5: The Referee

                                        A referee is an official who oversees a game. They have the last say in all matters, including disputes. They have the power to penalize a player, stop a match in case of a foul and have the general responsibility to oversee the game so that it goes without a hitch.
                                    </p>


                                </div>



                                <div className='col-md-6 py-3 '>
                                    <img src={fotballimage6} alt="" className='w-100 h-100' />
                                </div>
                                <div className='col-md-6 text-center py-3'>

                                    <img src={fotballimage5} alt="Pitch" className='w-100 h-90' />

                                </div>

                                <div className='col-md-6 py-3'>
                                    <p>
                                        Law 6: The Other Match Officials

                                        An assistant referee helps the referee in maintaining the decorum of the game. The assistant referees are placed on either side of the field and have the authority to step in the occurrence of an offence.
                                    </p>
                                    <p>
                                        Law 7: The Duration of the Match

                                        The gameplay time in a typical football match is 90 minutes long, which is divided into two halves of 45 minutes each. Between the two halves, there is a 15-minute halftime break. Full-time marks the conclusion of the game.
                                    </p>
                                    <p>
                                        Law 8: The Start and Restart of Play

                                        Every football game begins with a toss. The two team captains meet in the middle of the pitch with the match referee. The captain of the winning team (toss winner) chooses which goalpost to pursue in the first half, while the team that loses the toss kicks off the game.
                                    </p>
                                    <p>
                                        Law 9: The Ball In and Out of Play

                                        Ball in play and ball out of play are the two primary stages of play during a soccer game.

                                        The ball is said to be in play from the start of each playing period with a kick-off until the completion of the playing period. Only in rare cases does the ball leave the field of play or the referee stops play.
                                    </p>
                                    <p>
                                        Law 10: Determining the Outcome of a Match

                                        The objective of Football or Soccer is to score goals by kicking or passing the ball over the goal line and into the opponents goalposts. A goal is said to have been scored, if the ball crosses the goal line, between the goalposts, and under the crossbar, provided that no offence has been committed by the scoring team.
                                    </p>
                                </div>
                                <div className='col-md-6 col-12 '>
                                    <div className='d-flex  '>


                                        <div className='col-md-6 align-center p-1'>
                                            <img src={fotballimage7} alt="" className='img-fluid' />
                                        </div>
                                        <div className='col-md-6 p-1'>


                                            <img src={fotballimage3} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='col-md-6 align-center p-1'>


                                            <img src={fotballimage4} alt="" className='img-fluid' />
                                        </div>
                                        <div className='col-md-6 align-center p-1 d-none d-md-block'>


                                            <img src={fotballimage7} alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 py-3 text-center '>
                                    <h5 className='fs-2'>
                                        Watch Videos to play Soccer
                                    </h5>
                                </div>
                                <div className='d-md-flex'>

                                    <div className='col-md-6 col-12'>

                                        <video controls className='w-100 px-2'>
                                            <source src="video-for-football.mp4" type="video/mp4" />

                                        </video>
                                    </div>
                                    <div className='col-md-6 col-12 text-center m-auto'>
                                        <iframe width="" height="" src="https://www.youtube.com/embed/qknP-E-vPQ4?si=1hujZEXUf2Kdwiml" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen7 className=''></iframe>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>







        </>
    )
}
