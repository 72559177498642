// linkItemsData.js

export const linkItems = [
  {
    link: `${process.env.REACT_APP_ACCOUNT_URL}apps`,
    icon: "list",
    text: "Apps",
  },
  {
    link: `${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`,
    icon: "setting-alt",
    text: "Account Setting",
  },
];
