import React, { useState } from 'react';
import "../../../css/sports_css/sports.css"
import { buttonaway, pointsbutton } from '../../../helper/extraproperties';

export default function Hometeamcontrols({ selectedButtonIndex, handleButtonClick }) {
    const makeColor = (index) => {
        handleButtonClick(index);
    };

    const [pointButtonIndex, setpointButtonIndex] = useState(null);
    const handlePointIndex = (index) => {
        setpointButtonIndex(index);
    }

    return (
        <>
            <div className="">
                <div className=' '>
                    <h5 className='pt-1'>
                        HOME
                    </h5>
                    <div className='d-flex justify-content-evenly bg-teambox1 m-auto py-2 text-white '>

                        <p className='m-auto'>Player score </p>
                        <p className='m-auto'>
                            #10  Kane Scores 2 pts and 0 Fouls
                        </p>
                    </div>
                </div>
         
                <div className='bg-teambox2'>
                    <p className='pt-1'> Player Number </p>
                    <ul className="custom-control-group pb-1 justify-content-center">
                        {buttonaway.map((service, index) => (
                            <li key={service.id} className=' padding-button col-md-2 col-3  pb-1 ' >
                                <div className="custom-control custom-radio custom-control-pro no-control  ">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="btnRadio"
                                        id={`btnRadio${service.id}`}
                                        value={service.id}
                                        checked={selectedButtonIndex === index}
                                        onChange={() => handleButtonClick(index)}
                                    />
                                    <label
                                            className={`custom-control-label ${selectedButtonIndex === index ? 'greenbutton text-white' : ''
                                                }`}
                                        htmlFor={`btnRadio${service.id}`}
                                    >
                                        {service.number}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className='text-center bg-teambox1 pt-1'>
                        <ul className="custom-control-group pb-1 justify-content-center ">
                            {pointsbutton.map((points, index) => (
                                <li key={points.id}>
                                    <div className="custom-control custom-radio custom-control-pro no-control">
                                        <input
                                            type="radio"
                                            className="custom-control-input "
                                            name="btnRadiopoints"
                                            id={`btnRadiopoint${points.id}`}
                                            value={points.id}
                                            checked={pointButtonIndex === index}
                                            onChange={() => handlePointIndex(index)}
                                        />
                                        <label
                                            className={`custom-control-label  px-4 m-1 ${pointButtonIndex === index ? 'greenbutton  ' : ''
                                                }`}
                                            htmlFor={`btnRadiopoint${points.id}`}
                                        >
                                            {points.number}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}
