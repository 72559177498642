import { fetchHelper } from "./httphelper";

const POST_METHOD = "POST";
const GET_METHOD = "GET";
const PUT_METHOD = "PUT";
const PATCH_METHOD = "PATCH";
const DELETE_METHOD = "DELETE";

export const GETSPORT = "get-sport"
export const GETTOURNAMENT = "tournament"
export const VERSIONCONTROL = "version-control"
export const ADDSPORT = "add-sport"
export const UPDATESPORT = "update-sport";
export const DELETESPORT = "delete-sport"
export const ADDSPORTTEAM = "team/add-team"
export const GETALLTEAMS = "team/get-list-team"
export const GETTEAMPERSPORT = "team/get-team"
export const DELETETEAMSPORT = "team/delete-team"
export const UPDATETEAMSPORT = "team/update-team"


export const FETCHING_ITEMS_LIMIT = 10

const optionsCallBack = (options, pageParam) => {
    return {
        limit: FETCHING_ITEMS_LIMIT,
        cursur: pageParam * FETCHING_ITEMS_LIMIT,
        ...options,
    }
}

const valueToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTQxOTQ5OTUsImlhdCI6MTcxNDEwODU5NSwianRpIjoiMzgzNTk3OTBjYmQzZjJlNzcwZGQ0NzRjNzU4M2UyOGEiLCJ1c2VybmFtZSI6ImFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJjbG91ZFVzZXJJZCI6Miwicm9sZSI6eyJpZCI6MiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJrZXkiOiJhZG1pbiJ9fQ.6ckp7Nfi_ujqGLxH0wN7ni61cvDhO5clKfnD5hkleDo"

class CRUDMethods {
    constructor(endpoint) {
        this.endpoint = endpoint
    }

    async create(accessToken = process.env.REACAT_APP_TOKEN, body = null, customePostName = undefined) {
        let path = this.endpoint;
        if (customePostName) { path += "/" + customePostName }
        return fetchHelper(path, POST_METHOD, accessToken = process.env.REACAT_APP_TOKEN, body);
    }

    async get(accessToken = process.env.REACAT_APP_TOKEN, body = null, options = null, pageParam = 0) {
        return fetchHelper(this.endpoint, GET_METHOD, accessToken = process.env.REACAT_APP_TOKEN, body, optionsCallBack(options, pageParam));
    }


    async getAll(accessToken = process.env.REACAT_APP_TOKEN, body = null, options = null, customegetName = undefined) {
        let path = this.endpoint
        if (customegetName) { path += "/" + customegetName }
        return fetchHelper(path, POST_METHOD, accessToken = process.env.REACAT_APP_TOKEN, body, options);
    }

    async getOne(accessToken = process.env.REACAT_APP_TOKEN, id) {
        return fetchHelper(this.endpoint + "/" + id, GET_METHOD, accessToken = process.env.REACAT_APP_TOKEN);
    }

    async put(accessToken = process.env.REACAT_APP_TOKEN, body = null, id) {
        return fetchHelper(this.endpoint + "/" + id, PUT_METHOD, accessToken = process.env.REACAT_APP_TOKEN, body)
    }

    async patch(accessToken = process.env.REACAT_APP_TOKEN, body = null, id) {
        return fetchHelper(this.endpoint + "/" + id, PATCH_METHOD, accessToken = process.env.REACAT_APP_TOKEN, body)
    }

    async delete(accessToken = process.env.REACAT_APP_TOKEN, options = null, customegetName = undefined) {
        let path = this.endpoint
        if (customegetName) { path += "/" + customegetName }
        return fetchHelper(path, POST_METHOD, accessToken = process.env.REACAT_APP_TOKEN, options, null);
    }
}

export var endpoints = {
    getSport: new CRUDMethods(GETSPORT),
    Tournament: new CRUDMethods(GETTOURNAMENT),
    versionControl: new CRUDMethods(VERSIONCONTROL),
    addSport: new CRUDMethods(ADDSPORT)
}