import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { Dataview } from "../../../components/genericComponents/Dataview"
import { LegueTeams } from "../../../helper/extraproperties"
import { TeamCard } from "./TeamCard"
import { TeamLegueCard } from "./TeamLegueCard"


export const TeamLeague = () => {

    return (
        <>
            <div className={"col-sm-7  col-12  pt-3 d-block"}>
                <div className="col-12 ">
                    <Card className="card-bordered border-0">
                        <CardBody className="card-inner">
                            <CardTitle className="fs-5 fw-bolder">FIFA World Cup</CardTitle>
                            <CardText>
                                <div className="overflow-y-scroll overflow-x-visible" style={{ height: "100dvh" }}>
                                    {
                                        LegueTeams.map(element => {
                                            return (
                                                <TeamCard item={element} />
                                            )
                                        })
                                    }
                                </div>
                            </CardText>
                            {/* <div className="d-flex justify-content-center">
                                <button type="button" class="btn btn-primary">
                                    view All
                                </button>
                            </div> */}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}