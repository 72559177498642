import React, { useEffect, useState } from "react";
import { Display } from "react-7-segment-display";
import logoimg1 from "../../images/game/fcb.png";
import logoimg2 from "../../images/game/rm.png";
import { useParams } from "react-router";

const initialMinutes = 0;
const initialSeconds = 1;

export default function Soccerbigcard() {
  const params = useParams();
  const initialTotalSeconds = initialMinutes * 60 + initialSeconds;
  const [totalSeconds, setTotalSeconds] = useState(initialTotalSeconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (totalSeconds > 0) {
        setTotalSeconds((prevSeconds) => prevSeconds + 1);
      } else {
        clearInterval(intervalId);
        // Timer completed, you can trigger a callback or action here
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [totalSeconds]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Format minutes and seconds with leading zeros if necessary
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return (
    <>
      <section className="bg-black scoreboard p-3 p-lg-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-12 rounded-2 position-relative ">
              <div className="row justify-content-center">
                <h3 className="text-center fw-bold py-2 mb-3 text-bg-dark custom-letter-spacing bg-gradient rounded-3 col-lg-8">
                  LA LIGA FOOTBALL LEAGUE
                </h3>
                <div className="col-12 d-flex justify-content-center py-2">
                  <div className="clock-effect z-2 position-relative">
                    <div className="d-flex align-items-center">
                      {
                        <Display
                          value={formattedMinutes}
                          count={2}
                          height={50}
                          backgroundColor="black"
                          color="#0db30d"
                          className="mx-auto"
                        />
                      }
                      <div className="clock">
                        <span className="timer">:</span>
                      </div>
                      {
                        <Display
                          value={formattedSeconds}
                          count={2}
                          height={50}
                          backgroundColor="black"
                          color="#0db30d"
                          className="mx-auto"
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="col-6 col-lg-5 text-center">
                  <div className="text-center">
                    <img src={logoimg1} alt="" />
                    <h6 className="fs-18px p-2 rounded-pill bg-black text-azure-dim">BARCELONA</h6>
                  </div>
                </div>
                <div className="col-6 col-lg-5 text-center">
                  <div className="text-center">
                    <img src={logoimg2} alt="" />
                    <h6 className="fs-18px p-2 rounded-pill bg-black text-azure-dim">REAL MADRID</h6>
                  </div>
                </div>
                <div className="col-6 col-lg-5 pt-4 text-center">
                  <Display value="03" count={2} height={70} backgroundColor="" className="mx-auto" />
                  <ul className="mt-3">
                    <li className="fs-12px text-white">Leo Messi (14', 43')</li>
                    <li className="fs-12px text-white">Neymar Jr. (35')</li>
                  </ul>
                </div>
                <div className="col-6 col-lg-5 pt-4 text-center ">
                  <Display value="02" count={2} height={70} backgroundColor="" />
                  <ul className="mt-3">
                    <li className="fs-12px text-white">Benzema (12')</li>
                    <li className="fs-12px text-white">Kross (40')</li>
                  </ul>
                </div>
              </div>
              <h3 className="my-4 text-center text-white text-space ">
                1<sup>st</sup> HALF
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
