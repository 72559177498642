import React from "react";
import { useReducer, useRef, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { deepCopyObject } from "../../helper/helper";
import AddProperty from "../../components/genericComponents/Addproperty"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GAMES, SchemaTypes, SportPropertylist } from "../../helper/extraproperties";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../Component";

const ADD_PROPS_TYPE = "Add_Property";
const REMOVE_PROPS_TYPE = "Remove_Property";
const REMOVE_ITEM_FILEDATA = "Remove_Item_FileDatas";
const ADD_FILE = "Add_File"

const reducer = (state, action) => {
    var currentState = {}
    currentState.currentData = deepCopyObject(state.currentData);
    currentState.files = [...state.files]

    switch (action.type) {
        case ADD_PROPS_TYPE:
            currentState.currentData[action.payload.name] = action.payload.value;
            break;
        case ADD_FILE:
            let indexToRemove = action.payload.index
            currentState.files.splice(indexToRemove, 1);
            currentState.files.push(action.payload.value)
            currentState.currentData[action.payload.name] = currentState.files.length - 1
            break;
        case REMOVE_PROPS_TYPE:
            delete currentState.currentData[action.payload];
            break;
        default:
    }

    return currentState;
};


const FileReducer = (file, action) => {
    var newFiles = [...file];

    switch (action.type) {
        case SchemaTypes.IMAGE:
        case SchemaTypes.file:
            newFiles.push(action.payload.e);
            break;
        case REMOVE_ITEM_FILEDATA:
            let indexToRemove = newFiles.findIndex(element => element.name === action.element);
            if (indexToRemove !== -1) {
                newFiles.splice(indexToRemove, 1);
            }
            break;
        default:
    }
    return newFiles;
}

export default function GenericForm({
    formName = "Add New Sport ",
    addButtonText = "save",
    currentData = { currentData: {}, files: [] },
    querryFunction = () => { },
    propertyList = SportPropertylist,
    queryKeyValue = GAMES,
    navigateTo = "/",
    Onsuccess,
    closeModal,
    ModalInitialState = {}
}) {
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(reducer, currentData);
    const [file, setfile] = useReducer(FileReducer, []);
    const [isverified, setIsVerified] = useState(false);
    const [verify, setVerify] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()
    const formRef = useRef();
    const {action} = useParams()
    
    const { data, isLoading, error, mutate } = useMutation(
        () => querryFunction(state, file),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    predicate: (query) => query.queryKey.includes(queryKeyValue),
                });
            },
        }
    );

    const handleDelete = (element, data) => {
        formRef.current.reset();
        if (
            data.item.type === SchemaTypes.IMAGE
            || data.item.type === SchemaTypes.file) {
            setfile({ type: REMOVE_ITEM_FILEDATA, element });
        } else {
            dispatch({ type: REMOVE_PROPS_TYPE, payload: data });
        }
    }

    const Onchange = (e, data, index) => {
        if (
            data.item.type === SchemaTypes.Image
            || data.item.type === SchemaTypes.file) {
            dispatch({ type: ADD_FILE, payload: { ...e, index: index } });
        } else {
            dispatch({ type: ADD_PROPS_TYPE, payload: e });
        }
    }

    const GetCurrentValue = (data) => {
        if (
            data.item.type === SchemaTypes.IMAGE
            || data.item.type === SchemaTypes.file) {
            return file.find(item => item.name === data.item.name);
        } else {
            return state.currentData[data.item.name]
        }
    }

    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            mutate();
            ModalInitialState()
        } else {
            form.classList.add("was-validated");
        }
    };

    const handleRedirect = () => {
        navigate(navigateTo);
    };

    var groups = [];
    var singleProps = [];

    propertyList.forEach((data) => {
        if (data.item.groupName) {
            groups.push(data);
        } else {
            singleProps.push(data);
        }
    });

    if (isLoading) return <div className="spinner-border m-5" role="status" />;

    if (error && error !== "") {
        return (
            <div className="vstack gap-2 p-2">
                <div className="alert alert-danger" role="alert">
                    <div className="text-center fw-bold">Failed!</div>
                    <div>{error}</div>
                </div>
                <div className="ms-auto hstack gap-2">

                    <button className="btn btn-primary" onClick={() => handleRedirect()}>
                        ok
                    </button>
                </div>
            </div>
        );
    }

    if (data) {
        if (Onsuccess) {
            return Onsuccess(data)
        }
        return (
            <div className="vstack gap-2 p-2">
                <div className="alert alert-success" role="alert">
                    {addButtonText + " succeded with ID: " + data.id}
                </div>
                <button className="ms-auto btn btn-primary" autoFocus={true} onClick={() => handleRedirect()}>
                    ok
                </button>
            </div>
        );
    }


    return (

        <div className="container">
            <p className="fs-2 fw-medium pt-1">{action} form</p>
            <form
                onSubmit={handleSubmit}
                className="card needs-validation rounded border border-1 p-2"
                ref={formRef}
                noValidate
            >
                {singleProps.map((data, index) => {
                    return (
                        <AddProperty
                            deleteField={(element) => {
                                handleDelete(element, data);
                            }}
                            key={index}
                            data={data}
                            currentValue={GetCurrentValue(data)}
                            onChange={(e) => {
                                Onchange(e, data, index)
                            }}
                        />
                    );
                })}
                <hr />
                <Button type="submit" color="primary" className='ms-auto float-start align-self-end '>{addButtonText}</Button>
            </form>
        </div>
    );
}