import React from "react";

const Matches = () => {
  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="row">
            <h3>
              Matches
            </h3>
            <div className="col-md-6 col-lg-4">
              <div className="shadow pb-4">
                <h5 className="bg-primary p-2 text-white">Recent Match</h5>
                <div className="row">
                  <div className="col-5 h-100">
                    <div className="text-center">
                      <img src="fcb.png" alt="" />
                      <h6>Barcelona</h6>
                      <p className="fw-bold fs-5">3</p>
                      <ul>
                        <li className="fs-12px">Leo Messi (14', 90+5')</li>
                        <li className="fs-12px">Neymar Jr. (35')</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-2 my-auto text-center">
                    <span className="bg-primary p-2 text-white">vs</span>
                  </div>
                  <div className="col-5">
                    <div className="text-center">
                      <img src="rm.png" alt="" />
                      <h6>Real Madrid</h6>
                      <p className="fw-bold fs-5">2</p>
                      <ul>
                        <li className="fs-12px">Benzema (40')</li>
                        <li className="fs-12px">Kross (68')</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-8">
              <div className="shadow pb-4">
                <h5 className="bg-primary p-2 text-white">Upcoming Match</h5>
                <div className="row">
                  <div className="col-5">
                    <div className="text-center">
                      <img src="logo_1.png" alt="" />
                    </div>
                  </div>
                  <div className="col-2 my-auto text-center">
                    <span className="bg-primary p-2 text-white">vs</span>
                  </div>
                  <div className="col-5">
                    <div className="text-center">
                      <img src="logo_2.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <h5 className="text-primary">World Cup League</h5>
                  <p className="mb-1 fs-15px">December 09th, 2024 9:30 AM GMT+0</p>
                  <p className="mb-1 fs-14px text-primary"> New Euro Arena</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Matches;
