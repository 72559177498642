import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col } from "reactstrap"

export const ShimmerCard = () => {
    return (
        <Col lg="4 py-1">
            <Card className="card-bordered h-100 game-effect">
                <div className=" figure position-relative overflow-hidden">
                    <ShimmerThumbnail height={250} rounded />
                </div>
                <CardBody className="card-inner d-flex flex-column justify-content-between">
                    <div>
                        <ShimmerText line={1} gap={15} />
                        <ShimmerText line={1} gap={15} />    
                    </div>

                    <div className="card-footer bg-transparent p-0">
                        <ShimmerText line={1} gap={15} />
                        <ShimmerText line={1} gap={15} />
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}