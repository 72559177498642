export const BasketBallTab = ({ activeTabClass, title, ariaControls, ariaSelected, handleTabClick }) => {
    return (
        <li className="nav-item" role="presentation">
            <button
                className={activeTabClass}
                onClick={() => handleTabClick(ariaControls)}
                role="tab"
                aria-controls={ariaControls}
                aria-selected={ariaSelected}
            >
                {title}
            </button>
        </li>
    )
}