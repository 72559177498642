import React, { useState } from 'react';
import "../../../css/sports_css/sports.css"


export default function Awayteamcontrols({ selectedButtonIndexSecond, handleButtonClick }) {
    const buttonaway = [
        { id: 13, number: "02" },
        { id: 14, number: "06" },
        { id: 15, number: 13 },
        { id: 16, number: 15 },
        { id: 17, number: 17 },
        { id: 18, number: 19 },
        { id: 19, number: 12 },
        { id: 20, number: "05" },
        { id: 21, number: 10 },
        { id: 22, number: "01" },
        { id: 23, number: 20 },
        { id: 24, number: "09" },
    ];



    const [pointButtonIndex1, setpointButtonIndex1] = useState();
    const handlePointIndex = (index) => {
        setpointButtonIndex1(index);
    }

    const pointsbutton2 = [
        { id: 4, number: "1 pts" },
        { id: 5, number: "2 pts" },
        { id: 6, number: "3 pts" },
    ]

console.log(pointButtonIndex1);
    return (
        <>
            <div className="">
                <div className=' '>

                    <h5 className='pt-1'>
                        AWAY
                    </h5>
                    <div className='d-flex justify-content-evenly bg-teambox1 m-auto py-2 text-white '>

                        <p className='m-auto'>Player score </p>
                        <p className='m-auto'>
                            #03  Mickel Scores 2 pts and 0 Fouls
                        </p>
                    </div>
                </div>
                <div className='bg-teambox2'>
                    <p className='pt-1'> Player Number </p>
                    <ul className="custom-control-group pb-1 justify-content-center ">
                        {buttonaway.map((service, index) => (
                            <li key={service.id} className='padding-button col-md-2 col-3 pb-1 '>
                                <div className="custom-control custom-radio custom-control-pro no-control">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="btnRadio"
                                        id={`btnRadio${service.id}`}
                                        value={service.id}
                                        checked={selectedButtonIndexSecond === index}
                                        onChange={() => handleButtonClick(index)}
                                    />
                                    <label
                                        className={`custom-control-label ${selectedButtonIndexSecond === index ? 'greenbutton text-white' : ''
                                            }`}
                                        htmlFor={`btnRadio${service.id}`}
                                    >
                                        {service.number}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='bg-teambox1 pt-2'>
                    <ul className="custom-control-group pb-1 justify-content-center ">
                        {pointsbutton2.map((points, index) => (
                            <li key={points.id}>
                                <div className="custom-control custom-radio custom-control-pro no-control">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="btnRadioxyz"
                                        id={`btnRadioxy${points.id}`}
                                        value={points.id}
                                        checked={pointButtonIndex1 === index}
                                        onChange={() => handlePointIndex(index)}
                                    />
                                    <label
                                        className={`custom-control-label px-4 m-1 ${pointButtonIndex1 === index ? 'greenbutton text-white' : ''
                                            }`}
                                        htmlFor={`btnRadioxy  ${points.id}`}
                                    >
                                        {points.number} 
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>


            </div>
        </>
    )
}
