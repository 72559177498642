import React from 'react'
import cricket from "../../images/game/cricket.jpg"

export default function Basketballinfo() {
  return (
    <>
    

    </>
  )
}
