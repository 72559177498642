export const GenericList = ({ listItem, listTitle, subTitle = undefined }) => {
    return (
        <div className="p-2  d-flex flex-column ">
            <h6 className="text-black fw-medium">
                {listTitle}
            </h6>
            <div className="fs-5 fw-bold  text-start">
                {subTitle ? subTitle : null}
            </div>
            <ul className="">
                {
                    listItem.map((element, index) => {
                        return (
                            <li key={index} className="mx-2 text-black listStyle fs-15px">{element}</li>
                        )
                    }
                    )
                }
            </ul>
        </div>

    )
}