import React from "react";
import { Card } from "reactstrap";

const Matchlist = () => {
  return (
    <>
      <section className="p-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10 bg-body-secondary">
              <div className="row">
                <h4 className="bg-dark-dim p-1 text-center">Fixtures & Results</h4>
                <div className="d-flex justify-content-between my-2 align-items-center">
                  <h5 className="">Recent matches</h5>
                  <button type="button" className="btn bg-azure text-white">
                    View All
                  </button>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Wednesday 10 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Rugby</span>
                    </p>
                    <div className="text-center">
                      <small className="fs-13px fw-bold">
                        Pool World Rugby Pacific Challenge
                        <br /> 2024, Match 1
                      </small>
                      <div className="d-flex align-center justify-content-around">
                        <div>
                          <div className="team-logo-img">
                            <img src="fiji-warriorarriors.jpg" alt="" />
                          </div>
                          <p className="fw-bold mt-2">Fiji Warriors</p>
                        </div>
                        <div className="bg-blue mx-1 rounded-2 text-white">
                          <span className="fw-bold px-1 fs-6">17</span>
                          <span>|</span>
                          <span className="fw-bold px-1 fs-6">29</span>
                        </div>
                        <div>
                          <div className="team-logo-img">
                            <img src="manuma-samoa.jpg" alt="" />
                          </div>
                          <p className="fw-bold mt-2">Manuma Samoa</p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Thursday 18 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Football</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">Champions League Final Stage</small>
                    <div className="d-flex justify-content-around mt-2 align-items-center">
                      <div className="team-logo-img text-center">
                        <img src="bayern.jpg" alt="" />
                        <p className="fw-bolder mt-2">Bayern Munich</p>
                      </div>
                      <div className="mx-1 fs-5 fw-bold">1 - 1</div>
                      <div className="team-logo-img text-center">
                        <img src="realmadrid.jpg" alt="" />
                        <p className="fw-bolder mt-2">Real Madrid</p>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Monday 15 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Cricket</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">33rd Match • Indian Premier League 2024</small>
                    <div className="my-2">
                      <div className="d-flex align-items-center">
                        <div className="w-50 ">
                          <img src="gujarat-titans.webp" alt="" />
                          <span className="fs-15px fw-bold ps-1">GT</span>
                        </div>
                        <span className="fw-bold">89 (17.3)</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-50 ">
                          <img src="delhi-capitals.webp" alt="" />
                          <span className="fs-15px fw-bold ps-1">DC</span>
                        </div>
                        <span className="fw-bold">92-4 (8.5)</span>
                      </div>
                    </div>
                    <p className="text-azure">Delhi Capitals won by 6 wkts</p>
                  </Card>
                </div>
                {/* <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Friday 12 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Rugby</span>
                    </p>
                    <div className="text-center">
                      <small className="fs-13px fw-bold">
                        Stade de Marseille, Marseille
                        <br /> 2024, Match 12
                      </small>
                      <small className="d-block">Apia Park</small>
                      <div className="d-flex align-center justify-content-around">
                        <div>
                          <div className="team-logo-img">
                            <img src="arg.jpg" alt="" />
                          </div>
                          <span className="fw-bold">ARG</span>
                        </div>
                        <div className="bg-blue mx-1 rounded-2 text-white">
                          <span className="fw-bold px-1 fs-6">34</span>
                          <span>|</span>
                          <span className="fw-bold px-1 fs-6">15</span>
                        </div>
                        <div>
                          <div className="team-logo-img">
                            <img src="wal.jpg" alt="" />
                          </div>
                          <span className="fw-bold">WAL</span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div> */}
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Thursday 18 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">BasketBall</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">12th Match - NBA</small>
                    <div className="my-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="team-logo-img">
                          <img src="bull.png" alt="" />
                          <span className="fs-15px fw-bold ps-1">Chicago Bulls</span>
                        </div>
                        <span className="fw-bold">- 105</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between  mt-2">
                        <div className="team-logo-img">
                          <img src="boston.png" alt="" />
                          <span className="fs-15px fw-bold ps-1">Boston Celtics</span>
                        </div>
                        <span className="fw-bold">- 116</span>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="d-flex justify-content-between my-2 align-items-center">
                  <h5 className="">Live matches</h5>
                  <button type="button" className="btn bg-azure text-white">
                    View All
                  </button>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100 position-relative">
                    <span className="live"></span>
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Thursday 18 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">BasketBall</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">19.04.2024 / 11:00 AM</small>
                    <small className="fs-12px fw-bold text-center">VENUE: Fernando Buesa Arena</small>
                    <div className="row mt-2">
                      <div className="team-logo-img text-center col-4">
                        <img src="baskonia.png" alt="" />
                        <p className="mt-2">Baskonia</p>
                      </div>
                      <div className="text-center col-4">
                        <p className="fw-bold fs-5 mb-0">47 - 27</p>
                        <span className="text-primary fw-bold">Half Time</span>
                      </div>
                      <div className="team-logo-img text-center col-4">
                        <img src="bolonga.png" alt="" />
                        <p className="mt-2">Virtus Bologna</p>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="d-flex justify-content-between my-2 align-items-center">
                  <h5 className="">Upcoming matches</h5>
                  <button type="button" className="btn bg-azure text-white">
                    View All
                  </button>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Thursday 18 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">BasketBall</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">21.04.2024 / 20:30 PM</small>
                    <small className="fs-12px fw-bold text-center">VENUE: Fernando Buesa Arena</small>
                    <div className="row mt-2">
                      <div className="team-logo-img text-center col-5">
                        <img src="baskonia.png" alt="" />
                        <p className="mt-2">Baskonia</p>
                      </div>
                      <span className="fw-bold fs-2 text-center col-2">-</span>
                      <div className="team-logo-img text-center col-5">
                        <img src="bolonga.png" alt="" />
                        <p className="mt-2">Virtus Bologna</p>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-md-4 col-xl-3 mb-2">
                  <Card className="shadow-sm rounded-2 p-2 h-100">
                    <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                      <span>Thursday 18 April</span>
                      <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Football</span>
                    </p>
                    <small className="fs-13px fw-bold text-center">FRANCE: LIGUE 1 - ROUND 30</small>
                    <small className="fs-12px fw-bold text-center">19.04.2024 / 21:00</small>
                    <div className="row mt-2">
                      <div className="team-logo-img text-center col-5">
                        <img src="nice.png" alt="" />
                        <p className="mt-2">Nice</p>
                      </div>
                      <span className="fw-bold fs-2 text-center col-2">-</span>
                      <div className="team-logo-img text-center col-5">
                        <img src="lort.png" alt="" />
                        <p className="mt-2">Lorient</p>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Matchlist;
