import GenericChangeEndpointModal from "../../components/genericComponents/GenericChangeEndpointModal"
import { SportPropertylist, filePropertylist } from "../../helper/extraproperties"

export const AddSport = () => {
    const queryFunction = ()=>{

    }
    const queryKey = []
    
    return (
        <GenericChangeEndpointModal
            propertyList={SportPropertylist}
            currentData={{currentData:{},files:[]}}
            formName={"Add Sport"}
            addButtonText={"click"}
            querryFunction={() => { }}
            queryKeyValue={["getAll"]}
            navigateTo={"/"}

        />
    )
}