import React from 'react'

export default function Playerstat() {
  return (
<>
<table class="table " style={{backgroundColor:"black"}}>
  <thead>
    <tr className=''>
      <th scope="col">#</th>
      <th scope="col">1st</th>
      <th scope="col">2nd</th>
      <th scope="col">3rd</th>
      <th scope="col">4th</th>
      <th scope="col">OT</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Fouls</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <th scope="row">Rev</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr>
      <th scope="row">Asst</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

    </tr>
    <tr>
      <th scope="row">Stls</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

    </tr> <tr>
      <th scope="row">FG%</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

    </tr> 
    <tr>
      <th scope="row" className=''>PTS</th>
      <td>2</td>
      <td></td>
      <td></td>
      <td></td>

      <td></td>
    </tr>
    <tr>
      
    </tr>
  </tbody>
</table>
</>
  )
}
