import { ShimmerText } from "react-shimmer-effects"
import { HomePageWrapper } from "./HomePageWrapper"
import { ShimmerCard } from "./ShimmerCard"

export const Shammer = () => {
    return (
        <>
            <HomePageWrapper>
            <ShimmerText line={1} gap={15} />

                <ShimmerCard />
                <ShimmerCard />
                <ShimmerCard />
            </HomePageWrapper>
        </>
    )
}