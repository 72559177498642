import React from 'react'
import testimage from "../../../images/game/footabll.jpg"

export default function Media() {

    const mediaControls = [
        { title: "File1", 
        img : testimage},
        { title: "File2",
        img : testimage },
        { title: "File3" },
        { title: "File4" },
        { title: "File5" },
        { title: "File6" },
        { title: "File7" },
        { title: "File8" }
    ]
    return (
        <>
      <div className='conatiner-fluid p-2 m-0' >
            <div className='row m-0'>
                {mediaControls.map((element, index) => (
                    <div className='col-md-3 py-2' key={index}>
                        <div class="card ">
                            <img class="card-img-top img-fliud " src={testimage} alt="Card image cap" />
                            <div class="card-body text-center ">    
                                <h5 class="card-title">{element.title}</h5>

                            </div>
                        </div>
                    </div>

                    ))}

            </div >
     </div>
        </>
    )
}
