import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import Cookies from "js-cookie";
import AppWrap from "./global/AppWrap";
import "../css/sports_css/sports.css";
import Appbar from "./appbar/Appbar";
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
const Layout = ({ title, app, ...props }) => {
  const [authChecking, setAuthChecking] = useState(true);

  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: expires });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain:  process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);
  if (authChecking) return <>Redirecting to authentication</>;
  return (
    <>
      <Head title={!title && "Games"} />
      <AppRoot>
        <AppMain>
          <Sidebar fixed />
          <AppWrap>
            <Header fixed />
            <Outlet />
            <Footer />
          </AppWrap>
        </AppMain>
        <ToastContainer/>
      </AppRoot>
    </>
  );
};
export default Layout;
