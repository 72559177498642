import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { GAMES, SchemaTypes, SportPropertylist } from "../helper/extraproperties";
import { ADDSPORT, endpoints } from "../Endpoints/endpoints";
import { HomePageCard } from "./Homepage/HomepageCard";
import { HomePageWrapper } from "./Homepage/HomePageWrapper";
import { Shammer } from "./Homepage/Shammer";
import Error404Classic from "./error/404-classic";
import { getCookie } from "../utils/Utils";
import GenericChangeEndpointModal from "../components/genericComponents/GenericChangeEndpointModal";
import axios from "axios";
import { IoMdAdd } from "react-icons/io";


import { AxiosHelper } from "../helper/helper";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
;
const Homepage = () => {
  const [operaton, setOperation] = useState({
    Add: false
  })

  const bigNumber = 9007199254740991;

  const Querykey = [GAMES]

  const queryClient = useQueryClient()

  const InvalidateGames = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes(GAMES),
    });
  }

  const AddSport = async (state, file) => {
    let requestObject = {
      ...state.currentData
    }
    SportPropertylist.map(element => {
      if (element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file) {
        var file = state.files[requestObject[element.item.name]]
        requestObject[element.item.name] = file
      }
    })
    const formData = new FormData()
    SportPropertylist.map(element => {
      formData.append(element.item.name, requestObject[element.item.name]);
    })
    const data = await AxiosHelper(ADDSPORT, formData)
    InvalidateGames()
    return data;
  }

  const queryfunction = async () => {
    let data = await endpoints.getSport.getAll(process.env.REACAT_APP_TOKEN, { limit: bigNumber }, null, null)
    return Object.values(data.data)[0]
  }

  const { data, isLoading, error } = useQuery(
    {
      queryFn: queryfunction,
      queryKey: Querykey
    }
  )


  if (isLoading) {
    return <Shammer />
  }
  if (error) {
    return <Error404Classic />
  }

  if (operaton.Add) {
    return (<GenericChangeEndpointModal
      propertyList={SportPropertylist}
      currentData={{ currentData: {}, files: [] }}
      formName={"Add Sport"}
      addButtonText={"Save"}
      querryFunction={AddSport}
      queryKeyValue={GAMES}
      navigateTo={"/"}
      closeModal={() => setOperation((prev) => ({ ...prev, Add: !prev.Add }))}
      ModalInitialState={() => setOperation((prev) => ({ Add: false }))}
    />)
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between ">
        <div>
          <h3 className="d-flex py-2">Sport List</h3>
          <p>View All Sports here!</p>
        </div>
        <div className="py-2 px-2 ">
          <Button onClick={() => setOperation((prev) => ({ ...prev, Add: true }))} color="primary">Add Sport</Button>
        </div>
      </div>
      <HomePageWrapper>
        {
          data.map((element, index) => {
            return (
              <HomePageCard
                key={index}
                item={element}
              />
            )
          })
        }

      </HomePageWrapper>

    </div>
  );
};

export default Homepage;