import React from 'react'
import { ShimmerButton, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects"


export default function ShimmerTeamperSport() {
    return (
        <>
            <div className='col-md-12 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 '>
                    {/* <ShimmerTitle line={1} variant="secondary" /> */}
                </div>
                <div className=''>
                    <ul className="list-group ">
                        <li className="list-group-item">
                            <ShimmerTitle line={1} variant="secondary" />

                        </li>
                        <li className="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} width={"100%"} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                          <ShimmerTitle line={1} variant="secondary" />

                        </li>
                        <li className="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    )
}
