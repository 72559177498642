import { ShimmerButton, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects"
import { Button } from "reactstrap"

export const ShimmerTeam = () => {
    return (
        <div className="d-flex flex-wrap">
            <div className='col-md-6 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 '>
                    <ShimmerTitle line={1} variant="secondary" />
                </div>
                <div className='d-flex flex-column'>
                    <ul class="list-group ">
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <div className='col-md-6 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 '>
                    <ShimmerTitle line={1} variant="secondary" />
                </div>
                <div className='d-flex flex-column'>
                    <ul class="list-group ">
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <div className='col-md-6 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 '>
                    <ShimmerTitle line={1} variant="secondary" />
                </div>
                <div className='d-flex flex-column'>
                    <ul class="list-group ">
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <div className='col-md-6 border-1 border p-1'>
                <div className='d-flex flex-row justify-content-between p-2 '>
                    <ShimmerTitle line={1} variant="secondary" />
                </div>
                <div className='d-flex flex-column'>
                    <ul class="list-group ">
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div className='d-flex justify-content-start'>
                                <div className='d-flex flex-row g-2'>
                                    <div className=''>
                                        <ShimmerThumbnail height={50} width={50} rounded />
                                    </div>
                                    <ShimmerTitle line={1} variant="secondary" />
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    )
}