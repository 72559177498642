import { useQuery } from "react-query"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { GETTOURNAMENT, endpoints } from "../../../Endpoints/endpoints"
import { FootballLeagues, tournamentListGet } from "../../../helper/extraproperties"
import { TeamLegueCard } from "./TeamLegueCard"
import "../../../css/Team/Team.css"
import { useEffect } from "react"
import { TeamLegueShimmer } from "./TeamLegueShimmer"
import Errorpage from "../Error Page/Errorpage"
import Error404Classic from "../../error/404-classic"

export const TeamLegueView = ({ setStatus, gameId, toggleMenu, setToggle, toggle }) => {

    const queryKey = [gameId, GETTOURNAMENT]

    const queryFunction = async () => {
        let data = await endpoints.Tournament.getAll(process.env.REACAT_APP_TOKEN, null, null, tournamentListGet);
        let result = Object.values(data.data)
        setToggle({ index: 0, ...result[0] })
        return result
    }

    useEffect(() => {
        setTimeout(() => {
            setStatus({
                isloading: false,
                done: true
            })
        }, 3000);
    }, [])

    const { data, isLoading, error } = useQuery({
        queryFn: queryFunction,
        queryKey: queryKey
    })

    if (isLoading) {
        return (<TeamLegueShimmer />)
    }

    if (error) {
        return (
            <Error404Classic />
        )
    }


    if (data) {
        return (
            <>
            <div className="pb-2">
                <h3>Tournament</h3>
            </div>
                <Card className="card-bordered  border-0 ">
                    <CardBody className="card-inner p-2">
                        <CardTitle className="fs-5  fw-bolder"></CardTitle>
                        <CardText>
                            <div className="overflow-y-scroll overflow-x-visible row d-flex " >
                                {
                                    data?.map((element, index) => {
                                        return (

                                            <TeamLegueCard item={element} activeIndex={toggle} currentIndex={index} setToggle={toggleMenu} />

                                        )
                                    })
                                }
                            </div>
                        </CardText>
                    </CardBody>
                </Card>
            </>
        )
    }
}