import football from "../../../assets/football.png"
import { useNavigate } from "react-router";
import { GetLegueDate } from "../../../helper/helper";

export const TeamLegueCard = ({ item, activeIndex = undefined, setToggle = undefined, currentIndex }) => {
    const { tournament_logo, tournament_name } = item

    const navigate = useNavigate()

    const toggleStyle = currentIndex === activeIndex?.index ? '    fw-bolder ' : ' '
    const togglebgStyle = currentIndex === activeIndex?.index ? '   ' : ' '
    GetLegueDate(item.start_date)
    return (
        <>
            <div className={"col-md-3  d-none d-sm-block my-2 text-center justify-content-center  " + togglebgStyle}>
               
                    <div className="border d-flex">


                        <div className="col-4 ">
                            <img src={tournament_logo} style={{
                                height: '70px',
                                minHeight: '20px',
                                width: '70px'
                            }}></img>
                        </div>
                        <div className={"col-8 fw-normal pointer text-center d-flex flex-column justify-content-center  " + toggleStyle} style={{ fontSize: '13px' }} onClick={() => setToggle(currentIndex, item)}>
                            <div>
                                <p className="">

                                {tournament_name}
                                </p>
                            </div>
                            <div>
                                <p>
                                {GetLegueDate(item.start_date) + "-" + GetLegueDate(item.end_date)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
  

            <div className={"container  d-block d-sm-none border mb-1 " + togglebgStyle}>
                <div className="row d-flex justify-content-center ">
                    <div className="col-4  text-center ">
                        <img src={tournament_logo}></img>
                    </div>
                    <div className={"col-8 fw-normal pointer  d-flex flex-column justify-content-center text-center" + toggleStyle} style={{ fontSize: '13px' }} onClick={() => { navigate("TeamLeague") }}>
                        <div>
                            {tournament_name}
                        </div>
                        <div>
                            {GetLegueDate(item.start_date) + "-" + GetLegueDate(item.end_date)}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}