import { AiOutlineCheckCircle } from "react-icons/ai"
import { bytesToMB, getValueInPercentOfTotal } from "../../helper/helper"
import { Alert, Progress, Spinner } from "reactstrap"
import { BiError } from "react-icons/bi"
import { Icon } from "../Component"
import { bytesToMegaBytes } from "../../utils/Utils"
import folderimage from "../../assets/file-1453 (1).png"
import { ToastContainer, toast } from "react-toastify"
import { INITIAL, InitState } from "../../pages/component/VersionControl/VersionControlChangeEndpoint"

export default function ShowProgress({ dispatch, setStatus, percentage, done = false, success, error, Total, successMessage, estimatedUploadTimeInSeconds }) {
    if (done) {
        setTimeout(() => {
            dispatch({ type: INITIAL })
            setStatus(InitState)
        }, 3000);
    }

    return (
        // <div className="m-2 row row-cols-1 justify-content-center">
        //     <div className="row justify-content-center">
        //         <img src={folderimage} style={{ minHeight: '200px', height: '100px', width: '200px' }} />
        //     </div>
        //     <Spinner type="grow" color="dark" />
        // <div>
        //     <div className="fw-bolder row justify-content-center pb-1">
        //         Don't Close Until Process End !!!
        //     </div>
        //     <div className="row justify-content-center pb-1">
        //         uploading.... {percentage}%
        //     </div>
        //     <div className="row justify-content-center mb-1">
        //         Remaining Time...{estimatedUploadTimeInSeconds}sec
        //     </div>
        //     <div className="border border-1">
        //         <Progress animated className="progress-lg" value={percentage}> {percentage}% </Progress>
        //     </div>
        // </div>
        // <ToastContainer />
        <div className="loader-spinner-custom row justify-content-center align-center">
            <div className="col-md-6 text-center">
                <div className=" row justify-content-center pb-1">
                    <h2 >Don't Close Until Process End !!!</h2>
                </div>
                <div className="row justify-content-center pb-1 ">
                    <p  className="fs-3">uploading.... {percentage}%</p>
                </div>
                <div className="row justify-content-center mb-1">
                    <p className="fs-5">Remaining Time...{estimatedUploadTimeInSeconds}sec</p>
                </div>
                <div className="border border-1">
                    <Progress animated className="progress-lg" value={percentage}> {percentage}% </Progress>
                </div>
            </div>
        </div>

        // </div>
    )
}