import React from 'react'
import   Error  from "../../../images/game/Errorimage.png"
import { IoGameController } from "react-icons/io5";


export default function Errorpage() {
    return (
        <>
            <div className='container'>
                <div className='row justify-content-center py-4 ' >

                    <div className='col-md-4 col-3 p-5 my-5' id='Errorimage'>
                        <h5 className='display-4'>
                        404 
                        </h5>
                        <h5 className='fs-1'>
                        ERROR
                        </h5>
                         <h5 className='fs-1'>
                        PAGE NOT FOUND 
                        </h5>
                        <h5 className='display-5'>

                        <IoGameController />
                        </h5>
                    </div>
                    <div className='col-md-6 col-9 '>
                    <img src={Error} alt="error"  height={"100%"}/>
                    </div>
                </div>
            </div>
        </>
    )
}
