import React from "react";
import { Link } from "react-router-dom";
import { PreviewCard } from "../../components/Component";

import imgFirst from "../../images/game/basketball.jpg";
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";

export default function Scorecards() {
  return (
    <>
    <div className="p-2">
      <h3 >
        Scoreboards
      </h3>
    </div>
      {/* <div className="col-md-3">
        <div class="card" >
          <img src={imgFirst} class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title">Basketball Scorecard </h5>
            <Link to="/basketballscore">
              <button type="button" class="btn btn-primary m-2">

              </button>
            </Link>
          </div>
        </div> */}
      
            <Row className="p-2">
        <Col lg="4">
                <Card className="card-bordered h-100">
                  <div className=" ">
                    <CardImg top src={imgFirst} alt=""/>
                  </div>
                  <CardBody className="card-inner d-flex flex-column justify-content-between">
                    <>
                      <CardTitle tag="h5">Basketball Scoreboards</CardTitle>  
                    </>
                    <div className="card-footer bg-transparent p-0">
                      <Link to= "/basketballscore">
                      <Button color="primary p-2 ">Controls</Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              </Row>

        {/* <Link to="/rugbyscorecard">
          <button tton type="button" class="btn btn-primary m-2">
            Rugby
          </button>
        </Link>
        <Link to="/soccercard">
          <button tton type="button" class="btn btn-primary m-2">
            Football
          </button>
        </Link>    */}
        {/* <Link to="/basketballcard">
          <button tton type="button" class="btn btn-primary m-2">
            Football
          </button>
        </Link> */}
   
    </>
  );
}
