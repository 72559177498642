import React from "react";
import { useReducer, useRef, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { deepCopyObject } from "../../helper/helper";
import AddProperty from "../../components/genericComponents/Addproperty"
import { useNavigate } from "react-router-dom";
import { SchemaTypes } from "../../helper/extraproperties";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../Component";
import { toast } from "react-toastify";

const ADD_PROPS_TYPE = "Add_Property";
const REMOVE_PROPS_TYPE = "Remove_Property";
const REMOVE_ITEM_FILEDATA = "Remove_Item_FileDatas";
const ADD_FILE = "Add_File"

const reducer = (state, action) => {
    var currentState = {}
    currentState.currentData = deepCopyObject(state.currentData);
    currentState.files = [...state.files]

    switch (action.type) {
        case ADD_PROPS_TYPE:
            currentState.currentData[action.payload.name] = action.payload.value;
            break;
        case ADD_FILE:
            let indexToRemove = action.payload.index
            currentState.files.splice(indexToRemove, 1);
            currentState.files.push(action.payload.value)
            currentState.currentData[action.payload.name] = currentState.files.length - 1
            break;
        case REMOVE_PROPS_TYPE:
            delete currentState.currentData[action.payload];
            break;
        default:
    }

    return currentState;
};


const FileReducer = (file, action) => {
    var newFiles = [...file];

    switch (action.type) {
        case SchemaTypes.IMAGE:
        case SchemaTypes.file:
            newFiles.push(action.payload.e);
            break;
        case REMOVE_ITEM_FILEDATA:
            let indexToRemove = newFiles.findIndex(element => element.name === action.element);
            if (indexToRemove !== -1) {
                newFiles.splice(indexToRemove, 1);
            }
            break;
        default:
    }
    return newFiles;
}

export default function GenericChangeEndpointModal({
    formName,
    addButtonText,
    currentData,
    querryFunction,
    propertyList,
    queryKeyValue,
    navigateTo,
    Onsuccess,
    closeModal,
    ModalInitialState,
    edit = false,
}) {
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(reducer, currentData);
    const [file, setfile] = useReducer(FileReducer, []);
    const [isverified, setIsVerified] = useState(false);
    const [verify, setVerify] = useState(false);
    const navigate = useNavigate();
    const formRef = useRef();

    const { data, isLoading, error, mutate } = useMutation(
        () => querryFunction(state, file),
        {
            onSuccess: (data) => {
                if (data.type === "success" || data.data.type === "success") {
                    toast.success("Added Successfully")
                    queryClient.invalidateQueries({
                        predicate: (query) => query.queryKey.includes(queryKeyValue),
                    });
                } else {
                    let error = data.data.message ? data.data.message : data.message;
                    toast.error(error)
                }
            },
        }
    );

    const handleDelete = (element, data) => {
        formRef.current.reset();
        if (
            data.item.type === SchemaTypes.IMAGE
            || data.item.type === SchemaTypes.file) {
            setfile({ type: REMOVE_ITEM_FILEDATA, element });
        } else {
            dispatch({ type: REMOVE_PROPS_TYPE, payload: data });
        }
    }

    const Onchange = (e, data, index) => {
        if (
            data.item.type === SchemaTypes.Image
            || data.item.type === SchemaTypes.file) {
            dispatch({ type: ADD_FILE, payload: { ...e, index: index } });
        } else {
            dispatch({ type: ADD_PROPS_TYPE, payload: e });
        }
    }

    const GetCurrentValue = (data) => {
        if (
            data.item.type === SchemaTypes.IMAGE
            || data.item.type === SchemaTypes.file) {
            return file.find(item => item.name === data.item.name);
        } else {
            return state.currentData[data.item.name]
        }
    }

    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            mutate();
            ModalInitialState()
        } else {
            form.classList.add("was-validated");
        }
    };

    const handleRedirect = () => {
        navigate(navigateTo);
    };

    var groups = [];
    var singleProps = [];

    propertyList.forEach((data) => {
        if (data.item.groupName) {
            groups.push(data);
        } else {
            singleProps.push(data);
        }
    });

    if (isLoading) return <div className="spinner-border m-5" role="status" />;

    if (error && error !== "") {
        return (
            <div className="vstack gap-2 p-2">
                <div className="alert alert-danger" role="alert">
                    <div className="text-center fw-bold">Failed!</div>
                    <div>{error}</div>
                </div>
                <div className="ms-auto hstack gap-2">

                    <button className="btn btn-primary" onClick={() => handleRedirect()}>
                        ok
                    </button>
                </div>
            </div>
        );
    }

    if (data) {
        if (Onsuccess) {
            return Onsuccess(data)
        }
        return (
            <div className="vstack gap-2 p-2">
                <div className="alert alert-success" role="alert">
                    {addButtonText + " succeded with ID: " + data.id}
                </div>
                <button className="ms-auto btn btn-primary" autoFocus={true} onClick={() => handleRedirect()}>
                    ok
                </button>
            </div>
        );
    }


    return (
        <Modal isOpen={true} toggle={() => { closeModal() }}>
            <ModalHeader
                toggle={closeModal}
                close={
                    <button className="close"
                        onClick={closeModal}
                    >
                        <Icon name="cross" />
                    </button>
                }
            >
                {formName}
            </ModalHeader>
            <ModalBody>
                <form
                    onSubmit={handleSubmit}
                    className=" needs-validation container-fluid  rounded"
                    ref={formRef}
                    noValidate
                >
                    {singleProps.map((data, index) => {
                        return (
                            <AddProperty
                                edit={edit}
                                deleteField={(element) => {
                                    handleDelete(element, data);
                                }}
                                key={index}
                                data={data}
                                currentValue={GetCurrentValue(data)}
                                onChange={(e) => {
                                    Onchange(e, data, index)
                                }}
                            />
                        );
                    })}
                    <hr />
                    <button type="submit" className="ms-auto btn btn-primary float-start align-self-end ">
                        {addButtonText}
                    </button>
                </form>
            </ModalBody>
        </Modal>
    );
}