const menu = [
  {
    icon: "play-circle",
    text: "Sports",
    link: "/",
    dashboard: true,
  },
  {
    icon: "article",
    text: "Teams",
    link: "/Teams",
    dashboard: true,
  },
  {
    icon: "template",
    text: "Matches",
    link: "/Matches",
    dashboard: true,
  },
  {
    icon: "brick",
    text: "Scoreboards",
    link: "/scoreboards",
    dashboard: true,
  },
  {
    icon: "user-check",
    text: "Tournament",
    link: "/teamview",
    dashboard: true,
  },
  {
    icon: "menu-circled",
    text: "Version Control",
    link: "/versioncontrol",
    dashboard: true,
  },
];
export default menu;
