import React from 'react'
import { megabytesToBytes } from '../../helper/helper';
import { useState } from 'react';
import { BiX } from 'react-icons/bi';
import { IMAGEJPEGCONTENTTTYPE, IMAGEPNGCONTENTTYPE } from '../../helper/extraproperties';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

export default function ImageValidater({
    RestrictedSize,
    filetype,
    setSelectedFile,
    controlProps,
    props,
    selectedFile
}) {

    const [Error, setError] = useState("");
    const AllImageTypes = [IMAGEJPEGCONTENTTTYPE, IMAGEPNGCONTENTTYPE];

    var showImage = selectedFile || props?.currentValue?.value?.Store?.Img;


    const ValidateImage = (e) => {
        setSelectedFile(null);
        setError("");

        if (e.target.value.length === 0) {
            props.deleteField(props.data.item.name);
            return;
        }
        const file = e.target.files[0];
        if (!(AllImageTypes.includes(file.type))) {
            setError("Please Enter the Valid Image of Png or Jpeg")
            return;
        }

        if (file) {
            // var ImageSize = megabytesToBytes(RestrictedSize);
            // if (file.size > ImageSize) {
            //     setError("Image is too large. Please upload an image smaller than 1MB.");
            //     return;
            // }
            setSelectedFile(URL.createObjectURL(file));
            controlProps.onChange(e);
        }
    }

    return (
        <div
            className={"hstack flex-grow-1 pb-1".concat(
                " " + props.data.className
            )}>
            <div className="form-floating flex-fill pt-2">

                <input
                   style={{backgroundPosition:'right',backgroundImage:'none'}}
                    type={filetype}
                    accept={"image/png, image/jpeg"}
                    onChange={ValidateImage}
                    className={"form-control"
                        .concat(" " + props.data.inputFieldClass)}
                    id={"floatingInput" + props.data.item.name}
                    required={props.edit ? false :true}
                />
                <div className="invalid-feedback text-start">
                {props.data.item.displayName.charAt(0).toUpperCase() + props.data.item.displayName.slice(1)} is required
                </div>
                {props.data.item.displayName && (
                    <label htmlFor={"floatingInput" + props.data.item.name}>
                        {props.data.item.displayName}
                    </label>
                )}

                {Error !== "" ? <>
                    {Error}
                </> : <></>}

            </div>
            {!controlProps.required ? (
                <div className='d-flex'>
                    <button
                        type="button"
                        className="btn btn-lg btn-secondary mb-4"
                        onClick={() => {
                            setSelectedFile(null);
                            setError("")
                            props.deleteField(props.data.item.name);
                        }}
                    >
                        <BiX />
                    </button>
                </div>
            ) : (
                <></>
            )}

            {(showImage) && (
                <img src={showImage} alt="Uploaded"
                    className={"d-block border border-1 m-2 border-info-subtle p-1 rounded " + props.data.item.ImageClass}
                    style={{ minWidth: '80px',maxHeight:'50px',objectFit:'contain', maxWidth: '200px' }}
                />
            )}

        </div>
    )
}