import React, { useState } from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Icon } from '../../components/Component';

const PackageModal = (props) => {

  return (
    <Modal className='modal-xl' isOpen={props.isOpen} toggle={props.toggleIsOpenModal}>
      <ModalHeader
        toggle={props.toggleIsOpenModal}
        close={
          <button className="close" onClick={props.toggleIsOpenModal}>
            <Icon name="cross" />
          </button>
        }
      >
        Upgrade Plan
      </ModalHeader>
      <ModalBody>
        <div className='text-center'>
          <span>Annual Billing</span>
          <span>Annual Billing</span>
          <span>Monthly Billing</span>
        </div>
        <div className='row'>
          <div className="col-sm-6 col-xl-4">
            <Card className="card-bordered text-center h-100">
              <CardBody className="card-inner text-center">
                <CardTitle tag="h5">PRO</CardTitle>
                <CardText>
                  <p className='mb-0'>
                    <span className='fs-2 fw-bold'>$29</span>
                    <span className='opacity-75'>/month</span>
                  </p>
                  <p className='opacity-75'>Billed as one payment of $348</p>
                  <Button color="primary">Upgrade to PRO</Button>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Storage</p>
                    <h6 className="fw-normal fs-6">1000 minutes stored</h6>
                    <p className="fs-10 opacity-50">
                      ($0.04 extra min stored per month)</p>
                  </div>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Streaming</p>
                    <h6 className="fw-normal fs-6">10k minutes viewed per month</h6>
                    <p className="fs-10 opacity-50">                      
                    ($0.005 extra min viewed)</p>
                  </div>
                  <h6 className='pt-4'>Top features include</h6>
                  <ul className='text-start plan-list'>
                    <li className='mb-2'>Up to 5 channels</li>
                    <li className='mb-2'>Up to 200 video links</li>
                    <li className='mb-2'>Player customization</li>
                    <li className='mb-2'>Program scheduler</li>
                    <li className='mb-2'>Branded website</li>
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col-sm-6 col-xl-4">
            <Card className="card-bordered text-center h-100">
              <CardBody className="card-inner">
              <CardTitle tag="h5">BUSINESS</CardTitle>
                <CardText>
                  <p className='mb-0'>
                    <span className='fs-2 fw-bold'>$79</span>
                    <span className='opacity-75'>/month</span>
                  </p>
                  <p className='opacity-75'>Billed as one payment of $948</p>
                  <Button color="primary">Upgrade to BUSINESS</Button>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Storage</p>
                    <h6 className="fw-normal fs-6">3000 minutes stored</h6>
                    <p className="fs-10 opacity-50">
                      ($0.03 extra min stored per month)</p>
                  </div>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Streaming</p>
                    <h6 className="fw-normal fs-6">40k minutes viewed per month</h6>
                    <p className="fs-10 opacity-50">                      
                    ($0.004 extra min viewed)</p>
                  </div>
                  <h6 className='pt-4'>All PRO features, plus</h6>
                  <ul className='text-start plan-list'>
                    <li className='mb-2'>Unlimited channels</li>
                    <li className='mb-2'>Unlimited video links</li>
                    <li className='mb-2'>Live streaming</li>
                    <li className='mb-2'>Player embed restrictions</li>
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col-sm-6 col-xl-4">
            <Card className="card-bordered h-100">
              <CardBody className="card-inner text-center">
              <CardTitle tag="h5">PREMIUM</CardTitle>
                <CardText>
                  <p className='mb-0'>
                    <span className='fs-2 fw-bold'>$199</span>
                    <span className='opacity-75'>/month</span>
                  </p>
                  <p className='opacity-75'>Billed as one payment of $2,388</p>
                  <Button color="primary">Upgrade to PREMIUM</Button>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Storage</p>
                    <h6 className="fw-normal fs-6">10000 minutes stored</h6>
                    <p className="fs-10 opacity-50">
                      ($0.02 extra min stored per month)</p>
                  </div>
                  <div className='mt-3'>
                    <p className="mb-0 fw-semibold fs-5">Streaming</p>
                    <h6 className="fw-normal fs-6">120k minutes viewed per month</h6>
                    <p className="fs-10 opacity-50">                      
                    ($0.003 extra min viewed)</p>
                  </div>
                  <h6 className='pt-4'>All BUSINESS features, plus</h6>
                  <ul className='text-start plan-list'>
                    <li className='mb-2'>M3U8 links of linear channels</li>
                    <li className='mb-2'>Ads monetization</li>
                    <li className='mb-2'>Priority support</li>
                  </ul>
                </CardText>
              </CardBody>
            </Card>
          </div>          
        </div>
      </ModalBody>
      <ModalFooter className="bg-light justify-center">
        <span className="sub-text text-center">Compare plans and features  </span>
      </ModalFooter>
    </Modal>
  )
}

export default PackageModal