import { Card } from "reactstrap"

export const MatchScore = () => {
    return (
        <div className="col-md-10 col-xl-10 my-auto px-2 mx-auto ">
            <Card className="shadow-sm rounded-2 p-2 h-100">
                <p className="bg-body-secondary p-1 d-flex align-items-center justify-content-between fw-bold mb-0">
                    <span>Wednesday 10 April</span>
                    <span className="bg-azure text-white rounded-pill fs-10px px-2 py-1">Rugby</span>
                </p>
                <div className="text-center">
                    <small className="fs-13px fw-bold">
                        Pool World Rugby Pacific Challenge
                        <br /> 2024, Match 1
                    </small>
                    <div className="d-flex align-center justify-content-around">
                        <div>
                            <div className="team-logo-img">
                                <img src="fiji-warriorarriors.jpg" alt="" />
                            </div>
                            <p className="fw-bold mt-2">Fiji Warriors</p>
                        </div>
                        <div className="bg-blue mx-1 rounded-2 text-white">
                            <span className="fw-bold px-1 fs-6">17</span>
                            <span>|</span>
                            <span className="fw-bold px-1 fs-6">29</span>
                        </div>
                        <div>
                            <div className="team-logo-img">
                                <img src="manuma-samoa.jpg" alt="" />
                            </div>
                            <p className="fw-bold mt-2">Manuma Samoa</p>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}