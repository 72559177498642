import React, { useEffect, useState } from 'react'
// import Scorecards from './Scorecards'
import { Display } from "react-7-segment-display";
import { FaForward } from "react-icons/fa6";

import { FaBackward } from "react-icons/fa";



export default function Newboard() {




    const initialTotalSeconds = 15 * 60; // 15 minutes in seconds
    const [totalSeconds, setTotalSeconds] = useState(initialTotalSeconds);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (totalSeconds > 0) {
                setTotalSeconds(prevSeconds => prevSeconds - 1);
            } else {
                clearInterval(intervalId);
                // Timer completed, you can trigger a callback or action here
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [totalSeconds]);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Format minutes and seconds to display with leading zeros if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return (
        <>

            <div className='container-fluid p-0 bg-background  d-none'>
                <div className="container-fluid text-white bg-background py-4">
                    <div className="row  justify-content-center py-4">
                        <div className="col-4 p-0 text-center">
                            <div className="">
                                <h5 className="text-center display-5 fw-normal">Boston</h5>
                                <div className='d-flex justify-content-center'>

                                    <Display value={2} count={2} height={60} backgroundColor='#5a5a47ck' className="p-0 text-center" color='yellow' />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p className='fs-3 p-1'><FaBackward /> </p>


                                    <h2 className="fs-3 pt-2 p-1">BONUS</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 text-center justify-content-center d-flex flex-column align-center">

                            <div className='border-red d-flex justify-content-center p-2 '>
                                <div className='d-flex justify-content-center'>
                                    <Display value={formattedMinutes} count={2} height={60} backgroundColor='black' className="text-center" />
                                </div>

                                <div className='d-flex flex-column justify-content-center '>
                                    <div className='d-block px-2'>

                                        <div className="blinking-dot my-1"></div>
                                    </div>
                                    <div className='d-block px-2'>
                                        <div className="blinking-dot my-1"></div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <Display value={formattedSeconds} count={2} height={60} backgroundColor='black' className=" text-center" />
                                </div>
                            </div>



                            <div className='d-flex pt-2 align-center'>
                                <div className='p-2'>
                                    <h5 className="">PERIOD</h5>
                                </div>


                                <div className=''>
                                    <Display value="3" count={1} height={60} backgroundColor='black' className="" color='yellow' />
                                </div>

                            </div>

                        </div>
                        <div className="col-4 text-center">


                            <h5 className="display-5 fw-normal">Phoenix</h5>
                            <div className='d-flex justify-content-center'>

                                <Display value="20" count={3} height={60} bakgroundColor='' className="" color='#ffe400' />
                            </div>

                            <div className='d-flex justify-content-center'>

                                <h5 className="fs-3 pt-2 p-1">BONUS</h5>
                                <p className='fs-3 p-1'><FaForward /> </p>

                                <p className=''>

                                </p>
                            </div>

                        </div>
                    </div>
                    <div className='py-3'>
                        <div className='row bordertop-red justify-content-center bg-background py-4 '>
                            <div className='col-4 d-flex justify-content-center '>
                                <div className="col-8 text-center">
                                    <h5 className="text-center fs-2">FOULS</h5>
                                    <div className='d-flex justify-content-center'>
                                        <Display value={3} count={2} height={60} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>

                                </div>
                                <div className='col-4 text-center pt-5'>

                                    <div className='d-flex justify-content-center pt-5'>

                                        <Display value="17" count={2} height={60} backgroundColor='black' className="p-0 text-center" />
                                    </div>
                                    <h5 className="text-center fs-2 pt-1">TOL</h5>

                                </div>

                            </div>
                            <div className='col-4 d-flex justify-content-center'>
                                <div className='text-center px-2'>
                                    <h5 className=" fs-2">PLAYER</h5>
                                    <div className='d-flex justify-content-center'>
                                        <Display value="23" count={2} height={60} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>
                                </div>

                                <div className='text-center px-2'>
                                    <h5 className="fs-2">FOULS</h5>
                                    <div className='d-flex justify-content-center'>
                                        <Display value="3" count={2} height={60} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>
                                </div>

                            </div>
                            <div className='col-4 d-flex justify-content-center'>

                                <div className='col-4 text-center pt-5'>

                                    <div className='d-flex justify-content-center pt-5' >
                                        <Display value="20" count={2} height={60} backgroundColor='black' className="Tolperiod" />
                                    </div>
                                    <h5 className="text-center fs-2  pt-1">TOL</h5>

                                </div>
                                <div className="col-8 text-center">
                                    <h5 className="text-center fs-2">FOULS</h5>
                                    <div className='d-flex justify-content-center'>
                                        <Display value="2" count={2} height={60} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







            <div className='container-fluid py-1 px-0 bg-background height-100  '>
                <div className="container-fluid text-white bg-background ">
                    <div className="row  justify-content-center py-3 ">
                        <div className='col-12  order-0 text-center justify-content-center d-flex flex-column align-center py-1'>
                            <div className='border-red d-flex justify-content-center p-2 '>
                                <div className='d-flex justify-content-center '>
                                    <div className='d-md-none'>

                                        <Display value={formattedMinutes} count={2} height={40} backgroundColor='black' className="text-center" />
                                    </div>
                                    <div className='d-none d-md-block'>

                                        <Display value={formattedMinutes} count={2} height={100} backgroundColor='black' className="text-center " />
                                    </div>
                                </div>

                                <div className='d-flex flex-column justify-content-center bg-black '>
                                    <div className='d-block '>

                                        <div className="blinking-dot my-1"></div>
                                    </div>
                                    <div className='d-block'>
                                        <div className="blinking-dot my-1"></div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className='d-md-none'>
                                        <Display value={formattedSeconds} count={2} height={40} backgroundColor='black' className=" text-center" />
                                    </div>
                                    <div className='d-none d-md-block'>
                                        <Display value={formattedSeconds} count={2} height={100} backgroundColor='black' className=" text-center" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4  pt-2 text-center">
                            <div className="">
                                <h2 className="text-center">Boston</h2>
                                <div className='d-flex justify-content-center'>
                                    <div className='d-md-none'>
                                        <Display value={2} count={2} height={40} backgroundColor='#5a5a47ck' className="p-0 text-center" color='yellow' />

                                    </div>
                                    <div className='d-none d-md-block'>

                                        <Display value={2} count={2} height={100} backgroundColor='#5a5a47ck' className="p-0 text-center" color='yellow' />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <h5 className='fs-5 pt-2'> <FaBackward /> BONUS </h5>


                                </div>
                            </div>
                        </div>

                        <div className="col-4 justify-content-center text-center m-auto pt-1 d-md-flex">
                            <div>

                                <h5 className="mx-md-5">PERIOD</h5>

                                <div className='d-md-none'>
                                    <Display value="3" count={1} height={40} backgroundColor='black' className="" color='yellow' />
                                </div>

                                <div className='d-none d-md-block'>
                                    <Display value="3" count={1} height={100} backgroundColor='black' className="" color='yellow' />
                                </div>
                            </div>
                            <div className='pt-1 pt-md-0'>

                                <h5>SHOT CLOCK</h5>
                                <div className='d-md-none'>
                                    <Display value="00" count={2} height={40} backgroundColor='black' className="" color='yellow' />

                                </div>
                                <div className='d-none d-md-block'>
                                    <Display value="00" count={2} height={100} backgroundColor='black' className="" color='yellow' />

                                </div>
                            </div>


                        </div>

                        <div className="col-4 text-center pt-2">
                            <h5 className="fs-2 fw-normal">Phoenix</h5>
                            <div className='d-flex justify-content-center'>

                                <div className='d-md-none'>
                                    <Display value="20" count={2} height={40} bakgroundColor='' className="" color='#ffe400' />
                                </div>
                                <div className='d-none d-md-block'>
                                    <Display value="20" count={2} height={100} bakgroundColor='' className="" color='#ffe400' />
                                </div>

                            </div>



                            <h5 className="fs-5 pt-2 ">BONUS <FaForward /></h5>



                        </div>
                    </div>




                    <div className='row bordertop-red  bg-background py-5 '>

                        <div className='col-12 d-flex justify-content-center'>
                            <div className='text-center px-2'>
                                <h5 className=" fs-2">PLAYER</h5>
                                <div className='d-flex justify-content-center'>
                                    <div className='d-md-none'>
                                        <Display value="23" count={2} height={40} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>
                                    <div className='d-none d-md-block'>
                                        <Display value="23" count={2} height={100} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>

                                </div>
                            </div>

                            <div className='text-center px-2'>
                                <h5 className="fs-2">FOULS</h5>
                                <div className='d-flex justify-content-center'>
                                    <div className='d-md-none'>
                                        <Display value="3" count={2} height={40} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>
                                    <div className='d-none d-md-block'>
                                        <Display value="3" count={2} height={100} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className='col-6 d-flex justify-content-center pt-3'>
                            <div className='p-1 text-center'>
                                <h5 className="text-center fs-4">FOULS</h5>
                                <div className='d-md-none '>
                                    <Display value={3} count={2} height={40} backgroundColor='black' className="" color='yellow' />
                                </div> <div className='d-none d-md-block'>
                                    <Display value={3} count={2} height={100} backgroundColor='black' className="" color='yellow' />
                                </div>

                            </div>


                            <div className=' text-center p-1'>
                                <h5 className="text-center fs-5 ">TOL</h5>
                                <div className='d-md-none '>
                                    <Display value="17" count={2} height={40} backgroundColor='black' className="" />
                                </div> <div className='d-none d-md-block'>
                                    <Display value="17" count={2} height={100} backgroundColor='black' className="" />
                                </div>



                            </div>
                        </div>


                        <div className='col-6 d-flex justify-content-center pt-3'>





                            <div className='p-1 text-center'>
                                <h5 className="text-center fs-5">FOULS</h5>
                                <div className='d-md-none'>
                                    <Display value="2" count={2} height={40} backgroundColor='black' className="" color='yellow' />

                                </div> <div className='d-none d-md-block'>
                                    <Display value="2" count={2} height={100} backgroundColor='black' className="" color='yellow' />
                                </div>

                            </div>

                            <div className='p-1 text-center' >
                                <h5 className="text-center fs-5 ">TOL</h5>
                                <div className='d-md-none'>
                                    <Display value="3" count={2} height={40} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                </div>
                                <div className='d-none d-md-block'>
                                    <Display value="3" count={2} height={100} backgroundColor='black' className="p-0 text-center" color='yellow' />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>




        </>
    )
}


