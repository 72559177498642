import "../css/LandingPage/landingPage.css"
import PlayingImage from "../assets/constructor-branch.png"
import { GenericInfoCard } from "../components/genericComponents/GenericGameCard"
import { TbBook } from "react-icons/tb";
import { BiDownload } from "react-icons/bi";
import { TiMessages } from "react-icons/ti";
import pcImage from "../assets/pc.png"
import { GenericTable } from "../components/genericComponents/GenericTable"
import { GenericTableBuilder } from "../components/genericComponents/GenericTableBuilder"
import { Table1Header, VERSIONCONTROLGETALL, VERSIONCONTROLLIST, VersionControlGroup, requirementPcVersion, requirementWebGlVersion } from "../helper/extraproperties";
import { GenericList } from "../components/genericComponents/GenericList";
import logo from "../assets/constructor-tool.png"
import { RiGlobalLine } from "react-icons/ri";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { endpoints } from "../Endpoints/endpoints";
import Error404Classic from "./error/404-classic";

const menu = [
    {
        title: "About",
        icon: AiOutlineSetting,
        data: "The advantages of using a constructor tool instead of other similar applications"
    },
    {
        title: "Documentation",
        icon: TbBook,
        data: "Silo composer is a tool that provides users to create low poly 3D models."
    },
    {
        title: "Downloads",
        icon: BiDownload,
        data: "GUI clients and binary releases for all major platforms."
    },
    {
        title: "Community",
        icon: TiMessages,
        data: "Get involved! Bug reporting, chat, development and more."
    }
]

const bigNumber = 9007199254740991;


const ButtonComponent = ({ value }) => {
    return (
        <a href={value.fileUrl} download='true' className="btn btn-sm  btn-danger">
            <span className="dynamicSize text-center fw-light">
                Download
            </span>
        </a>
    )
}

export const LandingPage = () => {

    const queryfunction = async () => {
        const data = await endpoints.versionControl.getAll(process.env.REACAT_APP_TOKEN, { limit: bigNumber }, null, VERSIONCONTROLGETALL);
        const result = {}
        const array = data.data;

        for (let i = 0; i < array.length; i++) {
            let element = array[i]
            if (result[element.group_key]) {
                // result[element.group_key].push(element);
            } else {
                result[element.group_key] = []
                // result[element.group_key].push(element);
            }
            result[element.group_key].unshift(element)
        }
        return result;
    }
    const { data, isLoading, error } = useQuery({ queryKey: [VERSIONCONTROLLIST], queryFn: queryfunction });

    if (isLoading) {
        return <></>
    }

    if (error) {
        return <><Error404Classic /></>
    }






    return (
        <>
            <div className="container-fluid LandingBackgroundImage py-1" >
                <div className="ps-md-4 ">
                    <Link className=" " href="#home">
                        <img src={logo} height={35} alt="" />
                    </Link>
                </div>
                <div className="justify-content-center py-3 row">
                    <div className="col-md-4 my-auto ">
                        <p className=" text-black" style={{ fontSize: '16px' }} >
                            An application where you can make your 3d designs. Design rooms and interiors from scratch, and easily export them. Constructor tool features a variety of textures and objects to choose from that gives an edge over other similar applications.
                        </p>
                        <p className=" text-black" style={{ fontSize: '16px' }}>
                            Silo OS software application is user-friendly and easy to navigate, allowing users to customize their preferences and personalize their experience. With a sleek and modern design, Silo OS not only looks great, but it also functions flawlessly, ensuring a smooth user experience every time.
                        </p>
                    </div>
                    <div className="col-md-5 col-lg-4">
                        <div>
                            <img src={PlayingImage} alt="" className="logoWidth" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid landingPagesection2bg">
                <div className="justify-content-center row">
                    <div className="col-md-7 col-lg-6 my-auto ">
                        <div className="row border-2 border-end row-cols-2">

                            {
                                menu.map((element, index) => {
                                    return (
                                        <GenericInfoCard
                                            key={index}
                                            data={element.data}
                                            icons={<element.icon size={35} color="#8e8880" />}
                                            title={element.title}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className="row"></div>
                    </div>

                    <div className="col-md-4 col-lg-5 my-sm-2">
                        <div className="position-relative text-center">
                            <img src={pcImage} height={"80%"} alt="" />
                            <a className="window-info text-white" href="#release">
                                <p className="m-0 fs-5">Latest Source Release</p>
                                <h4 className="m-0 fw-bold">2.40.0</h4>
                                <small className="d-block pb-1">
                                    <u className="fs-14px">
                                        Release Notes (2023-03-04)
                                    </u>
                                </small>
                                <button className="btn btn-danger fw-light fs-16px text-white px-2 py-1">Download for Windows</button>
                            </a>
                        </div>

                        <div className="row justify-content-center text-center">
                            <div className="col-lg-6 col-xl-5">
                                <button className="border-0 bg-transparent my-2 position-relative">
                                    <div className="d-flex flex-row ">
                                        <span className="fw-bold px-2 text-nowrap"> Windows GUIs</span>
                                        <a href="#windows guis">
                                            <div style={{ backgroundColor: 'darkgray', borderRadius: '4px' }} >
                                                <BiDownload
                                                    color="bg-body-tertiary"
                                                    style={{
                                                        backgroundColor:
                                                            'darkgray', color: '#fff',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        borderRadius: '5px'
                                                    }}
                                                    size={45}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </button>
                            </div>
                            <div className="col-lg-6 col-xl-5">
                                <button className="border-0 bg-transparent my-2 position-relative">
                                    <span className="fw-bold fs-16px pe-1">Android</span>
                                    <span class="badge text-bg-danger">Comming oon</span>
                                </button>
                            </div>
                            <div className="col-lg-6 col-xl-5">
                                <button className="border-0 bg-transparent my-2 position-relative">
                                    <div className="d-flex flex-row ">
                                        <span className="fw-bold fs-16px px-2">WebGL</span>
                                        <a href="#webgel">
                                            <div style={{ backgroundColor: 'darkgray', borderRadius: '4px' }} className="px-1">
                                                <RiGlobalLine
                                                    size={40}
                                                    color="text-white"
                                                    style={{
                                                        backgroundColor: 'darkgray',
                                                        color: '#fff',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px',
                                                        borderRadius: '5px'
                                                    }}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-9 border-2 border-bottom"></div>

                    <div className="row justify-content-center py-3">
                        <div className="col-md-7 col-sm-10 ">
                            {
                                Object.entries(data)?.map(([key, value]) => {
                                    return <GenericTable
                                        tableTitle={VersionControlGroup[`${key}`]}
                                        body={
                                            <>
                                                <GenericTableBuilder
                                                    headerBody={value}
                                                    headers={Table1Header}
                                                    Objectcomponent={ButtonComponent}
                                                />
                                            </>
                                        }
                                    />
                                })
                            }

                            <div className="row">
                                <div className="col-md-6">
                                    <GenericList
                                        listItem={requirementPcVersion}
                                        listTitle={"Requirements - PC version"}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <GenericList
                                        listItem={requirementWebGlVersion}
                                        listTitle={"Requirements - Web GL version"}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4">
                            <div className="tableBackgroundColor p-3">
                                <h6 className="text-black fs-19px fw-medium text-center">
                                    {"User Guide/FAQs"}
                                </h6>
                                <div className="fs-6 text-start text-black pt-1">
                                    {"You can download the User Guide here"}
                                </div>
                                <ul className="mx-2 pointer pt-2 px-2">
                                    <li className="text-black listStyle">Windows Version <BiDownload /></li>
                                    <li className="text-black listStyle">WebGL Version <BiDownload /></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>




        </>
    )
}