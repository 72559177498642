// import { useReducer, useState } from "react"
// import Awayteamcontrols from "./Controls/Awayteamcontrols"
// import BasketballScorecard from "./Controls/BasketballScorecard"
// import Hometeamcontrols from "./Controls/Hometeamcontrols"
// import Timer from "./Controls/Timer"
// import Resetall from "./Controls/Resetall"
// import Homeplayercontrols from "./Controls/Homeplayercontrols"
// import Awayplayercontrols from "./Controls/Awayplayercontrols"
// import Playerdetails from "./Controls/Playerdetails"
// import { json } from "react-router"

// const deepcopyObject = (fromObject) => {
//     return JSON.parse(JSON.stringify(fromObject))
// }

// export const ADDTOCURRENT = "current";
// export const ADDTOAWAY = "away";
// export const ADD = "Add";
// export const SUBSTRACT = "substract"

// export const BasketBallView = () => {
//     const init = {
//         current: 0,
//         away: 0
//     }


//     const reducer = (state, action) => {
//         var currentState = deepcopyObject(state)
//         switch (action.type) {
//             case ADDTOCURRENT:
//                 if (action.payload.operation === ADD) {
//                     currentState.current += action.payload.value;
//                 } else if (action.payload.operation === SUBSTRACT) {
//                     currentState.current -= action.payload.value;
//                 }
//                 break;

//             case ADDTOAWAY:
//                 if (action.payload.operation === ADD) {
//                     currentState.away += action.payload.value;
//                 } else if (action.payload.operation === SUBSTRACT) {
//                     currentState.away -= action.payload.value;
//                 }
//                 break;
//         }
//         return currentState;
//     }

//     const [state, dispatch] = useReducer(reducer, init)

//     return (
//         <>
//             <div className="d-flex flex-column">
//                 <BasketballScorecard score={state} />
//                 <div className='container-fluid'>
//                     <div className='row p-1'>

//                         <h4 className='bg-background text-white p-2 my-2'>
//                             Controls
//                         </h4>
//                         <Hometeamcontrols changeScore={dispatch} score={state} />
//                         <Awayteamcontrols />
//                         <Timer />
//                         <Resetall />
//                     </div>
//                 </div>

//             </div>
//             <div className='row p-1 justify-content-center'>

//                 <h4 className='bg-background text-white p-2 my-2'>
//                     Player Controls
//                 </h4>
//                 <div className='col-md-6 text-cenetr justify-content-center'>

//                     <Homeplayercontrols />
//                     <div className='    '>
//                         < Playerdetails />
//                     </div>
//                 </div>
//                 <div className='col-md-6'>
//                     <Awayplayercontrols />
//                     < Playerdetails />

//                 </div>
//             </div>

//         </>
//     )
// }

import React, { useEffect, useState, useCallback } from 'react';


import Hometeamcontrols from './Controls/Hometeamcontrols'
import Awayteamcontrols from './Controls/Awayteamcontrols'
import Playerdetails from './Controls/Playerdetails'
import "../../css/sports_css/sports.css"
import Playerstat from './Controls/Playerstat'
import Streamimage from "../../images/game/streamimage.jpg"
import Streamscoreboard from './Controls/Streamscoreboard'
import { Link } from 'react-router-dom';
import { BasketBallTab } from './BasketBall/BasketBallTab';
import { BasketBallTabs } from '../../helper/extraproperties';
import Media from './ScoreboradTabs/Media';
import Audio from './ScoreboradTabs/Audio';
import Webcam from "react-webcam";
import { IoMdArrowRoundBack } from "react-icons/io";


export default function BasketBallView() {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [cameras, setCameras] = useState([])
  const [activeTab, setActiveTab] = useState('home-tab-pane');
  const [selectedButtonIndexSecond, setSelectedButtonIndexSecond] = useState(null);
  const [currentTeamMember, setCurrentTeamMember] = useState({
    teamplayer: 0,
    points: 0,
  })

  const handleButtonClick = (index) => {
    setSelectedButtonIndex(index);
  };
  const handleButtonClickSecond = (index) => {
    setSelectedButtonIndexSecond(index);
  };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);
  const [activeWebcam, setActiveWebcam] = useState(null);


  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const videoDevices = mediaDevices.filter(({ kind }) => kind === "videoinput");
      console.log("videoDevices", videoDevices)
      setDevices(videoDevices);
      setSelectedDeviceId(videoDevices[0]?.deviceId);
    });
  });

  const handleDeviceChange = (deviceId) => {
    setSelectedDeviceId(deviceId);
    setActiveWebcam(deviceId);
  };

  return (
    <>
      <div className='d-flex justify-content-start position-fixed1  '>
        <Link to="/scoreboards">
          <button type="button" className='btn fs-2 '><IoMdArrowRoundBack /></button>
        </Link>
      </div>
      <ul className="nav nav-tabs  justify-content-center" id="myTab" role="tablist">

        {
          BasketBallTabs.map((element, index) => {
            return (
              <BasketBallTab
                activeTabClass={(activeTab === element.activeTab) ? 'nav-link active' : 'nav-link'}
                ariaControls={element.activeTab}
                title={element.title}
                ariaSelected={activeTab == element.activeTab}
                key={index}
                handleTabClick={handleTabClick}
              />
            )
          })
        }
      </ul>
      <>
        {
          activeTab === 'home-tab-pane'
            ?
            <div className={"tab-content " + (activeTab === 'home-tab-pane') ? 'd-block ' : 'd-none'} id="myTabContent">
              <div
                className={`tab-pane fade ${activeTab === 'home-tab-pane' ? 'show active' : ''}`}
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab">
                <div className='container-fluid '>
                  <div className='row '>
                    <div className='col-md-3 my-4  text-center h-50 '>
                      <div>
                        <Hometeamcontrols selectedButtonIndex={selectedButtonIndex} handleButtonClick={handleButtonClick} />
                      </div>
                      <div className='py-2 mt-1 d-flex justify-content-evenly  m-auto  '>
                        <p className='fs-4 fw-bold text-center m-auto'>VS</p>
                        <button type="button " className="btn btn-primary px-5 py-2 buttonblack">PUSH</button>
                      </div>
                      <div className='py-2'>
                        <Awayteamcontrols selectedButtonIndexSecond={selectedButtonIndexSecond} handleButtonClick={handleButtonClickSecond} />
                      </div>
                      <div className='py-3 text-start'>
                        <Link to="/basketballscoreboard/:boston-vs-phonix">
                          <button type="button " className="btn btn-primary px-4 py-1buttonblack">Live Scoreboard</button>
                        </Link>
                      </div>
                    </div>
                    <div className='col-md-3 my-4  '>
                      <div>
                        <Playerdetails />
                      </div>
                      <p className='pt-2 fs-4'>Statistics</p> 
                      <div>
                        <Playerstat />
                      </div>
                    </div>
                    <div className='col-md-6 my-4  text-center '>
                      <img src={Streamimage} alt="" className=' img-fluid w-100 object-fit-contain ' />
                      <div className=''>
                        <Streamscoreboard />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : activeTab === "contact-tab-pane"
              ?
              <div className={`tab-pane fade ${activeTab === 'contact-tab-pane' ? 'show active d-block ' : 'd-none'}`}
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab">
                <div>

                  <Audio />
                </div>
              </div>
              : activeTab === "profile-tab-pane" ?
                <div
                  className={`tab-pane fade ${activeTab === 'profile-tab-pane' ? 'show active d-block ' : 'd-none'}`}
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div>
                    <Media />
                  </div>
                </div>
                :
                <div className={`tab-pane fade ${activeTab === 'camera-tab-pane' ? 'show active d-block ' : 'd-none'}`}
                  id="camera-tab-pane"
                  role="tabpanel"
                  aria-labelledby="camera-tab"
                >
                  <div className='container-fluid pt-2'>
                    <div className="row ">
                      <div>
                        <div className=' d-flex flex-wrap '>
                          {devices.map((device, index) => (
                            <div className=' '>
                              <button
                              key={index}
                              className=" btn py-2 bg-secondary-dim"
                              onClick={() => handleDeviceChange(device.deviceId)}
                            >
                              Cam{index + 1}
                            </button>
                              <div className='m-1'>
                                <Webcam
                                  audio={false}
                                  videoConstraints={{ deviceId: device.deviceId }}
                                  width={400}
                                  height={400}
                                  screenshotFormat="image/jpeg"
                                  className=''
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>}
      </>
    </>
  )

}


