
import { Icon, PreviewCard } from "../Component";
import { bytesToMB, cutWord, download, getDataForPage } from "../../helper/helper";
import { endpoints } from "../../Endpoints/endpoints";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Errorpage from "../../pages/component/Error Page/Errorpage";
import { DELETEMESSAGEVERSIONCONTROL, VERSIONCONTROLDELETE, VERSIONCONTROLGETALL, VERSIONCONTROLLIST, versioncontrolpost } from "../../helper/extraproperties";
import { GenericModal } from "./GenericModal";
import { VersionControlShimmer } from "../../pages/component/VersionControl/VersionControlShimmer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Error404Classic from "../../pages/error/404-classic";
import { Button, Tooltip } from "reactstrap";
import { GenericFooterPagination } from "./GenericFooterPagination";
import { ToastContainer, toast } from "react-toastify";
import { GenericTableContentToolTip } from "./GenericTableContentToolTip";

export const GenericOrderTable = () => {
    const [modalForm, setModalForm] = useState(false);
    const [currentElement, setCurrentElement] = useState(undefined)
    const queryClient = useQueryClient()
    const [searchString, setSearchString] = useState("")
    const bigNumber = 9007199254740991;
    const [currentPage, setCurrentPage] = useState(1);
    const [options, setOptions] = useState("normal")


    const toastSuccess = (text) => toast.success(text)

    const toastError = (text) => toast.error(text)


    const navigate = useNavigate()

    const toggleForm = (item) => {
        setModalForm(!modalForm);
        setCurrentElement(item)
    }

    const deleteDocument = async (id) => {
        let options = {
            version_control_id: id
        }
        let data = await endpoints.versionControl.delete(null, options, VERSIONCONTROLDELETE)
        // setDeleteItem(data.message === DELETEMESSAGEVERSIONCONTROL)
        return data;
    }

    const queryfunction = async ({ pageParam = 1 }) => {
        const data = await endpoints.versionControl.getAll(process.env.REACAT_APP_TOKEN, { limit: bigNumber }, null, VERSIONCONTROLGETALL);
        const array = data.data.reverse();
        if (array.length === 0) {
            return []
        }
        var resultObject = {}
        let page = 1
        let ok = true;
        while (ok) {
            if (!resultObject[page]) {
                resultObject[page] = []
                var result = getDataForPage(array, page)
                resultObject[page] = result.data;
            }
            if (result.lastIndex === array.length) {
                ok = false;
            }
            page++;
        }
        return resultObject;
    }


    var { data, isLoading: loader, error: err } = useQuery({ queryKey: ["GETALLLIST", VERSIONCONTROLLIST], queryFn: queryfunction });

    const { data: mutatedData, isLoading, error, mutate } = useMutation({
        mutationFn: (id) => deleteDocument(id),
        onSuccess: (data) => {
            if (data.message === "Version deleted successfully") {
                toastSuccess("Version has been deleted successfully.")
            } else if (data.message === "Version id not found") {
                toastError("User Does Not Have Access!!")
            } else {
                toastError("Unknown Error!!")
            }
            queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey.includes(VERSIONCONTROLLIST)
            })
        }
    })

    if (isLoading || loader) {
        return <VersionControlShimmer />
    }

    if (error || err) {
        return <><Error404Classic /></>
    }


    if (modalForm) {

        return (
            <div>
                <GenericModal currentElement={currentElement} modalForm={modalForm} toggleForm={toggleForm} />
            </div>
        )
    }

    const deleteItem = async (item) => {
        try {
            // toast("sds")
            const result = await Swal.fire({
                title: "Are You Sure?", // Removed extra space after "Sure"
                text: "This process can't be reverted are you sure want to delete it?",
                icon: "question",
                showCancelButton: true, // Added showCancelButton for "Cancel" option
                confirmButtonColor: '#3085d6', // Optional: Customize confirm button color
                cancelButtonColor: '#d33', // Optional: Customize cancel button color
                confirmButtonText: 'Yes, delete!',
                cancelButtonText: 'No, cancel'
            })
            if (result.isConfirmed && item?.id) {
                mutate(item?.id);
            }
        } catch (err) {
            console.log(err)
        }
    }


    var filterData = data[currentPage]

    if (filterData && searchString !== "") {
        filterData = [].concat(...Object.values(data));
        filterData = filterData.filter((current) => {
            var valueToSearchIn = current.title.toLowerCase();
            var valueToSearch = searchString.toLowerCase();
            return valueToSearchIn.includes(valueToSearch);
        })
    }
    
    if (options !== "normal") {
        filterData = [].concat(...Object.values(data)).slice(0, options)
    }

    return (
        <div className="card py-2">
            <div className="d-flex flex-column  border border-1">
                <div className="p-2">
                    <p className="h4">Version Control</p>
                </div>
                <div className="d-flex flex-row justify-content-between px-2">
                    <label>
                        <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search by Version Title"
                            onChange={(e) => setSearchString(e.target.value)}
                        />
                    </label>
                    <div className="d-flex flex-row px-1">
                        <GenericTableContentToolTip
                            id={"six" + 1}
                            ToolTipText={"download page"}
                            content={

                                <Button color="primary fw-bold mx-1 px-2 py-1 pointer" onClick={() => navigate("/landingpage")}>
                                    <Icon name="menu" /></Button>
                            }
                        />
                        <select
                            name="DataTables_Table_0_length"
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setOptions(e.target.value)}
                            value={options}
                        >
                            <option value="normal">Show</option>
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>


                </div>
                <div className="card m-2 border border-1 ">
                    <table className="table table-striped ">
                        <thead>
                            <tr >
                                <th className="">
                                    Title
                                </th>
                                <th >
                                    Size
                                </th>
                                <th >
                                    Version
                                </th>
                                <th style={{ paddingLeft: '20px' }}>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody >
                            {filterData?.length > 0 ? filterData?.map((item, index) => {

                                const pathParts = item.file.split("/");
                                const fileName = pathParts[pathParts.length - 1];
                                const [version, sizeString] = item.version.split(",");
                                return (
                                    <tr key={item.id}>
                                        <td className="text-truncate">
                                            {cutWord(10, item.title)}..
                                        </td>
                                        <td>
                                            {bytesToMB(sizeString)}
                                        </td>
                                        <td >
                                            {version}
                                        </td>
                                        <td className="d-flex flex-row ">
                                            <GenericTableContentToolTip
                                                id={"first" + index}
                                                ToolTipText={"Edit"}
                                                content={
                                                    <div className="px-1 fs-5 pointer" onClick={() => toggleForm(item)}>
                                                        <Icon name="edit" ></Icon>
                                                    </div>
                                                }
                                            />
                                            <GenericTableContentToolTip
                                                id={"second" + index}
                                                ToolTipText={"Download"}
                                                content={
                                                    <a href={item.file_url} className={'fs-5 pointer text-download'} download=''>
                                                        <Icon name="download"></Icon>
                                                    </a>
                                                }
                                            />
                                            <GenericTableContentToolTip
                                                id={"third" + index}
                                                ToolTipText={"Delete"}
                                                content={
                                                    <div className="px-1 fs-5 pointer" onClick={() => { deleteItem(item) }}>
                                                        <Icon name="trash-alt" />
                                                    </div>
                                                }
                                            />

                                        </td>
                                    </tr>
                                );
                            })
                                : <tr className="text-center">
                                    <td colSpan={4}>No Data</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="m-2">
                    <GenericFooterPagination
                        NextIcons={() => (<Icon name="forward-ios" />)}
                        PreviousIcon={() => (<Icon name="back-ios" />)}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        data={data}
                    />
                </div>
            </div>
        </div>
    )

}