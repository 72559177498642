import axios from "axios";
import { SchemaTypes } from "../helper/extraproperties";
import { getCookie } from "../utils/Utils";


const SERVER_URL = process.env.REACT_APP_GAME_BASE_URL

export async function fetchHelper(
    endpoint,
    method,
    accessToken = null,
    body = null,
    options = null) {
    try {
        var url = SERVER_URL + endpoint;
        var responseJson
        var response;
        const formData = new FormData();
        if (options) {
            const queryParams = new URLSearchParams(options);
            url = url + `?${queryParams.toString()}`;
        }

        var requestObject = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                "authToken": getCookie("authToken")
            }
        }

        if (body && !(body?.contentType && body.contentType === SchemaTypes.file)) {
            requestObject.body = JSON.stringify(body);
        }

        if ((body?.contentType && body.contentType === SchemaTypes.file)) {
            formData.append("file", body.file)
            formData.append("description", body.description)
            formData.append("version", body.version)
            formData.append("title", body.title)
        }

        if ((body?.contentType && body.contentType === SchemaTypes.file)) {
            response = await fetch(url, { body: formData, method: method, headers: { "authToken": getCookie("authToken") } });
            responseJson = response;
        } else {
            response = await fetch(url, requestObject);
            responseJson = await response.json();
        }

        if (response.ok) return responseJson;

        var errorMessage =
            "Server error:" +
            response.status +
            " Message: " +
            responseJson.message;

        return Promise.reject(errorMessage);
    }
    catch (e) {
        return Promise.reject(e.message);
    }
}

export async function UploadFile(method, body, headers, SERVER_URL) {
    try {
        var url = SERVER_URL;

        var requestObject = {
            method: method,
            headers: headers,
        };

        if (body) {
            requestObject.body = body;
        }

        const response = await fetch(url, requestObject);

        if (response.ok) {
            return response;
        }

        var errorMessage =
            "Server error:" +
            response.status +
            " Message: " +
            response.statusText;

        return Promise.reject(errorMessage);
    } catch (e) {
        return Promise.reject(e.message);
    }
}