import { PreviewCard } from "../../components/Component"
import { Row } from "reactstrap"

export const HomePageWrapper = ({ children }) => {
    return (
        <section className="mx-lg-2">
            <div className="">
        
                    <Row>
                        {
                            children
                        }
                    </Row>
        
           </div>
        </section>
    )
}