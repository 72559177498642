import { TeamCard } from "./TeamCard"
import { useState } from "react"
import { FootballLeagues, LegueTeams } from "../../../helper/extraproperties"
import "../../../css/Team/Team.css"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { TeamLegueCard } from "./TeamLegueCard"
import { Shimmer } from "./Shimmer"
import { GenericTeamWrapper } from "./GenericTeamWrapper"
import { useInfiniteQuery } from "react-query"
import { FETCHING_ITEMS_LIMIT, endpoints } from "../../../Endpoints/endpoints"
import { TeamLegueView } from "./TeamLegueView"

export const TeamView = (props) => {

    // var limitSupported = props.limitSupported !== undefined ? props.limitSupported : true;

    // const queryFunction = async ({ pageParam = 0 }) => {
    //     return await endpoints.Tournament.getAll()
    // }

    // var { data, error, isLoading, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    //     {
    //         queryKey: props.queryKeyParameter,
    //         queryFn: queryFunction,

    //         getNextPageParam: (_lastPage, allPages) => {
    //             if (!limitSupported) return undefined;
    //             if (_lastPage.length < FETCHING_ITEMS_LIMIT) return undefined;
    //             return allPages.length;
    //         },
    //         select: (result) => {
    //             var dataToReturn = result.pages.flatMap((current) => current);

    //             if (props.filterData) {
    //                 dataToReturn = props.filterData(dataToReturn);
    //             }

    //             return dataToReturn;
    //         }
    //     });
    // console.log(data);
    // const onScroll = (event) => {
    //     const { scrollTop, clientHeight, scrollHeight } = event.target;



    const [toggle, setToggle] = useState(undefined)

    const init = {
        isloading: true,
        done: false
    }

    const [status, setStatus] = useState(init);

    const toggleMenu = (index, item) => {
        setStatus({
            isloading: true,
            done: false
        })
        setToggle({ index: index, ...item })
        setTimeout(() => {
            setStatus({
                isloading: false,
                done: true
            })
        }, 3000);
    }

    const getThirdSection = () => {

    }

    return (
        <>
            <GenericTeamWrapper>

                <div className="col-sm-12 col-12 pt-3">
                    <TeamLegueView status={status} setStatus={setStatus} gameId={"football"} toggleMenu={toggleMenu} setToggle={setToggle} toggle={toggle} />
                </div>
                <div className="row">
                    <div className={"  pt-3 d-sm-block d-none mb-2"}>

                        <Card className="card-bordered border-0 ">

                            <CardBody className="card-inner">
                                {
                                    !status.isloading
                                        ?
                                        <>
                                            <CardTitle className="fs-5  fw-bolder">{toggle ? toggle.title : "FIFA World Cup"}</CardTitle>
                                            <CardText>
                                                <div className="overflow-y-scroll overflow-x-visible row d-flex flex-wrap justify-content-center" style={{ height: "100dvh" }}>
                                                    {
                                                        LegueTeams.map(element => {
                                                            return (
                                                                <TeamCard item={element} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </CardText>
                                        </>
                                        :
                                        <div>
                                            <Shimmer height={450} />
                                        </div>
                                }
                                {/* <div className="d-flex justify-content-center">
                                    <button type="button" class="btn btn-primary">
                                        view All
                                    </button>
                                </div> */}
                            </CardBody>
                        </Card>

                    </div>
                </div>
            </GenericTeamWrapper>
        </>)
}
