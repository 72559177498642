import { IoIosInformationCircleOutline } from "react-icons/io";


export const SchemaTypes = {
  Number: "Number",
  String: "String",
  file: "file",
  Image: "Image"
}

export const propertyList = {
  Title: {
    displayName: "Title",
    name: "title",
    type: SchemaTypes.String,
    required: true,
    maxLength: 60
  },
  Document: {
    displayName: "Select Document",
    name: "file",
    type: SchemaTypes.file,
    required: true,
    Types: ["zip", "application/x-msdownload", "application/octet-stream", "application/zip", "application/x-zip", "application/x-zip-compressed"],
    specialFileInput: true
  },
  Version: {
    displayName: "Version",
    name: "version",
    type: SchemaTypes.String,
    required: true,
    maxLength: 15
  },
  Description: {
    displayName: "Description",
    name: "description",
    type: SchemaTypes.String,
    required: true,
    maxLength: 255
  },
  SportImg: {
    displayName: "Select Image",
    name: "sport_image",
    type: SchemaTypes.Image,
    required: true
  },
  SportTitle: {
    displayName: "Sport Title",
    name: "sport_name",
    type: SchemaTypes.String,
    required: true,
    maxLength: 60
  },
  SportDescription: {
    displayName: "Sport Description",
    name: "sport_description",
    type: SchemaTypes.String,
    required: true,
    maxLength: 255
  },
  teamName: {
    displayName: "Team Name",
    name: "team_name",
    type: SchemaTypes.String,
    required: true,
    maxLength: 60
  },
  teamLogo: {
    displayName: "Team Logo",
    name: "team_logo",
    type: SchemaTypes.Image,
    required: true,

  }
}

export const SportTeamPropertylist = [
  {
    item: propertyList.teamName,
    maxLength: 10
  },
  {
    item: propertyList.teamLogo
  }
]

export const SportPropertylist = [
  {
    item: propertyList.SportImg,
  },
  {
    item: propertyList.SportTitle,
    maxLength: 10
  },
  {
    item: propertyList.SportDescription,
    maxLength: 50
  }
]

export const filePropertylist = [
  {
    item: propertyList.Document,
  },
  {
    item: propertyList.Title,
    maxLength: 10
  },
  {
    item: propertyList.Version,
    maxLength: 10
  },
  {
    item: propertyList.Description,
    maxLength: 50
  }
]

export const acceptedFile = ["zip", "application/octet-stream", "application/x-msdownload", "application/zip", "application/x-zip", "application/x-zip-compressed"]

export const SILOCONSTRUCTOR = "Silocontructor"
export const SILOOPENWORLD = "siloopenworld"
export const SILOASSEMBLER = "Siloassembler"
export const SILODURBY = "silodurby"

export const Table1 = [
  {
    version: "Silo Constructor tool 1.2",
    whatsNew: "Silo OS software application is user-friendly and easy to navigate",
    size: "649 MB",
    buttonContent: {
      onClick: () => { },
      buttonName: "Download (64 bit)"
    }
  },
  {
    version: "Silo Constructor tool 1.1",
    whatsNew: "Design rooms and interiors from scratch",
    size: "649 MB",
    buttonContent: {
      onClick: () => { },
      buttonName: "Download (64 bit)"
    }
  },
  {
    version: "Silo Constructor tool 1.0",
    whatsNew: "make your own 3d designs",
    size: "649 MB",
    buttonContent: {
      onClick: () => { },
      buttonName: "Download (64 bit)"
    }

  }
]

export const Table2 = [
  {
    version: "Silo Assembler",
    whatsNew: "Silo Assembler enables hands-on crafting of intricate steel structures for construction projects.",
    size: "120.04 MB",
    buttonContent: {
      onClick: () => { },
      buttonName: "Download (64 bit)"
    }

  },
  {
    version: "Silo Assembler WebGL",
    whatsNew: "App effortlessly combines furniture components for personalized and unique designs.",
    size: " ",
    buttonContent: {
      onClick: () => { },
      buttonName: "Browser WebGL"
    }

  }
]

export const Table1Header = [
  "Version",
  "What's New",
  "Size",
  "Download",
]


export const requirementPcVersion = [
  "Processor : Intel i5 / Ryzen 5 (Recomended)",
  "RAM : 16 GB (Recomended)",
  "Graphics Card : 2 GB (Recomended)",
  "Operating System : Windows"
]

export const requirementWebGlVersion = [
  "Operating System : Any",
  "Device : Any Computer, Tab, Mobile",
  "Browser : Any Popular browsers with JS support"
]

export const tabGames = [
  {
    tagName: "FootBall",
    Icon: IoIosInformationCircleOutline
  },
  {
    tagName: 'basketBall',
    Icon: IoIosInformationCircleOutline
  }, {
    tagName: 'Rugby',
    Icon: IoIosInformationCircleOutline
  }]

export const FootballLeagues = [
  {
    title: 'FIFA World Cup',
    content: "The FIFA World Cup is an international quadrennial (every four years) men's football tournament contested by the national teams of the member associations of FIFA. It is the most prestigious association football tournament in the world, as well as the most widely viewed and followed sporting event in the world.", // Informative description
    leagueId: 1,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: { // Dedicated object for web app display
      keyInfo: [ // Array of key points for quick summary
        'International Tournament (Men\'s)',
        'Held every four years',
        'Most prestigious association football tournament'
      ],
      format: "Knockout tournament after qualification rounds", // Competition format
      hostCountry: null,
      website: 'https://www.fifa.com/worldcup/',
      resources: [ // Optional links to additional content
        {
          title: 'Wikipedia Page',
          url: 'https://en.wikipedia.org/wiki/FIFA_World_Cup'
        },
        {
          title: 'Official YouTube Channel',
          url: 'https://www.youtube.com/fifatv'
        }
      ]
    }
  },
  {
    title: 'Indian Super League (ISL)',
    content: "The Indian Super League (ISL) is a professional men's football league in India, at the top of the Indian football league system. Founded in 2013, it is contested by 11 clubs from 10 cities across India.",
    leagueId: 2,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Professional League (Men\'s)',
        'Top tier of Indian football system',
        'Founded in 2013'
      ],
      format: "Round robin followed by playoffs",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.indiansuperleague.com/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.indiansuperleague.com/'
        },
        {
          title: 'ISL on Twitter',
          url: 'https://twitter.com/IndSuperLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  }, {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  },
  {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  }, {
    title: 'UEFA Champions League',
    content: "The UEFA Champions League is an annual club football competition organized by the Union of European Football Associations (UEFA) and contested by top-division European clubs. It is considered the most prestigious club football competition in the world and the most watched club competition in the world.",
    leagueId: 3,
    Leagueimg: 'https://a.espncdn.com/combiner/i?img=/i/leaguelogos/soccer/500/62.png&scale=crop&cquality=40&location=origin&w=80&h=80',
    displayInfo: {
      keyInfo: [
        'Club Competition (Europe)',
        'Annual tournament',
        'Most prestigious club football competition in the world'
      ],
      format: "Group stage followed by knockout rounds",
      currentChampion: null, // Placeholder for dynamically updating champion info
      website: 'https://www.uefa.com/uefachampionsleague/',
      resources: [
        {
          title: 'Official Website',
          url: 'https://www.uefa.com/uefachampionsleague/'
        },
        {
          title: 'Champions League Highlights on YouTube',
          url: 'https://www.youtube.com/c/ChampionsLeague'
        }
      ]
    }
  }

];


export const BasketballLegue = [
  {
    title: 'NBA Finals',
    content: "",
    leagueId: "1"  // Changed teamId to leagueId for clarity
  },
  {
    title: 'WNBA Playoffs',
    content: "",
    leagueId: "2"
  },
  {
    title: 'EuroLeague Basketball',
    content: "",
    leagueId: "3"
  },
];

export const RugbyLegue = [
  {
    title: 'Six Nations Championship',
    content: "",
    leagueId: "1"  // Changed teamId to leagueId for clarity
  },
  {
    title: 'The Rugby Championship',
    content: "",
    leagueId: "2"
  },
  {
    title: 'Super Rugby Pacific',
    content: "",
    leagueId: "3"
  },
];

export const BasketBall = [
  {
    name: 'All Blacks (New Zealand)',
    country: 'New Zealand',
    league: 'The Rugby Championship, Six Nations (unofficial)'  // Can be an array of leagues if applicable
  },
  {
    name: 'Springboks (South Africa)',
    country: 'South Africa',
    league: 'The Rugby Championship'
  },
  {
    name: 'Wallabies (Australia)',
    country: 'Australia',
    league: 'The Rugby Championship'
  },
  {
    name: 'England',
    country: 'England',
    league: 'Six Nations'
  },
  {
    name: 'Ireland',
    country: 'Ireland',
    league: 'Six Nations'
  },
  {
    name: 'Wales',
    country: 'Wales',
    league: 'Six Nations'
  },
  {
    name: 'France',
    country: 'France',
    league: 'Six Nations'
  },
  {
    name: 'Scotland',
    country: 'Scotland',
    league: 'Six Nations'
  },
  {
    name: 'Italy',
    country: 'Italy',
    league: 'Six Nations'
  },
  {
    name: 'Argentina',
    country: 'Argentina',
    league: 'The Rugby Championship (since 2012)'  // Note the addition of participation year
  },
];


export const RugbyTeams = [
  {
    name: 'All Blacks (New Zealand)',
    country: 'New Zealand',
    league: 'The Rugby Championship, Six Nations (unofficial)'  // Can be an array of leagues if applicable
  },
  {
    name: 'Springboks (South Africa)',
    country: 'South Africa',
    league: 'The Rugby Championship'
  },
  {
    name: 'Wallabies (Australia)',
    country: 'Australia',
    league: 'The Rugby Championship'
  },
  {
    name: 'England',
    country: 'England',
    league: 'Six Nations'
  },
  {
    name: 'Ireland',
    country: 'Ireland',
    league: 'Six Nations'
  },
  {
    name: 'Wales',
    country: 'Wales',
    league: 'Six Nations'
  },
  {
    name: 'France',
    country: 'France',
    league: 'Six Nations'
  },
  {
    name: 'Scotland',
    country: 'Scotland',
    league: 'Six Nations'
  },
  {
    name: 'Italy',
    country: 'Italy',
    league: 'Six Nations'
  },
  {
    name: 'Argentina',
    country: 'Argentina',
    league: 'The Rugby Championship (since 2012)'  // Note the addition of participation year
  },
];
export const FootballTeams = [
  {
    name: 'Real Madrid',
    country: 'Spain',
    league: 'La Liga',
    championships: 35  // Include number of league championships won
  },
  {
    name: 'FC Barcelona',
    country: 'Spain',
    league: 'La Liga',
    championships: 26
  },
  {
    name: 'Bayern Munich',
    country: 'Germany',
    league: 'Bundesliga',
    championships: 32
  },
  {
    name: 'Manchester United',
    country: 'England',
    league: 'Premier League',
    championships: 20
  },
  {
    name: 'Liverpool FC',
    country: 'England',
    league: 'Premier League',
    championships: 19
  },
  {
    name: 'Juventus',
    country: 'Italy',
    league: 'Serie A',
    championships: 36
  },
  {
    name: 'Paris Saint-Germain',
    country: 'France',
    league: 'Ligue 1',
    championships: 10
  },
  {
    name: 'Chelsea FC',
    country: 'England',
    league: 'Premier League',
    championships: 6
  },
  {
    name: 'Atlético Madrid',
    country: 'Spain',
    league: 'La Liga',
    championships: 11
  },
  {
    name: 'Inter Milan',
    country: 'Italy',
    league: 'Serie A',
    championships: 19
  },
];


const Games = [
  {
    title: "basketBall",
    content: "",
    teamId: "basketBall",
    object: {
      teamFirst: {
        name: "sheffield United",
        teamFirstScore: "1",
        imageUrl: "https://images.fotmob.com/image_resources/logo/teamlogo/8657_small.png"
      },
      teamFirst: {
        name: "Burney",
        teamFirstScore: "2",
        imageUrl: "https://images.fotmob.com/image_resources/logo/teamlogo/8191_small.png"
      },
      isFinished: false,
      place: "Bramall Lane",
      date: "4/19/2024, 1:10:30 PM"
    }

  },
  {
    title: "Rugby",
    content: "",
    teamId: 'Rugby'

  },
  {
    title: "Rugby",
    content: "",
    teamId: 'Rugby'

  },
]


export const LegueTeams = [
  {
    "CountryName": "Italy",
    "Opponent": "England",
    "Points": 7,
    "PlayingAt": "Home"
  },
  {
    "CountryName": "France",
    "Opponent": "Netherlands",
    "Points": 4,
    "PlayingAt": "Brazil"
  },
  {
    "CountryName": "Argentina",
    "Opponent": "Portugal",
    "Points": 2,
    "PlayingAt": "Germany"
  },
  {
    "CountryName": "Spain",
    "Opponent": "Belgium",
    "Points": 8,
    "PlayingAt": "France"
  },
  {
    "CountryName": "Portugal",
    "Opponent": "Argentina",
    "Points": 1,
    "PlayingAt": "Italy"
  },
  {
    "CountryName": "Belgium",
    "Opponent": "Spain",
    "Points": 5,
    "PlayingAt": "England"
  },
  {
    "CountryName": "England",
    "Opponent": "France",
    "Points": 9,
    "PlayingAt": "Argentina"
  },
  {
    "CountryName": "Netherlands",
    "Opponent": "Portugal",
    "Points": 3,
    "PlayingAt": "Spain"
  },
  {
    "CountryName": "Germany",
    "Opponent": "Belgium",
    "Points": 10,
    "PlayingAt": "Netherlands"
  },
  {
    "CountryName": "Brazil",
    "Opponent": "Germany",
    "Points": 6,
    "PlayingAt": "Portugal"
  }
]

export const BasketBallTabs = [
  {
    activeTab: 'home-tab-pane',
    title: 'Switcher',
  },
  {
    activeTab: 'profile-tab-pane',
    title: '  Media',
  },
  {
    activeTab: 'contact-tab-pane',
    title: '  Audio',
  },
  {
    activeTab: 'camera-tab-pane',
    title: 'Camera',
  },

]

export const VersionControlGroup = {
  0: "Silo Constructor Tool",
  1: "Siloopenworld",
  2: "Silo Durby Race",
  3: "Silo Assembler Tool"
}
export const buttonaway = [
  { id: 1, number: 10 },
  { id: 2, number: 25 },
  { id: 3, number: 42 },
  { id: 4, number: 55 },
  { id: 5, number: 68 },
  { id: 6, number: 77 },
  { id: 7, number: 82 },
  { id: 8, number: 91 },
  { id: 9, number: 12 },
  { id: 10, number: 36 },
  { id: 11, number: 49 },
  { id: 12, number: 63 },
];
export const pointsbutton = [
  { id: 1, number: "1 pts" },
  { id: 2, number: "2 pts" },
  { id: 3, number: "3 pts" },
]


export const GAMES = "sportGames";
export const tournamentListGet = "get-list-tournament";
export const versioncontrolpost = "add-version-control";
export const VERSIONCONTROLFILE = "versionControl";
export const VERSIONCONTROLGETALL = "get-list-version-control";
export const VERSIONCONTROLDELETE = "delete-version-control"
export const VERSIONCONTROLUPDATE = "update-version-control"

export const VERSIONCONTROLLIST = "versioncontrolAll";
export const TEAMS = "teams";


export const IMAGEPNGCONTENTTYPE = "image/png"
export const IMAGEJPEGCONTENTTTYPE = "image/jpeg"

export const DELETEMESSAGEVERSIONCONTROL = "Version deleted successfully"
export const UPDATEMESSAGEVERISIONCONTROL = "Version id not found"
export const UPDATESUCCESS = "Version updated successfully"
