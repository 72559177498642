import { useState } from "react";
import { Tooltip } from "reactstrap"

export const GenericButtonIcon = ({ toolTipText = "unknown", IconComponent, onPress }) => {
    const [tooltipOpen, setOpen] = useState(false);

    const toggle = () => { setOpen(!tooltipOpen) };
    return (
        <div className="pointer mx-1" id={toolTipText} onClick={onPress}>
            <Tooltip placement='top' isOpen={tooltipOpen} target={toolTipText} toggle={toggle}>
                {toolTipText}
            </Tooltip>
            <IconComponent /></div>
    )
}