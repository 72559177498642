import React from 'react';
import { Bracket, Seed, SeedItem, SeedTeam, SingleLineSeed } from 'react-brackets';
import sport from "../../../assets/football.png"

const teams = [
  "Team A",
  "Team B",
  "Team C",
  "Team D",
];

const rounds = [
  {
    title: 'Round 1',
    seeds: [
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
    ],
  },
  {
    title: 'Round 2',
    seeds: [
      { winner: true },
      { winner: true },
      { winner: true },
      { winner: true },
    ],
  },
  {
    title: 'Round 3',
    seeds: [
      { winner: true },
      { winner: true },
    ],
  },
  {
    title: 'Round 4',
    seeds: [
      { winner: true },
    ],
  },
];
const rounds2 = [
  {
    title: 'Round 1',
    seeds: [
      { winner: true },
    ],
  },
  {
    title: 'Round 2',
    seeds: [
      { winner: true },
      { winner: true },
    ],
  },
  {
    title: 'Round 3',
    seeds: [
      { winner: true },
      { winner: true },
      { winner: true },
      { winner: true },
    ],
  },
  {
    title: 'Round 4',
    seeds: [
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
      { teams: [teams[0], teams[1]] },
      { teams: [teams[2], teams[3]] },
    ],
  },
];
const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
  const isLineConnector = rounds[roundIndex].seeds.length === rounds[roundIndex + 1]?.seeds.length;

  const Wrapper = isLineConnector ? SingleLineSeed : Seed;

  return (
    <Wrapper mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
      <SeedItem className='bg-gray'>
        <div className='rounded-2 d-flex flex-column'>
          <div className='d-flex flex-row justify-content-center'>
            <img className='m-2' style={{ borderRadius: '50%' }} height={30} width={30} src={sport}></img>
            <div className='m-2'>Feb 21</div>
            <img className='m-2' style={{ borderRadius: '50%' }} height={30} width={30} src={sport}></img>
          </div>
          <div className="row justify-content-center">
            <div className='col-4'>
              <div>INT</div>
              <div>2</div>
            </div>
            <div className='col-2'>-</div>
            <div className='col-4'>
              <div>INT</div>
              <div>2</div>
            </div>
          </div>
        </div>
      </SeedItem>
    </Wrapper>
  );
};

export function Test() {
  return (
    <div className='d-flex flex-row '>
      <div className='d-flex flex-row p-2'>
        <Bracket
          rtl={false}
          renderSeedComponent={CustomSeed}
          rounds={rounds}
          className="justify-content-center "
        />
      </div>
    </div>
  );
}
