export const GenericTable = ({ body, tableTitle }) => {
    return (
        <div className="">
            <div className=" fw-medium text-black dynamicSizetableheader">
                {tableTitle}
            </div>
            <table className="table table-lg " > 
                {
                    body
                }
            </table>
        </div>
    )
}