import { useNavigate } from "react-router"
import leagueimg from "../../../assets/leagueimg.png"
import stateimage from "../../../assets/es.png"
import first from "../../../assets/first.png"
import apponent from "../../../assets/apponent.png"
import { cutWord } from "../../../helper/helper"

export const TeamCard = ({ item }) => {
    const navigate = useNavigate()
    return (
        <>
      
            <div className="col-md-5 justify-content-center py-2  mb-1 mx-2 border rounded-2 p-2 p-md-2 ">
             
                    <div className="col-md-6">
                        <div className="col-1">
                            <img alt="" src={stateimage} style={{ minHeight: '20px', objectFit: 'contain', minWidth: "25px" }} />
                        </div>
                        <div className="col-5 fw-bolder" >
                            {item.CountryName}
                        </div>
                    </div>
                    <div className="col-12 row align-center pointer " 
                    //onClick={() => navigate("/TeamLeague/matchScore")}
                    >
                        <div className="col-4">
                            2:30 - 5:40 PM
                        </div>
                        <div className="col-8">
                            <div className="col-12 row">
                                <div className="col-2">
                                    <img alt="" src={first} style={{ minHeight: '10px', objectFit: 'cover', minWidth: "10px" }} />
                                </div>
                                <div className="col-7 text-nowrap fw-bolder">
                                    {
                                        cutWord(10, item.PlayingAt)
                                    }
                                </div>
                                <div className="col-2">
                                    <span class="badge text-bg-secondary">{item.Points}</span>
                                </div>
                            </div>
                            <div className="fw-bold ps-4">vs</div>
                            <div className="col-12 row">
                                <div className="col-2">
                                    <img alt="" src={apponent} style={{ minHeight: '10px', objectFit: 'cover', minWidth: "10px" }} />
                                </div>
                                <div className="col-7 text-nowrap fw-bolder ">
                                    {cutWord(10, item.Opponent)}
                                </div>
                                <div className="col-2 ">
                                    <span class="badge text-bg-primary"> {item.Points}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      
        </>
    )
}