import { useState } from "react";
import { Tooltip } from "reactstrap";

export const GenericTableContentToolTip = ({ ToolTipText, content, id }) => {
    const [tooltipOpen, setOpen] = useState(false);

    const toggle = () => { setOpen(!tooltipOpen) };
    return (
        <div id={id}>
            {
                content
            }
            <Tooltip placement="top" isOpen={tooltipOpen} target={id} toggle={toggle}>
                {ToolTipText}
            </Tooltip>
        </div>
    )
}