import React from 'react'
import logoimg1 from "../../../images/game/rugbylogo1.png"
import logoimg2 from "../../../images/game/rubgylogo2.png"

export default function Streamscoreboard() {
    return (
        <>
            <div className='  bg-white  '>
                <div className='col-md-12 align-center d-flex bg-black'>
                    <div className='col-4 d-flex justify-content-around   align-center '>
                        <img src={logoimg1} alt="xyz" width={"100px"} className=''/>
                        <h5>HOME</h5>
                        <h5>0</h5>
                    </div>
                    <div className='col-4 '>
                    <div className='d-flex justify-content-evenly py-1 '>
                    
                    <div className='d-flex flex-column'>
                        <h5>Timer</h5>
                        <h5>12:00</h5>
    
                    </div>
                    <div className='d-flex flex-column'>
                        <h5>PERIOD</h5>
                        <h5>1</h5>
    
                    </div>
                    </div>
                    </div>
                    <div className='col-4 d-flex justify-content-around align-center '>
                        <h5 className='pt-1'>0</h5>
                    <h5>AWAY</h5>
                        <img src={logoimg2} alt="xyz" width={"100px"} className='bg-black'/>
                    </div>


                </div>
               

            </div>






            {/* <div className=' py-2 bg-transperent my-1 '>
                <div className='col-md-12 align-center d-flex  '>
                    <div className='d-flex justify-content-evenly position-r'>
                        <img src={logoimg1} alt="xyz" width={"100px"} className=''/>
                    </div>
                    <div className='position-re '>

                        <h1 className='text-white'>2</h1>
                    </div>

                    
                    <div className='  align-center position-rela '>
                    <h5>Timer</h5>
                    <h5>12:00</h5>

                    </div>



                <div className='d-flex justify-content-evenly py-2 '>
                    
                <div className='d-flex flex-column justify-content-center position-relat'>
                <h1 className=''>0</h1>

                </div>
                <div className='position-rela'>

                        <img src={logoimg2} alt="xyz" width={"100px"} />
                </div>
             
                <div className='d-flex flex-column '>
                    <h5>PERIOD</h5>
                    <h5>1</h5>

                </div>
                </div>

                </div>
            </div> */}
        </>
    )
}
