import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col } from "reactstrap"
import { DELETESPORT, UPDATESPORT } from "../../Endpoints/endpoints"
import { useEffect, useState } from "react"
import GenericChangeEndpointModal from "../../components/genericComponents/GenericChangeEndpointModal"
import { GAMES, SchemaTypes, SportPropertylist } from "../../helper/extraproperties"
import { useQueryClient } from "react-query"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
} from "reactstrap";

import { AxiosHelper } from "../../helper/helper"
import { Icon } from "../../components/Component"
import { toast } from "react-toastify"

export const HomePageCard = ({ item, link = "/soccer" }) => {
    const { sport_description, sport_image, sport_name } = item
    const queryClient = useQueryClient()

    const InvalidateGames = () => {
        queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(GAMES),
        });
    }
    const [operaton, setOperation] = useState({
        update: false,
        delete: false,
    })

    const deleteSport = async (id) => {
        let data = await AxiosHelper(DELETESPORT, { sport_id: id });
        if (data.data.message === "Sport not found") {
            toast.error("User Does not Have Access!!")
        } else {
            toast.success(data.data.message)
        }
        InvalidateGames()
        return data
    }

    const updateSport = async (state, file) => {
        let requestObject = {
            ...state.currentData
        }
        SportPropertylist.map(element => {
            if (element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file && (state?.currentData[element.item.name])) {
                var file = state.files[requestObject[element.item.name]]
                requestObject[element.item.name] = file
            }
        })
        const formData = new FormData()
        formData.append("sport_id", item.id)
        SportPropertylist.map(element => {
            if (requestObject[element.item.name]) {
                formData.append(element.item.name, requestObject[element.item.name]);
            }
        })
        var data = await AxiosHelper(UPDATESPORT, formData)
        InvalidateGames()
        return data;
    }


    if (operaton.update) {
        let updateObject = {}
        SportPropertylist.map(element => {
            if (element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file) {
                // delete item[element.item.name];
            } else {
                updateObject[element.item.name] = item[element.item.name]
            }
        })
        return (<GenericChangeEndpointModal
            edit={true}
            propertyList={SportPropertylist}
            currentData={{ currentData: { ...updateObject }, files: [] }}
            formName={"Update Sport"}
            addButtonText={"Save"}
            querryFunction={updateSport}
            queryKeyValue={GAMES}
            navigateTo={"/"}
            closeModal={() => setOperation((prev) => ({ ...prev, update: !prev.update }))}
            ModalInitialState={() => setOperation((prev) => ({ update: false, delete: false }))}
        />)
    }

    return (
        <Col md="4 my-1 ">

            <Card className="card-bordered h-100 game-effect p-1 ">
                <div className="text-end ">

                </div>
                <div className=" figure position-relative overflow-hidden  ">
                    <CardImg top src={sport_image} alt="" className="object-fit-image" height={"240px"}/>
                </div>
                <CardBody className="card-inner d-flex flex-column ">
                    <>
                    <div className="row d-flex"  >
                            <div className="col-md-10">

                            <CardTitle tag="h5" className="m-0 pt-1">{sport_name}</CardTitle>
                            </div>
                            <div className="col-md-2">
                            <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                    <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                onClick={() => setOperation((prev) => ({ ...prev, update: true }))}
                                            >
                                                <Icon name="edit"></Icon>
                                                <span>Edit</span>

                                            </DropdownItem>
                                        </li>
                                        <li>
                                            <DropdownItem
                                                tag="a"
                                                onClick={() => deleteSport(item.id)}
                                                >
                                                <Icon name="trash"></Icon>
                                                <span>Delete</span>

                                            </DropdownItem>
                                        </li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        </div>
          



                        <CardText>
                            {sport_description}
                        </CardText>
                    </>
                    {/* <div className="card-footer bg-transparent p-0">
                        <Link to={link}>
                            <Button color="primary">Know More</Button>
                        </Link>
                    </div> */}
                </CardBody>
            </Card>
        </Col>
    )
}