import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from "reactstrap";
import { Icon } from "../Component";
import { useMutation, useQueryClient } from "react-query";
import { VERSIONCONTROL, endpoints } from "../../Endpoints/endpoints";
import { DELETEMESSAGEVERSIONCONTROL, UPDATEMESSAGEVERISIONCONTROL, UPDATESUCCESS, VERSIONCONTROLLIST, VERSIONCONTROLUPDATE, VersionControlGroup, acceptedFile, versioncontrolpost } from "../../helper/extraproperties";
import axios from "axios";
import { getCookie } from "../../utils/Utils";
import { VersionControlShimmer } from "../../pages/component/VersionControl/VersionControlShimmer";
import Error404Classic from "../../pages/error/404-classic";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { InitState } from "../../pages/component/VersionControl/VersionControlChangeEndpoint";

export const GenericModal = ({ currentElement, toggleForm, modalForm }) => {
    var [vr, size] = currentElement.version.split(",");
    const [title, setTitle] = useState(currentElement.title)
    const [version, setVersion] = useState(vr);
    const [file, setFile] = useState();
    const [formerror, setformError] = useState(undefined)
    const queryClient = useQueryClient()
    const [description, setDescription] = useState(currentElement.description);
    const [status, setStatus] = useState(InitState)

    const updateDocument = async (id) => {
        let fileSize = 0;
        let startTime = null;
        if (!formerror) {
            let options = {
                version_control_id: id
            }
            let formData = new FormData();
            formData.append('id', id)
            formData.append('description', description);
            formData.append('title', title);
            if (file) {
                formData.append('file', file);
                size = file.size;
                fileSize = size;
            }
            if (vr !== version) {
                formData.append('version', version + "," + size);
            } else {
                formData.append('version', vr + "," + size);
            }
            const data = await axios.post(process.env.REACT_APP_GAME_BASE_URL + VERSIONCONTROL + "/" + VERSIONCONTROLUPDATE, formData, {
                headers: {
                    "authToken": getCookie("authToken")
                },
                onUploadProgress: (progressEvent) => {
                    const uploadedBytes = progressEvent.loaded;
                    const percentage = Math.round(Math.round(uploadedBytes * 100) / fileSize);
                    if (startTime) {
                        const elapsedTime = Date.now() - startTime;
                        const estimatedTimeRemaining = Math.round((fileSize - uploadedBytes) * elapsedTime / uploadedBytes);
                        setStatus(prev => ({ ...prev, percentage, total: uploadedBytes, estimatedTime: estimatedTimeRemaining }));
                    } else {
                        startTime = Date.now(); // Start time for the first progress event
                    }
                }
            })
            setStatus(prev => ({ ...prev, success: true }))
            return data;
        }
        return false;
    }

    const { mutate, data, isLoading, error, isSuccess } = useMutation({
        mutationFn: (id) => updateDocument(id),
        mutationKey: ["GETALLLIST"],
        onSuccess: (data) => {
            if (data.data.message === UPDATEMESSAGEVERISIONCONTROL) {
                toast.error("User Does Not Have Access!!")
                // Swal.fire(
                //     'Error!',
                //     `Failed to update version control`,
                //     'error'
                // );

            } else if (data.data.message === UPDATESUCCESS) {
                toast.success("Version Control Updated Successfully")
                // Swal.fire(
                //     'Updated!',
                //     'Version has been updated successfully.',
                //     'success'
                // );
                queryClient.invalidateQueries({
                    predicate: (query) =>
                        query.queryKey.includes(VERSIONCONTROLLIST)
                })

            }
            toggleForm()
        }

    })
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === true) {

            e.preventDefault()
            mutate(currentElement.id)

        } else {
            form.classList.add("was-validated");
        }
    }

    if (isLoading) {
        return (<div className="loader-spinner-custom row justify-content-center align-center">
            <div className="col-md-6 text-center">
                <div className=" row justify-content-center pb-1">
                    <h2 >Don't Close Until Process End !!!</h2>
                </div>
                <div className="row justify-content-center pb-1 ">
                    <p className="fs-3">uploading.... {status.percentage}%</p>
                </div>
                <div className="border border-1">
                    <Progress animated className="progress-lg" value={status.percentage}> {status.percentage}% </Progress>
                </div>
            </div>
        </div>)
    }
    if (isSuccess) {
        modalForm()
    }

    const checkFormFile = (e) => {
        if (acceptedFile.includes(e.target.files[0].type)) {
            setFile(e.target.files[0])
            setformError(undefined)
        } else {
            setformError("Please Select Appropriate file")
        }
    }

    if (error) {
        return <Error404Classic />
    }
    return (
        <div className="container p-2">
            <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                    <div className="fs-5 align-center mx-1 pointer g-2" onClick={() => toggleForm()}><Icon name="back-ios" />
                        <p className="fs-2 fw-medium mx-2">Update Version Control</p>
                    </div>
                    <p className="fs-6 mx-5">{VersionControlGroup[currentElement?.group_key]}</p>
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                noValidate
                className="card needs-validation rounded border border-1 p-2"
            >

                <div className="form-group">
                    <label className="form-label" htmlFor="title">
                        Title
                    </label>
                    <div className="form-control-wrap">
                        <input maxLength={15} placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" style={{ backgroundPosition: 'right', backgroundImage: "none" }} id="title" value={title} required={true} />
                        <div className="invalid-feedback">
                            Field Required
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="description">
                        Description
                    </label>
                    <div className="form-control-wrap">
                        <input maxLength={255} type="text" placeholder="Enter Description" className="form-control" style={{ backgroundPosition: 'right', backgroundImage: "none" }} onChange={(e) => setDescription(e.target.value)} id="description" value={description} required={true} />
                        <div className="invalid-feedback">
                            Field Required
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="version">
                        Version
                    </label>
                    <div className="form-control-wrap">
                        <input maxLength={15} type="text" placeholder="Enter Version" className="form-control" style={{ backgroundPosition: 'right', backgroundImage: "none" }} id="version" value={version} onChange={(e) => setVersion(e.target.value)} required={true} />
                        <div className="invalid-feedback">
                            Field Required
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="file">
                        File
                    </label>
                    <div className="form-control-wrap">
                        <input type="file" id="file" placeholder="File" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" className="form-control" style={{ backgroundPosition: 'right', backgroundImage: "none" }} onChange={checkFormFile} />
                    </div>
                </div>
                {
                    formerror &&
                    <>{formerror}</>
                }
                <div className="d-flex flex-row column-gap-1 justify-content-between">
                    <Button color="primary" type="submit" >
                        Update
                    </Button>
                    {/* <Button color="primary" type="submit" onClick={(e) => {
                        e.preventDefault()
                        toggleForm()
                    }} >
                        Cancel
                    </Button> */}
                </div>
            </form>
        </div>
    )
}