import Nouislider from 'nouislider-react'
import React from 'react'

export default function Audio() {
    return (
        <>
            <div className='container-fluid '>
                <div className="row py-5 px-2">
                    <div className='col-md-2 align-center '>
                        <h5 >

                            Microphone Controls
                        </h5>
                    </div>
                    <div className="col-md-5">
                        <h5 className='pb-2'>
                            Microphone 1
                        </h5>
                        <Nouislider
                            className="form-range-slider"
                            accessibility
                            connect={[true, false]}
                            start={20}
                            range={{
                                min: 0,
                                max: 100,
                            }}
                        ></Nouislider>
                    </div>
                    <div className="col-md-5">
                        <h5 className='pb-2'>
                            Microphone 2
                        </h5>
                        <Nouislider
                            className="form-range-slider"
                            accessibility
                            connect={[true, false]}
                            start={50}
                            range={{
                                min: 0,
                                max: 100,
                            }}
                        ></Nouislider>
                    </div>
                </div>
                <div className="row py-5 px-2">
                    <div className='col-md-2 align-center '>
                        <h5 >

                            Audio Controls
                        </h5>
                    </div>
                    <div className="col-md-5">
                        <h5 className='pb-2'>
                            Audio 1
                        </h5>
                        <Nouislider
                            className="form-range-slider"
                            accessibility
                            connect={[true, false]}
                            start={30}
                            range={{
                                min: 0,
                                max: 100,
                            }}
                        ></Nouislider>
                    </div>
                    <div className="col-md-5">
                        <h5 className='pb-2'>
                            Audio 2
                        </h5>
                        <Nouislider
                            className="form-range-slider"
                            accessibility
                            connect={[true, false]}
                            start={20}
                            range={{
                                min: 0,
                                max: 100,
                            }}
                        >

                        </Nouislider>

                    </div>
                </div>
                <div className="row py-5 text-center align-center px-2">
                    <div className='col-md-2 align-center '>
                        <h5 >

                            Camera Controls
                        </h5>
                    </div>
                    <div className="col-md-2">
                        <h5 className='pb-2'>
                            Camera 1
                        </h5>
                        <div className="form-control-wrap d-flex justify-content-center ">
                            <Nouislider
                                className="form-range-slider"
                                orientation="vertical"
                                accessibility
                                connect={[false, true]}
                                start={10}
                                range={{
                                    min: 0,
                                    max: 100,
                                }}
                            ></Nouislider>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <h5 className='pb-2'>
                            Camera 2
                        </h5>
                        <div className="form-control-wrap text-center d-flex justify-content-center">
                            <Nouislider
                                className="form-range-slider"
                                orientation="vertical"
                                accessibility
                                connect={[false, true]}
                                start={30}
                                range={{
                                    min: 0,
                                    max: 100,
                                }}
                            ></Nouislider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <h5 className='pb-2'>
                            Camera 3
                        </h5>
                        <div className="form-control-wrap d-flex justify-content-center">
                            <Nouislider
                                className="form-range-slider"
                                orientation="vertical"
                                accessibility
                                connect={[false, true]}
                                start={50}
                                range={{
                                    min: 0,
                                    max: 100,
                                }}
                            ></Nouislider>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <h5 className='pb-2'>
                            Camera 4
                        </h5>
                        <div className="form-control-wrap d-flex justify-content-center">
                            <Nouislider
                                className="form-range-slider"
                                orientation="vertical"
                                accessibility
                                connect={[false, true]}
                                start={60}
                                range={{
                                    min: 0,
                                    max: 100,
                                }}
                            ></Nouislider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
