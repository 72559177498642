import React from "react";
import { getCookie } from "../utils/Utils";
import { QueryClient, useQueryClient } from "react-query";
import axios from "axios";

export const cutWord = (remain, text) => {
  return text.substring(0, remain) + (text.length > 50 ? "..." : "")
}

export function deepCopyObject(fromObject) {
  return JSON.parse(JSON.stringify(fromObject));
}
export function bytesToMb(bytes) {
  const oneMb = 1048576;
  return `${bytes / oneMb + "MB"}`
}
export function waitSeconds(milisecond) {
  setTimeout(() => {
  }, milisecond);
}

const handleUpload = async (body, setProgress, url) => {
  const formData = new FormData();
  formData.append("file", body.file)
  formData.append("description", body.description)
  formData.append("version", body.version)
  formData.append("title", body.title)

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: { "authToken": getCookie("authToken") },
    onUploadProgress: (e) => {
      if (e.lengthComputable) {
        const uploadedBytes = e.loaded;
        const totalBytes = e.total;
        const uploadProgress = Math.floor((uploadedBytes / totalBytes) * 100);
        setProgress(uploadProgress);
      }
    },
  });
  setProgress(0);
};
export const megabytesToBytes = (megabytes) => {
  return megabytes * 1024 * 1024;
}

const dateString = "2024-05-28";

const [year, month, day] = dateString.split("-");


export const GetLegueDate = (dateString) => {
  const DateObject = {
    '01': "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  }
  const [year, month, day] = dateString.split("-");
  return DateObject[month] + " " + day

}

export const getValueInPercentOfTotal = (value, total) => {
  return Math.round(Math.round(100 * value) / total)
}
export function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
}

export async function download(url, filename = 'download.txt') {
  try {
    const response = await fetch(url, { mode: 'no-cors' });
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename; // Set desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Download error:', error);
    // Handle download errors (e.g., display error message)
  }
}

export function getDataForPage(data, pageNumber,ps = 10) {
  const pageSize = ps;
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  return { data: data.slice(startIndex, endIndex), lastIndex: endIndex };
}

export const Invalidate = (queryKey) => {
  const queryClient = new useQueryClient()
  queryClient.invalidateQueries({
    predicate: (query) =>
      query.queryKey.includes(queryKey)
  })
}

export const blobToBase64 = async (blob) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onloadend = function () {
      var pdfBase64Data = reader.result;
      resolve(pdfBase64Data);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

export const AxiosHelper = async (extraPath, body) => {
    let path = process.env.REACT_APP_GAME_BASE_URL + extraPath;
    var data = await axios.post(path, body, {
      headers: {
        "authToken": getCookie("authToken")
      }
    })
    return data;
}