import { GAMES } from '../../../helper/extraproperties';
import { useQuery } from 'react-query';
import { ShimmerTeam } from './ShimmerTeam';
import Error404Classic from '../../error/404-classic';
import {  endpoints } from '../../../Endpoints/endpoints';
import { ShowSportCard } from './ShowSportCard';

export const TestTeam = () => {

    const bigNumber = 9007199254740991;

    const queryfunction = async () => {
        let data = await endpoints.getSport.getAll(process.env.REACAT_APP_TOKEN, { limit: bigNumber }, null, null);
        return Object.values(data.data)[0];
    };

    const { data, isLoading, error } = useQuery({
        queryFn: queryfunction,
        queryKey: [GAMES],
    });

    if (isLoading) {
        return <ShimmerTeam />;
    }

    if (error) {
        return <Error404Classic />;
    }

    return (
        <div className='container-fluid'>
            <div className='row mt-2  m-1'>
                <h3>Teams </h3>
               {
                data.map(element=>{
                    return <ShowSportCard
                        item = {element}
                    />
                })
               }
            </div>
        </div>
    );
};
