import { VersionControlCard } from "./VersionControlCard"
import Constructor  from "../../../images/game/Version Control/Constructor Tool.jpg"
import OpenWorld from "../../../images/game/Version Control/Open World.jpg"
import Assebler from "../../../images/game/Version Control/Asembler Tool.jpg"
import derbyrace from "../../../images/game/Version Control/Durby Race.jpg"

export const VersionControlView = () => {
    const siloGames = [
        {
            title: "Silo Constructor Tool",
            describe: "You can create 3D designs easily here with no any design knowledge, simple drag and drop elements and your 3D design ready for print. This constructor tool provides a user-friendly design process. This tool provides flexible design, Rapid Prototyping and Fast Rendering for best quality experience for Designers.",
            sourceImg: Constructor,
            sourceId: 0
        },
        {
            title: "Silo Open World",
            describe: "Silo open world.com is an online platform for real estate property listings. Siloopenworld.com allows its users (real estate agents or individual sellers) (“Users”) to post their Listings and share information of the properties available for sale or rent. Siloopenworld.com only provides a platform for, and therefore merely displays, the information as uploaded by the users.",
            sourceImg: OpenWorld,
            sourceId: 1
        },
        {
            title: "Silo Durby Race",
            describe: "Silo Durby  is an online platform for silo durby . Siloopenworld.com allows its users (real estate agents or individual sellers) (“Users”) to post their Listings and share information of the properties available for sale or rent. Siloopenworld.com only provides a platform for, and therefore merely displays, the information as uploaded by the users.",
            sourceImg: derbyrace,
            sourceId: 2
        },
        {
            title: "Silo Assembler Tool",
            describe: "Silo Assembler is a constructing tool to construct structure like ware house, storage unit, any many more. Also, you can build furniture of your choice, change texture and have a preview of 360.",
            sourceImg: Assebler,
            sourceId: 3
        }
    ]

    return (
        <>
            <div className="row justify-content-center mx-2">
                <div className=" height10  rounded mt-1 mb-1 d-flex flex-column pt-2">
                    <h3>Version Control</h3>
                    <p >Welcome to Version Control</p>
                </div>
                <div className="row justify-content-evenly p-0" >
                    {
                        siloGames.map((element, index) => {
                            return (
                                <VersionControlCard
                                    key={index}
                                    item={element}
                                />
                            )
                        })

                    }
                </div>
            </div>
        </>
    )
}