import { bytesToMB, cutWord } from "../../helper/helper"

export const GenericTableBuilder = ({ headers, headerBody, Objectcomponent = undefined }) => {
    if (headerBody.length === 0) {
        return <>No Data Please refresh Page</>
    }

    return (
        <>
            <thead>
                <tr>
                    {
                        headers.map((element, index) => {
                            return (
                                <th className="dynamicSize col-3" key={index} scope="col">{element}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody >
                {
                    headerBody?.map((element, index) => {
                        const [version, sizeString] = element.version.split(",");
                        let obj = {
                            version: version,
                            whatsNew: element.description,
                            size: bytesToMB(sizeString),
                            buttonContent: {
                                fileUrl: element.file_url,
                                buttonName: "Download (64 bit)",
                                file: element.file
                            }
                        }

                        return (
                            <tr key={index}>
                                {
                                    Object.entries(obj).map(([key, value], index) => {
                                        if (!Objectcomponent) {

                                            return <></>
                                        }
                                        if (typeof (value) === "object") {
                                            return (
                                                <td className="col-3 text-truncate" key={index}>
                                                    <Objectcomponent value={value} />
                                                </td>)
                                        }
                                        return (
                                            <td className=" dynamicSize col-3 text-truncate" key={index}>{cutWord(15,value)}</td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </>
    )
}