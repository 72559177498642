import { useNavigate, useParams } from "react-router"
import { Icon, PreviewCard } from "../../../components/Component"
import { Badge, Button } from "reactstrap"
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from "react-query";
import { GAMES, SchemaTypes, SportTeamPropertylist, TEAMS } from "../../../helper/extraproperties";
import { AxiosHelper, getDataForPage } from "../../../helper/helper";
import { ADDSPORTTEAM, DELETETEAMSPORT, GETTEAMPERSPORT, UPDATETEAMSPORT } from "../../../Endpoints/endpoints";
import Error404Classic from "../../error/404-classic";
import { useState } from "react";
import { GenericFooterPagination } from "../../../components/genericComponents/GenericFooterPagination"
import { GenericButtonIcon } from "../../../components/genericComponents/GenericButtonIcon";
import GenericChangeEndpointModal from "../../../components/genericComponents/GenericChangeEndpointModal";
import ShimmerTeamperSport from "./ShimmerTeamperSport";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const ShowTeamPerSport = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [operaton, setOperation] = useState({
        Add: false,
        update: undefined,
        delete: false,
    })

    const queryClient = useQueryClient();
    const { sportId, sportName } = useParams()
    const [viewAll,setViewAll]= useState(false)
    const location = useLocation()
    const { item } = location.state;
    const navigate = useNavigate()
    const queryKey = [sportId, GAMES]

    const InvalidateSportPerTeam = () => {
        queryClient.invalidateQueries({
            predicate: (query) => query.queryKey.includes(GAMES),
        });
    }

    const SportTeamqueryfunction = async () => {
        let data = await AxiosHelper(GETTEAMPERSPORT, {
            sport_id: sportId
        })

        let array = data.data.data;

        if ((data.data.message === "Validation failed") || (data.data.message === "All teams retrieved successfully" && (Object.keys(data.data.data).length === 0))) {
            return {}
        }
        array = array.reverse()
        var resultObject = {}
        let page = 1
        let ok = true;
        while (ok) {
            if (!resultObject[page]) {
                resultObject[page] = []
                var result = getDataForPage(array, page, 5)
                resultObject[page] = result.data;
            }
            if (result.lastIndex === array.length) {
                ok = false;
            }
            page++;
        }
        return resultObject;
    }

    const { data, isLoading, error } = useQuery({
        queryFn: SportTeamqueryfunction,
        queryKey: queryKey
    })

    if (isLoading) {
        return <ShimmerTeamperSport />;
    }

    if (error) {
        return <Error404Classic />;
    }
    var filterData = []
    if (Object.keys(data).length === 0) {
        filterData = []
    }else if(viewAll){
        filterData = Object.values(data).flat()
    } else {
        filterData = data[currentPage]
    }

    const deleteSport = async (id) => {
        const result = await Swal.fire({
            title: "Are You Sure?", // Removed extra space after "Sure"
            text: "This process can't be reverted are you sure want to delete it?",
            icon: "question",
            showCancelButton: true, // Added showCancelButton for "Cancel" option
            confirmButtonColor: '#3085d6', // Optional: Customize confirm button color
            cancelButtonColor: '#d33', // Optional: Customize cancel button color
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: 'No, cancel'
        })

        if (result.isConfirmed && item?.id) {
            let data = await AxiosHelper(DELETETEAMSPORT, { team_id: id })
            // console.log(data)
            if (data?.data?.message === "Team deleted successfully") {
                toast.success("Deleted Successfully")
            } else {
                toast.error(data.data.message)
            }
            InvalidateSportPerTeam()

        }
    }

    const updateSport = async (state, file) => {
        let requestObject = {
            ...state.currentData
        }

        SportTeamPropertylist.map(element => {

            if ((element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file) && (state?.currentData[element.item.name] || state?.currentData[element.item.name] === 0)) {
                var file = state.files[requestObject[element.item.name]]

                requestObject[element.item.name] = file
            }
            return true;
        })
        const formData = new FormData()
        formData.append('id', operaton.update.id)
        formData.append("sport_id", item.id)
        SportTeamPropertylist.map(element => {
            if (requestObject[element.item.name]) {
                formData.append(element.item.name, requestObject[element.item.name]);
            }
            return true;
        })
        var data = await AxiosHelper(UPDATETEAMSPORT, formData)
        InvalidateSportPerTeam()
        return data;
    }

    if (operaton.update) {
        let updateObject = {}
        SportTeamPropertylist.map(element => {
            if (element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file) {
                // delete operaton.update[element.item.name];
            } else {
                updateObject[element.item.name] = operaton.update[element.item.name]
            }
            return true;
        })

        return (<GenericChangeEndpointModal
            edit={true}
            propertyList={SportTeamPropertylist}
            currentData={{ currentData: { ...updateObject }, files: [] }}
            formName={"Update Team"}
            addButtonText={"Update"}
            querryFunction={updateSport}
            queryKeyValue={GAMES}
            navigateTo={"/"}
            closeModal={() => setOperation((prev) => ({ ...prev, update: prev.update ? undefined : prev.update }))}
            ModalInitialState={() => setOperation((prev) => ({ update: undefined, delete: false }))}
        />)
    }


    const QueryKey = [sportId, TEAMS]

    const addTeamQueryFunction = async (state) => {
        let requestObject = {
            ...state.currentData
        }
        SportTeamPropertylist.map(element => {
            if (element.item.type === SchemaTypes.Image || element.item.type === SchemaTypes.file) {
                var file = state.files[requestObject[element.item.name]]
                requestObject[element.item.name] = file
            }
        })

        const formData = new FormData()

        formData.append("sport_id", sportId)

        SportTeamPropertylist.map(element => {
            formData.append(element.item.name, requestObject[element.item.name]);
        })

        const data = await AxiosHelper(ADDSPORTTEAM, formData)

        InvalidateSportPerTeam()
        return data;
    }

    if (operaton.Add) {
        return (<GenericChangeEndpointModal
            propertyList={SportTeamPropertylist}
            currentData={{ currentData: {}, files: [] }}
            formName={"Add Team"}
            addButtonText={"Submit"}
            querryFunction={addTeamQueryFunction}
            queryKeyValue={QueryKey}
            navigateTo={"/"}
            closeModal={() => setOperation((prev) => ({ ...prev, Add: !prev.Add }))}
            ModalInitialState={() => setOperation((prev) => ({ Add: false }))}
        />)
    }

    return (
        <PreviewCard>
            <div className="">
                <div className="container-fluid p-2 d-flex flex-column">
                    <div className="row">
                        <div className="col-2 d-flex flex-row justify-content-start ">
                            <div className="pointer" onClick={() => navigate("/teams")}><Badge className="border-0 text-black bg-transparent py-1"  ><Icon name="back-ios" /></Badge></div>
                            <div className="fw-bold fs-4 px-1">Back</div>
                        </div>
                        <div className="col-10 justify-content-end d-flex">
                            <Button onClick={() => setOperation((prev) => ({ ...prev, Add: true }))} color="primary">Add Team</Button>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex flex-row">
                        <img src={item.sport_image} style={{ borderRadius: '50%' }} height={50} width={50} alt=""></img>
                        <div className="fw-bold fs-4 mx-2 align-center">{sportName}</div>
                    </div>
                    <div className="d-flex justify-content-end"><Button onClick={() => setViewAll((prev)=>!prev)} color="primary">{
                        viewAll
                        ? "Show Less" :
                        "View All"}</Button></div>
                    <hr />
                    <div className="d-flex flex-column row">
                        <div className="d-flex flex-row justify-content-between  col-1 py-2 mx-1">
                            <div className="fw-bold">#</div>
                            <div className="fw-bold">Teams</div>
                        </div>
                        <div className="col-9"></div>
                        <div className='d-flex flex-column'>
                            {
                                filterData?.map((element, index) => {
                                    return (
                                        <><div class=" p-1">
                                            <div className='d-flex flex-row justify-between'>
                                                <div className="d-flex flex-row g-2">
                                                    <div className="align-center fw-medium">
                                                        <li></li>
                                                    </div>
                                                    <div className=''>
                                                        <img className="" style={{ borderRadius: '50%' }} height={30} width={30} src={element.team_logo} alt="" />
                                                    </div>
                                                    <div className='align-center pointer fw-medium' style={{ fontSize: '13px !important' }}>{element.team_name}</div>
                                                </div>
                                                <div className="d-flex flex-row justify-content-center">
                                                    <GenericButtonIcon toolTipText={"Edit"} id={"t"} IconComponent={() => (<Icon name="edit" className="fs-3" />)} onPress={() => { setOperation((prev) => ({ ...prev, update: prev.update ? undefined : element })) }} />
                                                    <GenericButtonIcon toolTipText={"Delete"} id={"x"} IconComponent={() => (<Icon name="trash-alt" className="fs-4" />)} onPress={() => { deleteSport(element) }} />
                                                </div>
                                            </div>
                                        </div>
                                            <hr />
                                        </>)
                                })
                            }
                           {
                            !viewAll &&
                            ( <div className="m-2">
                                <GenericFooterPagination
                                    NextIcons={() => (<Icon name="forward-ios" />)}
                                    PreviousIcon={() => (<Icon name="back-ios" />)}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    data={data}
                                />
                            </div>)}
                        </div>
                    </div>
                </div>

            </div>
        </PreviewCard>
    )
}